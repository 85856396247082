import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import SignupProviderLayout from "./SignupProviderLayout";
import SignupName from "../components/Auth/SignupName";
import useWindowDimensions from "../Hooks/UseWindowDimension";
import SignupContact from "../components/Auth/SignupContact";
import SignupLocation from "../components/Auth/SignupProviderLocation";
import SignupSecurity from "../components/Auth/SignupSecurity";
// import VerifyPhoneNumber from "../components/Auth/VerifyPhoneNumber"; //to_add_back
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  createServiceUser,
  verifyPhoneNumber,
  setPassword,
  updateUserData,
  saveRegisteredUserDataToState,
  clearRegisterRelatedErrors,
  checkIfUserExist,
  resetCheckIfUserExist,
} from "../redux/actions/register";
import SignupServiceCategory from "../components/Auth/SignupServiceCategory";
import SignupProfession from "../components/Auth/SignupProfession";
import SignupRefrees from "../components/Auth/SignupRefrees";
import RegistrationThanks from "../components/Auth/RegistrationThanks";
import { Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    paddingLeft: "0rem",
    paddingRight: "0rem",
    transform: "scale(0.75)",
    marginLeft: "-2rem",
    marginTop: "-0.5rem",
    backgroundColor: "transparent",
  },
}));

function SignupUserStepper({ resetCheckIfUserExist, ...props }) {
  const { width } = useWindowDimensions();

  const [openSnack, setOpenSnack] = React.useState(false);

  function getSteps() {
    return [
      "Name",
      "Contact",
      // `${width > 700 ? "Verify Number" : "Verify"}`,  //to_add_back
      "Location",
      "Category",
      "Profession",
      "Referees",
      "Security",
      "Done",
    ];
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const userExist = props?.registerReducer?.emailCheck?.exist && props?.registerReducer?.phoneCheck?.exist;
  const { userExistError } = props?.registerReducer;
  const [isRegisteredSuccessful] = useState(false);
  // const [isCodeCorrect] = useState(true);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isProcessing] = useState(false);
  const handleWidthOfButtonTwo = () => {
    if (width > 480) {
      return { position: "relative", top: "-14rem", left: "12.5rem" };
    } else return { position: "relative", top: "-14rem", left: "4rem" };
  };
  const handleWidthOfButtonThree = () => {
    if (width > 480) {
      return { position: "relative", top: "-14rem", left: "10rem" };
    } else return { position: "relative", top: "-14rem", left: "3rem" };
  };
  const handleWidthOfButtonFour = () => {
    if (width > 480) {
      return { position: "relative", top: "-14rem", left: "6rem" };
    } else return { position: "relative", top: "-14rem", left: "1rem" };
  };

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // const [verifyUserPhone, setverifyUserPhone] = useState({
  //   _id: "",
  //   code: "",
  // });
  const [serviceUserPassword, setserviceUserPassword] = useState({
    _id: props?.registerReducer?.user?._id,
    password: "",
  });
  const [otherInformation, setotherInformation] = useState({
    // _id: props?.registerReducer?.user?._id, //to_add_back
    userType: "provider",
    state: "",
    city: "",
    area: "",
    profession: "",
    categories: [],
    referees: [{ fullName: "", phone: "" }],
  });

  // useEffect(() => {
  //   resetCheckIfUserExist();
  // }, [resetCheckIfUserExist]);

  const handleNext = async () => {
    if (activeStep === 0) {
      await props.clearRegisterRelatedErrors();
      if(!userData.email && !userData.phone){
        await resetCheckIfUserExist();
      }
    }
    // if (activeStep === 1) {

    //   // await props.createServiceUser(userData);
    // }
    //to_add_back
    // if (activeStep === 2) {
    //   await props.verifyPhoneNumber(verifyUserPhone);
    // }
    // if (activeStep === 3) {
    //   await console.log("otherInformation", otherInformation);
    // }
    // if (activeStep === 4) {
    //   await console.log("otherInformation", otherInformation);
    // }
    // if (activeStep === 5) {
    //   await console.log("otherInformation", otherInformation);
    // }
    // if (activeStep === 6) {
    //   // console.log("data from provider stepper 6", {
    //   //   ...userData,
    //   //   ...otherInformation,
    //   // });
    //   await props.updateUserData({
    //     ...userData,
    //     ...otherInformation,
    //   });
    // }
    if (activeStep === 5) {
      if (userData.email === "") {
        delete userData.email;
      }
      if(otherInformation.referees[0].fullName==="" ||otherInformation.referees[0].phone===""){
        delete otherInformation.referees;
      }
      console.log("data from provider stepper 6", {
        ...userData,
        ...otherInformation,
      });
      handleClickSnack()
      await props.createServiceUser({
        ...userData,
        ...otherInformation,
      });
      handleCloseSnack()
    }
    if (activeStep === 6) {
      handleClickSnack()
      await props.setPassword(serviceUserPassword);
      handleCloseSnack()
    }

    // if (activeStep === 7) {
    // props.setPassword(serviceUserPassword);
    // }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // console.log("from signupStepper", { ...userData, ...otherInformation });



  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };



  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <SignupName
              setUserData={setUserData}
              userData={userData}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );
      case 1:
        return (
          <>
            <SignupContact
              userExist={userExist}
              userExistError={userExistError}
              isProcessing={isProcessing}
              setUserData={setUserData}
              userData={userData}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );

      //to_add_back
      // case 2:
      //   if ( props.registerReducer.isSuccessful) {
      //   return (
      //     <>
      //       <VerifyPhoneNumber
      //         isCodeCorrect={isCodeCorrect}
      //         userData={userData}
      //         verifyUserPhone={verifyUserPhone}
      //         setverifyUserPhone={setverifyUserPhone}
      //         isFormFilled={isFormFilled}
      //         setIsFormFilled={setIsFormFilled}
      //         signupPerson="Service Provider"
      //       />
      //     </>
      //   );
      // } else {
      //   handleBack();
      // }
      // break;

      case 2:
        return (
          <>
            <SignupLocation otherInformation={otherInformation} setotherInformation={setotherInformation} />
          </>
        );

      case 3:
        return (
          <>
            <SignupServiceCategory
              otherInformation={otherInformation}
              setotherInformation={setotherInformation}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );
      case 4:
        return (
          <>
            <SignupProfession
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
              otherInformation={otherInformation}
              setotherInformation={setotherInformation}
            />
          </>
        );
      case 5:
        return (
          <>
            <SignupRefrees
              otherInformation={otherInformation}
              setotherInformation={setotherInformation}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );

      case 6:
        if (props.registerReducer.isSuccessful) {
          return (
            <>
              <SignupSecurity
                serviceUserPassword={serviceUserPassword}
                setserviceUserPassword={setserviceUserPassword}
                isFormFilled={isFormFilled}
                setIsFormFilled={setIsFormFilled}
              />
            </>
          );
        } else {
          handleBack();
        }
        break;
      case 7:
        if (props.registerReducer.isSuccessful) {
          return (
            <>
              <RegistrationThanks userData={userData} />
            </>
          );
        } else {
          handleBack();
        }
        break;

      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <SignupProviderLayout>
      <div className={classes.root}>
        {width < 768 ? (
          <div className="w-full hidden sm:block">
            <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        ) : (
          ""
        )}

        <div>
          {activeStep === steps.length ? (
            isRegisteredSuccessful ? (
              <Redirect to="/login" />
            ) : (
              handleBack()
            )
          ) : (
            <div>
              {getStepContent(activeStep)}

              {activeStep === steps.length - 1 ? (
                ""
              ) : (
                <>
                  {/* {(!isFormFilled || userExist) && (
                    <p
                      className="text-red-500"
                      style={
                        width > 480
                          ? {
                              position: "relative",
                              top: "-14rem",
                              left: "6rem",
                            }
                          : {
                              position: "relative",
                              top: "-14rem",
                              left: "1rem",
                            }
                      }
                    >
                      {userExistError}
                    </p>
                  )} */}
                  {/* {activeStep !== 2 || 7 ? ( */}
                  {activeStep !== 6 ? (
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      style={handleWidthOfButtonFour()}
                    >
                      <span className="capitalize text-base font-medium px-3">Back</span>
                    </Button>
                  ) : (
                    ""
                  )}
                  <>
                  {activeStep === 5 && ( <Button variant="contained" style={handleWidthOfButtonThree()} onClick={handleNext}>
                      <span className="capitalize">Skip</span>
                    </Button>)}
                    <Button
                      disabled={!isFormFilled || userExist}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={handleWidthOfButtonTwo()}
                    >
                      <span className="capitalize text-base font-medium">
                        {activeStep === steps.length - 1 ? "Finish" : "Continue"}
                      </span>
                    </Button>
                  </>
                </>
              )}
            </div>
          )}
             <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message="Please wait..."
          autoHideDuration={2500}
        />
        </div>
      </div>
    </SignupProviderLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createServiceUser,
  verifyPhoneNumber,
  setPassword,
  updateUserData,
  saveRegisteredUserDataToState,
  clearRegisterRelatedErrors,
  checkIfUserExist,
  resetCheckIfUserExist,
})(SignupUserStepper);
