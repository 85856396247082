import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { ReactComponent as PhotoIcon } from "../../assets/icons/PhotoIcon.svg";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { HeartRating } from "../Rating/Rating";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "transparent",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
}));

function PhotoSpeaks(props) {
  // const history = useHistory();
  const classes = useStyles();
  // const {providerByID} = props.browseProviderReducer
  const [open, setOpen] = useState(false);
  const [newImg, setNewImg] = useState("");

  const handleOpen = (img) => {
    setOpen(true);
    setNewImg(img);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="mt-6 max-w-2xl sm:max-w-md lg:max-w-lg">
        <div className="flex items-center gap-3">
          <PhotoIcon />
          <h3 className="text-lg font-medium">Photo Speaks</h3>
        </div>
        <div className="flex pt-4 pl-10">
          {(props?.images || [])?.map((img, index) => (
            <Button className="mr-4 cursor-pointer" onClick={() => handleOpen(img)} key={index}>
              <img src={img?.url||img} alt="" style={{maxHeight: "10rem"}}/>
            </Button>
          ))}
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} flex flex-col  outline-none text-center w-full max-w-xl`}>
            <div className="flex text-white w-full max-w-xl justify-between items-center absolute top-24">
              <div className="pl-4 font-bold">1/{props?.images?.length}</div>
              <Button onClick={handleClose}>
                <CloseRoundedIcon style={{ color: "white" }} />
              </Button>
            </div>
            <img src={newImg} alt="" className="w-full" />

            <div className="flex text-white w-full max-w-xl justify-between items-center absolute bottom-24">
              <Button>
                <div className="bg-white p-2 rounded-xl">
                  <ArrowBackIosRoundedIcon />
                </div>
              </Button>
              <Button>
                <div className="bg-white px-2 pt-1.5 rounded-xl">
                  <HeartRating color="red" />
                </div>
              </Button>
              <Button>
                <div className="bg-white p-2 rounded-xl">
                  <ArrowForwardIosRoundedIcon />
                </div>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(PhotoSpeaks);
