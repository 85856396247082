const initialState = {
    providerData: [],
    singleProviderView:{},
    categoryName:"",
    searchValue:"",
  };
  
  export const browseProviderReducer = (state = initialState, actions) => {
    const { type, payload } = actions;

    if (type === "SAVE_BROWSE_PROVIDER_DATA") {
      return {
        ...state,
        providerData: payload,
        isSuccessful: true,
        error: null,
        // message: payload.message
      };
    } else if (type === "SAVE_PROVIDER_BY_ID_DATA") {
      return {
        ...state,
        providerByID: payload,
        isSuccessful: true,
        error: null,
        // message: payload.message
      };
    }
    else if (type === "BROWSE_PROVIDER_ERROR") {
      return {
        ...state,
        error: payload,
        isSuccessful: false,
      };
    }  else if (type === "CLEAR_BROWSE_PROVIDER_RELATED_ERROR") {
      let error = null;
      let message = "";
  
      return {
        ...state,
        error,
        message,
      };
    }else if (type==="VIEW_SINGLE_PROVIDER"){
      return {
        ...state,
        singleProviderView:payload
      };
    }else if (type==="SET_CATEGORY_NAME"){
      return {
        ...state,
        categoryName:payload
      };
    }else if (type === "SET_SEARCH_VALUE"){
      return {
        ...state,
        searchValue:payload
      }
    }
  
    return state;
  };
  