import React from "react";
import { Link } from "react-router-dom";
import BodyLayout from "../Layout/BodyLayout";
import { setRegistrationAction } from "../../redux/actions/register";
import { connect } from "react-redux";

function SignupBody(props) {

  return (
    <BodyLayout>
      <h3 className="text-2xl">Who are you?</h3>
      <p className="text-base sm:text-lg text-gray-500 pt-2">
        To get started, choose the type of user you want to be on dikooko.
      </p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4 sm:rounded-lg">
        <Link to="/signup-provider" className="w-full px-6 pb-6 rounded-lg border-black text-left border-2  transition duration-500 ease-in-out hover:bg-black hover:text-white regBtnGrp"
        onClick={()=>props.setRegistrationAction("Service Provider")}>
          <p className="text-xl sm:text-2xl pt-6 pb-2 font-medium">
            A Service Provider
          </p>
          <p className="text-gray-500 text-description">
            I want to connect with service users who can see my past works and
            profile.
          </p>
        </Link>
        <Link
          to="/signup-user"
          className="w-full px-6 pb-6 rounded-lg border-black text-left border-2  transition duration-500 ease-in-out hover:bg-black hover:text-white regBtnGrp"
          onClick={()=>props.setRegistrationAction("Service User")}>
          <p className="text-xl sm:text-2xl pt-6 pb-2 font-medium">
            A Service User
          </p>
          <p className="text-gray-500 text-description">
            I want to connect with service providers who have been vetted by
            other service users.
          </p>
        </Link>
      </div>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  setRegistrationAction,
})(SignupBody);
