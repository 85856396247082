import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  }));

export default function MessageInput() {
  const classes = useStyles();

  return (
    <div className="flex w-full">
      <div className={classes.root}>
        <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>
      </div>
      <input
        type="text"
        placeholder="Type your message"
        className="border border-gray-500 rounded-full w-full sm:w-1/2 px-4 py-1.5"
      />
      <IconButton
        // variant="contained"
        color="primary"
        // className={classes.button}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
}
