import React, { useEffect, useState } from "react";
import { Button, Radio, RadioGroup, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ReactComponent as FiveStars } from "../../assets/icons/FiveStars.svg";
import { ReactComponent as FourStars } from "../../assets/icons/FourStars.svg";
import { ReactComponent as ThreeStars } from "../../assets/icons/ThreeStars.svg";
import { ReactComponent as TwoStars } from "../../assets/icons/TwoStars.svg";
import { ReactComponent as OneStar } from "../../assets/icons/OneStar.svg";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { browseServiceProvider } from "../../redux/actions/browseServiceProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  clearFilterButton: {
    marginTop: theme.spacing(1),
  },
}));

function CardRatingLocation({ browseServiceProvider, ...props }) {
  const classes = useStyles();
  const [city, setcity] = useState("");
  const [ratingValue, setratingValue] = useState("0");
  const { categoryName } = props.browseProviderReducer;
  const handleChange = (event) => {
    if (location.lagos || location.oyo) {
      setcity(event.target.value);
    } else {
      setcity("");
    }
  };

  const [location, setlocation] = useState({
    lagos: false,
    oyo: false,
    nearMe: false,
  });

  const handleStarCheckChange = (event) => {
    setratingValue(event.target.value);
  };

  const handleLocationCheckChange = (event) => {
    setlocation({ ...location, [event.target.name]: event.target.checked });
  };

  const handleClearFilter = () => {
    setlocation({
      lagos: false,
      oyo: false,
      nearMe: false,
    });
    setratingValue("0");
    setcity("");
  };
  useEffect(() => {
    browseServiceProvider(
      (categoryName || "").replace(/&/g, "_").replace(/ /g, ""),
      city,
      location.lagos && location.oyo ? "" : location.lagos ? "lagos" : location.oyo ? "oyo" : "",
      ratingValue
    );
  }, [categoryName, city, location, ratingValue, browseServiceProvider]);

  return (
    <div>
      <div>
        <h3 className="font-medium mb-3">RATINGS</h3>
        <FormControl component="fieldset">
          <RadioGroup aria-label="star" name="star" value={ratingValue} onChange={handleStarCheckChange}>
            <FormControlLabel
              className="checkboxLabel text-gray-500"
              control={<Radio color="primary" />}
              value="5"
              label={
                <span className="flex items-center">
                  <FiveStars /> & above
                </span>
              }
            />
            <FormControlLabel
              className="checkboxLabel text-gray-500"
              control={<Radio color="primary" />}
              value="4"
              label={
                <span className="flex items-center">
                  <FourStars /> & above
                </span>
              }
            />
            <FormControlLabel
              className="checkboxLabel text-gray-500"
              control={<Radio color="primary" />}
              value="3"
              label={
                <span className="flex items-center">
                  <ThreeStars /> & above
                </span>
              }
            />
            <FormControlLabel
              className="checkboxLabel text-gray-500"
              control={<Radio color="primary" />}
              value="2"
              label={
                <span className="flex items-center">
                  <TwoStars /> & above
                </span>
              }
            />
            <FormControlLabel
              className="checkboxLabel text-gray-500"
              control={<Radio color="primary" />}
              value="1"
              label={
                <span className="flex items-center">
                  <OneStar /> & above
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="py-4">
        <hr className="text-gray-900 w-full" style={{ borderColor: "#A6A6A6" }} />
      </div>
      <div className="">
        <h3 className="font-medium my-3">LOCATION</h3>

        <FormControlLabel
          className="checkboxLabel items-center text-gray-500"
          control={<Checkbox checked={location.lagos} onChange={handleLocationCheckChange} name="lagos" color="primary" />}
          label={<div className="text-base pt-1">Lagos</div>}
        />
        <br />
        <FormControlLabel
          className="checkboxLabel items-center text-gray-500"
          control={<Checkbox checked={location.oyo} onChange={handleLocationCheckChange} name="oyo" color="primary" />}
          label={<div className="text-base pt-1">Oyo</div>}
        />
        <br />

        {/* <FormControlLabel
          className="checkboxLabel items-center text-gray-500"
          control={
            <Checkbox
              checked={location.nearMe}
              onChange={handleLocationCheckChange}
              name="nearMe"
              color="primary"
            />
          }
          label={<div className="text-base pt-1">Near Me</div>}
        /> */}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Search by City</InputLabel>
          {location.lagos && !location.oyo ? (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={city}
              onChange={handleChange}
              label="Search by City"
            >
              <MenuItem value=" Victoria Island">Victoria Island</MenuItem>
              <MenuItem value="Ikeja">Ikeja</MenuItem>
              <MenuItem value="Lekki">Lekki</MenuItem>
              <MenuItem value="Ajah">Ajah</MenuItem>
              <MenuItem value="Surulere">Surulere</MenuItem>
              <MenuItem value="Ikoyi">Ikoyi</MenuItem>
            </Select>
          ) : location.oyo && !location.lagos ? (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={city}
              onChange={handleChange}
              label="Search by City"
            >
              <MenuItem value="Ibadan- Oluyole/Onireke">Ibadan- Oluyole/Onireke</MenuItem>
              <MenuItem value="Ibadan- Agodi/Bodija">Ibadan- Agodi/Bodija</MenuItem>
              <MenuItem value="Ibadan- Iyaganku/Jericho">Ibadan- Iyaganku/Jericho</MenuItem>
              <MenuItem value="Ibadan- Ologuneru">Ibadan- Ologuneru</MenuItem>
              <MenuItem value="Ibadan- Akobo">Ibadan- Akobo</MenuItem>
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={city}
              onChange={handleChange}
              label="Search by City"
            >
              <MenuItem value="">Select One State</MenuItem>
            </Select>
          )}
        </FormControl>

        <Button onClick={handleClearFilter} variant="outlined" color="primary" className={classes.clearFilterButton}>
          <span className="font-medium capitalize"> Clear Filters</span>
        </Button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { browseServiceProvider })(CardRatingLocation);
