import React from "react";
import BodyLayout from "../Layout/BodyLayout";
import { ReactComponent as AutomotiveTiny } from "../../assets/icons/AutomotiveTiny.svg";
import { ReactComponent as BuildingTiny } from "../../assets/icons/BuildingTiny.svg";
import { ReactComponent as CaregivingTiny } from "../../assets/icons/CaregivingTiny.svg";
import { ReactComponent as CateringTiny } from "../../assets/icons/CateringTiny.svg";
import { ReactComponent as CleaningTiny } from "../../assets/icons/CleaningTiny.svg";
import { ReactComponent as EventPlanningTiny } from "../../assets/icons/EventPlanningTiny.svg";
import { ReactComponent as FashionDesigningTiny } from "../../assets/icons/FashionDesigningTiny.svg";
import { ReactComponent as HealthTiny } from "../../assets/icons/HealthTiny.svg";
import { ReactComponent as LaundryTiny } from "../../assets/icons/LaundryTiny.svg";
import { ReactComponent as LogisticsTiny } from "../../assets/icons/LogisticsTiny.svg";
import { ReactComponent as MaintenanceTiny } from "../../assets/icons/MaintenanceTiny.svg";
// import { ReactComponent as MiscellaneousTiny } from "../../assets/icons/MiscellaneousTiny.svg";
import { ReactComponent as OthersTiny } from "../../assets/icons/OthersTiny.svg";
import { MuiCategoryButton } from "../Button/CategoryButton";

export default function SignupServiceCategory(props) {

  const [check, setCheck] = React.useState({
    Automotive: false,
    Building: false,
    Caregiving: false,
    Catering: false,
    Cleaning: false,
    Event_Planning: false,
    Fashion_Clothing: false,
    Wellness_Beauty: false,
    Telecoms: false,
    Logistics: false,
    Maintenance: false,
    // Miscellaneous: false,
    Others: false,
  });

  const handleCheckChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };
  let keys = Object.keys(check);

  props.otherInformation.categories = keys.filter(function (key) {
    return check[key];
  });

  let greaterThanOrEqualTo3 = false;

  if (props.otherInformation.categories?.length >= 3) {
    greaterThanOrEqualTo3 = true;
  } else {
    greaterThanOrEqualTo3 = false;
  }

  if (props.otherInformation.categories?.length > 0 && props.otherInformation.categories?.length < 4){
    props.setIsFormFilled(true);
  }else{
    props.setIsFormFilled(false);
  }

  return (
    <BodyLayout height="h-full" maxWidth="lg:max-w-xl">
      <h3 className="text-xl sm:text-2xl">Service Category</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        What are you offering to the public?
      </p>
      <br />
      <p className={`text-sm ${greaterThanOrEqualTo3 ? "text-red-500" : ""}`}>
        You can only select up to 3 categories
      </p>

      <div className="flex flex-col gap-2 mt-4">
        <div className="flex gap-1  flex-wrap MuiCategory_wrapper">
          <MuiCategoryButton
            name="Automotive"
            itemName="Automotive"
            disabled={
              greaterThanOrEqualTo3 === true && check.Automotive === false
                ? true
                : false
            }
            checked={check.Automotive}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <AutomotiveTiny
                className={check.Automotive ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />

          <MuiCategoryButton
            name="Building"
            itemName="Building"
            disabled={
              greaterThanOrEqualTo3 === true && check.Building === false
                ? true
                : false
            }
            checked={check.Building}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <BuildingTiny
                className={check.Building ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />

          <MuiCategoryButton
            name="Catering"
            itemName="Catering"
            disabled={
              greaterThanOrEqualTo3 === true && check.Catering === false
                ? true
                : false
            }
            checked={check.Catering}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <CateringTiny
                className={check.Catering ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
        </div>

        <div className="flex gap-1  flex-wrap ">
          <MuiCategoryButton
            name="Caregiving"
            itemName="Caregiving"
            disabled={
              greaterThanOrEqualTo3 === true && check.Caregiving === false
                ? true
                : false
            }
            checked={check.Caregiving}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <CaregivingTiny
                className={check.Caregiving ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
          <MuiCategoryButton
            name="Cleaning"
            itemName="Cleaning"
            disabled={
              greaterThanOrEqualTo3 === true && check.Cleaning === false
                ? true
                : false
            }
            checked={check.Cleaning}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <CleaningTiny
                className={check.Cleaning ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />

          <MuiCategoryButton
            name="Event_Planning"
            itemName="Event Planning"
            disabled={
              greaterThanOrEqualTo3 === true && check.Event_Planning === false
                ? true
                : false
            }
            checked={check.Event_Planning}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <EventPlanningTiny
                className={check.Event_Planning ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
        </div>

        <div className="flex gap-1  flex-wrap ">
          <MuiCategoryButton
            name="Fashion_Clothing"
            itemName="Fashion & Clothing"
            disabled={
              greaterThanOrEqualTo3 === true &&
              check.Fashion_Clothing === false
                ? true
                : false
            }
            checked={check.Fashion_Clothing}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <FashionDesigningTiny
                className={
                  check.Fashion_Clothing ? "mr-2 filter invert" : "mr-2"
                }
              />
            }
          />

          <MuiCategoryButton
            name="Wellness_Beauty"
            itemName="Wellness & Beauty"
            disabled={
              greaterThanOrEqualTo3 === true && check.Wellness_Beauty === false
                ? true
                : false
            }
            checked={check.Wellness_Beauty}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <HealthTiny
                className={check.Wellness_Beauty ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />

          <MuiCategoryButton
            name="Telecoms"
            itemName="Telecoms"
            disabled={
              greaterThanOrEqualTo3 === true && check.Telecoms === false
                ? true
                : false
            }
            checked={check.Telecoms}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <LaundryTiny
                className={check.Telecoms ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
        </div>
        <div className="flex gap-1  flex-wrap ">
          <MuiCategoryButton
            name="Logistics"
            itemName="Logistics"
            disabled={
              greaterThanOrEqualTo3 === true && check.Logistics === false
                ? true
                : false
            }
            checked={check.Logistics}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <LogisticsTiny
                className={check.Logistics ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
          {/* <MuiCategoryButton
            name="Miscellaneous"
            itemName="Miscellaneous"
            disabled={
              greaterThanOrEqualTo3 === true && check.Miscellaneous === false
                ? true
                : false
            }
            checked={check.Miscellaneous}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <MiscellaneousTiny
                className={check.Miscellaneous ? "mr-2 filter invert" : "mr-2"}
              />
            }
          /> */}

          <MuiCategoryButton
            name="Maintenance"
            itemName="Maintenance"
            disabled={
              greaterThanOrEqualTo3 === true && check.Maintenance === false
                ? true
                : false
            }
            checked={check.Maintenance}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <MaintenanceTiny
                className={check.Maintenance ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
        </div>
        <div className="flex gap-1  flex-wrap ">
          <MuiCategoryButton
            name="Others"
            itemName="Others"
            disabled={
              greaterThanOrEqualTo3 === true && check.Others === false
                ? true
                : false
            }
            checked={check.Others}
            handleCheckChange={handleCheckChange}
            IconGrp={
              <OthersTiny
                className={check.Others ? "mr-2 filter invert" : "mr-2"}
              />
            }
          />
        </div>
      </div>
      <br />
      <br />
    </BodyLayout>
  );
}
