import React from "react";
import Footer from "../components/Footer/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, saveLoginUserDataToState, clearLoginRelatedErrors } from "../redux/actions/login";
import JobRecordsBody from "../components/UserNProviderUtility/JobRecordsBody";
import WebServiceProvider from "../components/SideNav/WebServiceProvider";
import MessagesBody from "../components/UserNProviderUtility/MessagesBody";
import NotificationsBody from "../components/Notifications/NotificationsBody";
import ProfileBody from "../components/UserNProviderUtility/ProfileBody";
import useWindowDimensions from "../Hooks/UseWindowDimension";
import Header from "../components/Header/Header";
import Visitor from "../components/ServiceUser/Visitor";
import ContactSupportBody from "../components/UserNProviderUtility/ContactSupportBody";

function ServiceProviderPage(props) {
  const { width } = useWindowDimensions();
  const { isAuthenticated, user } = props.loginReducer;
  const username = `Welcome, ${user.firstName}`;

  return (
    <>
      {(!isAuthenticated || user.userType === "user") && <Redirect to="/" />}
      {!isAuthenticated || width < 640 ? <Header showRegisterButton={true} showLoginButton={width > 280 ? true : false} /> : ""}
      {isAuthenticated &&<div className="absolute z-2 right-0 hidden md:block float-right pt-5 pr-12">{username}</div>}

      <div className="flex max-w-screen-2xl min-h-screen mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <Router>
          <WebServiceProvider />
          <div className="w-full px-2 sm:ml-72">

          <Route exact path="/provider/">
            <JobRecordsBody />
          </Route>
          <Route exact path="/provider/job-records">
            <JobRecordsBody />
          </Route>
          <Route exact path="/provider/user-review" component={Visitor} />
          
          <Route exact path="/provider/messages">
            <MessagesBody name="John Krover" lastMessage="Hello, I have a job that req..." />
          </Route>
          <Route exact path="/provider/notifications">
            <NotificationsBody />
          </Route>

          <Route exact path="/provider/profile">
            <ProfileBody />
          </Route>

          <Route exact path="/provider/contact-support">
            <ContactSupportBody/>
          </Route>
          </div>
        </Router>
      </div>

      {window.location.pathname.includes("contact-support" || "messag") || width < 640 || isAuthenticated ? "" : <Footer />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  login,
  saveLoginUserDataToState,
  clearLoginRelatedErrors,
})(ServiceProviderPage);
