import axios from "axios";
import { BASE_URL, requestHeaders } from "../../constants/config";
import * as actionTypes from "./types";
// import { userType } from "../reducers/loginReducer";

export const addJobRecord = (userData, userExist, token) => {
  let newJobRecord = new FormData();

  if (userExist) {
    if(userData?.provider){newJobRecord.append("provider", userData?.provider)};
    if(userData?.client){newJobRecord.append("client", userData?.client)};
    if(userData?.description){newJobRecord.append("description", userData?.description)};
    if(userData?.location){newJobRecord.append("location", userData?.location)};
    if(userData?.image){newJobRecord.append("image", userData?.image)};
  } else {
    if(userData?.provider){newJobRecord.append("provider", userData?.provider)};
    if(userData?.clientName){newJobRecord.append("clientName", userData?.clientName)};
    if(userData?.clientEmail){newJobRecord.append("clientEmail", userData?.clientEmail)};
    if(userData?.clientPhone){newJobRecord.append("clientPhone", userData?.clientPhone)};
    if(userData?.image){newJobRecord.append("image", userData?.image)};
    if(userData?.location){newJobRecord.append("location", userData?.location)};
    if(userData?.description){newJobRecord.append("description", userData?.description)};
  }

  // console.log("newJoRecord", newJobRecord.keys,newJobRecord.values,newJobRecord.entries);

  return async (dispatch) => {
    try {
      const addJobRecordData = await axios.post(`${BASE_URL}job/record-job`, newJobRecord, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (addJobRecordData.status === 200 || 201) {
        // console.log("addJobRecordData=",addJobRecordData.getAllResponseHeaders());
        // console.log(addJobRecordData.headers.get("Authorization"));
        dispatch(saveAddJobRecordDataToState(addJobRecordData));
      }

      //   console.log(addJobRecordData);
    } catch (error) {
      //   console.log("addJobRecordData error", error);
      dispatch(addJobRecordError(error.response));
    }
  };
};

export const saveAddJobRecordDataToState = (providerData) => {
  return {
    type: actionTypes.SAVE_ADD_JOB_RECORD_DATA_TO_STATE,
    payload: providerData,
  };
};

export const addJobRecordError = (data) => {
  return {
    type: actionTypes.ADD_JOB_RECORD_ERROR,
    payload:data
  };
};

export const getJobRecord = (ID, token) => {
  return async (dispatch) => {
    try {
      const userData = await axios.get(`${BASE_URL}job/${ID}`, requestHeaders);
      //   console.log(userData.data);
      //   console.log(userType);
      if (userData.status === 200) {
        dispatch(saveGetJobRecordDataToState(userData.data));
      }
    } catch (error) {
      //   console.log("login error", error);
      dispatch(getJobRecordError(error.response));
    }
  };
};

export const getClientJobRecord = (ID) => {
  return async (dispatch) => {
    try {
      const userData = await axios.get(`${BASE_URL}job/client-jobs/${ID}`, requestHeaders);
      //   console.log(userData.data);
      //   console.log(userType);
      if (userData.status === 200) {
        dispatch(saveGetJobRecordDataToState(userData.data));
      }
    } catch (error) {
      //   console.log("login error", error);
      dispatch(getJobRecordError(error.response));
    }
  };
};

export const getProviderJobRecord = (ID) => {
  return async (dispatch) => {
    try {
      const userData = await axios.get(`${BASE_URL}job/provider-jobs/${ID}`, requestHeaders);
      //   console.log(userData.data);
      //   console.log(userType);
      if (userData.status === 200) {
        dispatch(saveGetJobRecordDataToState(userData.data));
      }
    } catch (error) {
      //   console.log("login error", error);
      dispatch(getJobRecordError(error.response));
    }
  };
};


export const saveGetJobRecordDataToState = (userData) => {
  return {
    type: actionTypes.SAVE_GET_JOB_RECORD_DATA_TO_STATE,
    payload: userData,
  };
};

export const getJobRecordError = (err) => {
  return {
    type: actionTypes.GET_JOB_RECORD_ERROR,
    payload: err,
  };
};

export const reviewJobRecord = (ID, data, token) => {
  return async (dispatch) => {
    try {
      const userData = await axios.post(`${BASE_URL}job/review/${ID}`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (userData.status === 200 || 201) {
        dispatch(saveReviewJobRecordDataToState(userData.data));
      }
    } catch (error) {
      dispatch(reviewJobRecordError(error.response));
    }
  };
};

export const saveReviewJobRecordDataToState = (userData) => {
  return {
    type: actionTypes.SAVE_REVIEW_JOB_RECORD_DATA_TO_STATE,
    payload: userData,
  };
};

export const reviewJobRecordError = (err) => {
  return {
    type: actionTypes.REVIEW_JOB_RECORD_ERROR,
    payload: err,
  };
};
export const setSingleJobRecord = (data) => {
  return {
    type: actionTypes.SET_SINGLE_JOB_RECORD,
    payload: data,
  };
};

export const deleteJobRecord=(jobId,token)=>{
  return async (dispatch) => {
    try {
      const deleteJobRecordData = await axios.delete(`${BASE_URL}job/delete/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });

      (deleteJobRecordData.status === 200 || 201) &&
        dispatch(deletedJobRecordSuccessfully(deleteJobRecordData));
    } catch (error) {
      dispatch(deleteJobRecordError(error?.response));
    }
  };
}

export const deletedJobRecordSuccessfully = (userData) => {
  return {
    type: actionTypes.DELETED_JOB_RECORD_SUCCESSFULLY,
    payload: userData,
  };
};


export const deleteJobRecordError = (err) => {
  return {
    type: actionTypes.DELETE_JOB_RECORD_ERROR,
    payload: err,
  };
};



export const editJobRecord=(jobId,token,formData)=>{
  return async (dispatch) => {
    try {
      const editJobRecordData = await axios.put(`${BASE_URL}job/edit/${jobId}`, formData, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });

      (editJobRecordData.status === 200 || 201) &&
        dispatch(editedJobRecordSuccessfully(editJobRecordData));
    } catch (error) {
      dispatch(editJobRecordError(error?.response));
    }
  };
}

export const editedJobRecordSuccessfully = (userData) => {
  return {
    type: actionTypes.EDITED_JOB_RECORD_SUCCESSFULLY,
    payload: userData,
  };
};


export const editJobRecordError = (err) => {
  return {
    type: actionTypes.EDIT_JOB_RECORD_ERROR,
    payload: err,
  };
};