import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as JobRecordIcon } from "../../assets/icons/JobRecordIcon.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/MessagesIcon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/NotificationIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/ProfileIcon.svg";
import { ReactComponent as ContactSupportIcon } from "../../assets/icons/ContactSupportIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/LogoutIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/HomeIcon.svg";

export default function MobileSideNav(props) {
  return (
    <div className="flex flex-col justify-between h-full text-sm sm:text-base">
      <div className="px-4 flex flex-col gap-3 mt-6">
        {props.userType === "Service User" ? (
          <>
            <NavLink
              to="/user"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/user"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/user")}
            >
              <HomeIcon className={`mr-2  ${props.currentPath === "/user" && "svg-fill-white"}`} />
              <span> HOME </span>
            </NavLink>
            <NavLink
              to="/user/messages"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/user/messages"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/user/messages")}
            >
              <MessagesIcon
                className={`mr-2  ${props.currentPath === "/user/messages" && "svg-fill-white"}`}
              />
              <span>MESSAGES </span>
            </NavLink>
            <NavLink
              to="/user/notifications"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/user/notifications"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/user/notifications")}
            >
              <NotificationIcon
                className={`mr-2  ${
                  props.currentPath === "/user/notifications" && "svg-fill-white"
                }`}
              />
              <span> NOTIFICATIONS </span>
            </NavLink>
            <NavLink
              to="/user/profile"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/user/profile"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/user/profile")}
            >
              <ProfileIcon
                className={`mr-2  ${props.currentPath === "/user/profile" && "svg-stroke-white"}`}
              />
              <span> PROFILE </span>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              to="/provider/job-records"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/provider/job-records"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/provider/job-records")}
            >
              <JobRecordIcon
                className={`mr-2  ${
                  props.currentPath === "/provider/job-records" && "svg-fill-white"
                }`}
              />
              <span> JOB RECORDS </span>
            </NavLink>
            <NavLink
              to="/provider/messages"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/provider/messages"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/provider/messages")}
            >
              <MessagesIcon
                className={`mr-2  ${
                  props.currentPath === "/provider/messages" && "svg-fill-white"
                }`}
              />
              <span>MESSAGES </span>
            </NavLink>
            <NavLink
              to="/provider/notifications"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/provider/notifications"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/provider/notifications")}
            >
              <NotificationIcon
                className={`mr-2  ${
                  props.currentPath === "/provider/notifications" && "svg-fill-white"
                }`}
              />
              <span> NOTIFICATIONS </span>
            </NavLink>
            <NavLink
              to="/provider/profile"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                props.currentPath === "/provider/profile"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => props.handleChangeRoute("/provider/profile")}
            >
              <ProfileIcon
                className={`mr-2  ${
                  props.currentPath === "/provider/profile" && "svg-stroke-white"
                }`}
              />
              <span> PROFILE </span>
            </NavLink>
          </>
        )}
      </div>

      <div className="mb-16 pt-10 px-4 border-t border-gray-200 flex flex-col gap-6">
        <NavLink
          to={
            props.userType === "Service User"
              ? "/user/contact-support"
              : "/provider/contact-support"
          }
          className={`flex gap-3 px-2 whitespace-nowrap  ${
            props.currentPath === ("/user/contact-support" || "/provider/contact-support")
              ? "bg-black hover:text-white text-white py-3 rounded"
              : "text-black hover:text-black py-3"
          }`}
          onClick={() =>
            props.handleChangeRoute(
              props.userType === "Service User"
                ? "/user/contact-support"
                : "/provider/contact-support"
            )
          }
        >
          <ContactSupportIcon
            className={
              props.currentPath === ("/user/contact-support" || "/provider/contact-support") &&
              "svg-fill-white"
            }
          />
          <span> CONTACT SUPPORT </span>
        </NavLink>
        <Link to="/" onClick={props.handleLogout} className="flex gap-3 whitespace-nowrap">
          <LogoutIcon className="mr-2" />
          <span>LOGOUT </span>
        </Link>
      </div>
    </div>
  );
}
