import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

export default function Unavailable() {
  return (
    <div class="flex flex-col justify-between w-full h-screen bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
      <Header />

      <div className="px-4 text-center -mt-12">
        <h3 className="text-2xl sm:text-4xl font-semibold">404</h3>
        <p className="text-gray-500 mt-6 sm:text-xl">The Page you're looking for is currently Unavailable</p>
      </div>

      <Footer />
    </div>
  );
}
