import React, { useState } from "react";
import { ReactComponent as JobRecordIcon } from "../../assets/icons/JobRecordIcon.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/MessagesIcon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/NotificationIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/ProfileIcon.svg";
import { ReactComponent as ContactSupportIcon } from "../../assets/icons/ContactSupportIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/LogoutIcon.svg";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/actions/login";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as Logo2 } from "../../assets/icons/Logo2.svg";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import SideNavWrapper from "./SideNavWrapper";

function WebServiceProvider(props) {
  const [currentPath, setcurrentPath] = useState(window.location.pathname);
  const { width } = useWindowDimensions();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    props.logout();
  };
  return (
    <SideNavWrapper>
        <div className="px-10 flex flex-col gap-3">
          <Link
            to="/provider/job-records"
            className="py-10 text-xl sm:text-2xl font-bold pl-3 sm:pl-0"
            onClick={() => setcurrentPath("/provider/job-records")}
          >
            {width > 640 ? <Logo className="p-1.5 sm:p-0" /> : <Logo2 className={width > 300 ? "ml-3" : ""} />}
          </Link>
          <NavLink
            to="/provider/job-records"
            className={`flex gap-3 pr-4 whitespace-nowrap  ${
              currentPath === "/provider/job-records"
                ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                : "text-black hover:text-black py-3 ml-4"
            }`}
            onClick={() => setcurrentPath("/provider/job-records")}
          >
            <JobRecordIcon className={`mr-2  ${currentPath === "/provider/job-records" && "svg-fill-white"}`} />
            <span> JOB RECORDS </span>
          </NavLink>
          <NavLink
            to="/provider/messages"
            className={`flex gap-3 pr-4 whitespace-nowrap  ${
              currentPath === "/provider/messages"
                ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                : "text-black hover:text-black py-3 ml-4"
            }`}
            onClick={() => setcurrentPath("/provider/messages")}
          >
            <MessagesIcon className={`mr-2  ${currentPath === "/provider/messages" && "svg-fill-white"}`} />
            <span>MESSAGES </span>
          </NavLink>
          <NavLink
            to="/provider/notifications"
            className={`flex gap-3 pr-4 whitespace-nowrap  ${
              currentPath === "/provider/notifications"
                ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                : "text-black hover:text-black py-3 ml-4"
            }`}
            onClick={() => setcurrentPath("/provider/notifications")}
          >
            <NotificationIcon className={`mr-2  ${currentPath === "/provider/notifications" && "svg-fill-white"}`} />
            <span> NOTIFICATIONS </span>
          </NavLink>
          <NavLink
            to="/provider/profile"
            className={`flex gap-3 pr-4 whitespace-nowrap  ${
              currentPath === "/provider/profile" ? "bg-black hover:text-white text-white py-3 pl-4 rounded" : "text-black hover:text-black py-3 ml-4"
            }`}
            onClick={() => setcurrentPath("/provider/profile")}
          >
            <ProfileIcon className={`mr-2  ${currentPath === "/provider/profile" && "svg-stroke-white"}`} />
            <span> PROFILE </span>
          </NavLink>
        </div>

        <div className="mb-16 pt-10 px-10 border-t border-gray-200 flex flex-col gap-6">
          <NavLink
            to="/provider/contact-support"
            className={`flex gap-3 pr-4 whitespace-nowrap  ${
              currentPath === "/provider/contact-support"
                ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                : "text-black hover:text-black py-3 ml-4"
            }`}
            onClick={() => setcurrentPath("/provider/contact-support")}
          >
            <ContactSupportIcon className={`mr-2  ${currentPath === "/provider/contact-support" && "svg-fill-white"}`} />
            <span> CONTACT SUPPORT </span>
          </NavLink>
          <Link to="/" onClick={handleLogout} className="flex gap-3 whitespace-nowrap">
            <LogoutIcon className="mr-2" />
            <span>LOGOUT </span>
          </Link>
        </div>
    </SideNavWrapper>
  );
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { logout })(WebServiceProvider);
