import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WebSideNav from "../components/SideNav/IntroWebSideNav";
import SignupBody from "../components/Auth/SignupBody";

export default function SignUp() {
  return (
    <>
      <Header signupPage={true} showLoginButton={true} haveAccountText="You have a dikooko account already?"/>

      <div className="flex max-w-screen-2xl mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">

        <WebSideNav
          title="Register to Enjoy What dikooko is Offering"
          body="Registering on the platform grants you access to service users and providers."
        />
        <SignupBody />
      </div>

      <Footer />
    </>
  );
}
