//LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SAVE_LOGGED_IN_USER_DATA = "SAVE_LOGGED_IN_USER_DATA";
export const LOGIN_ERROR = "LOGIN_ERROR";

//REGISTRATION
export const SAVE_REGISTERED_USER_DATA = "SAVE_REGISTERED_USER_DATA";
export const SAVE_REGISTERED_USER_ID = "SAVE_REGISTERED_USER_ID";
export const SAVE_REGISTERED_USER_TOKEN = "SAVE_REGISTERED_USER_TOKEN";
export const IS_USER_VERIFIED = "IS_USER_VERIFIED";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const CLEAR_REGISTER_RELATED_ERROR = "CLEAR_REGISTER_RELATED_ERROR";
export const LOGOUT = "LOGOUT";
export const CLEAR_LOGIN_RELATED_ERROR = "CLEAR_LOGIN_RELATED_ERROR";
export const COMPLETE_USER_REGISTRAION_DATA = "COMPLETE_USER_REGISTRAION_DATA";
export const SET_REGISTRATION_TYPE = "SET_REGISTRATION_TYPE";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_REGISTRATION_ACTION = "SET_REGISTRATION_ACTION";
export const PHONE_VERIFIED = "PHONE_VERIFIED";
export const SAVE_REVIEWED_PROVIDER_DATA = "SAVE_REVIEWED_PROVIDER_DATA";
export const SAVE_PASSWORD_RESET_DATA = "SAVE_PASSWORD_RESET_DATA";
export const SAVE_CHECK_IF_USER_EXIST_DATA_TO_STATE ="SAVE_CHECK_IF_USER_EXIST_DATA_TO_STATE";
export const CHECK_IF_USER_EXIST_ERROR ="CHECK_IF_USER_EXIST_ERROR";
export const RESET_CHECK_IF_USER_EXIST = "RESET_CHECK_IF_USER_EXIST";

//BROWSE PROVIDER
export const SAVE_BROWSE_PROVIDER_DATA = "SAVE_BROWSE_PROVIDER_DATA";
export const BROWSE_PROVIDER_ERROR = "BROWSE_PROVIDER_ERROR";
export const CLEAR_BROWSE_PROVIDER_RELATED_ERROR =  "CLEAR_BROWSE_PROVIDER_RELATED_ERROR";
export const SAVE_PROVIDER_BY_ID_DATA = "SAVE_PROVIDER_BY_ID_DATA";
export const VIEW_SINGLE_PROVIDER = "VIEW_SINGLE_PROVIDER";
export const SET_CATEGORY_NAME = "SET_CATEGORY_NAME";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

// JOB RECORDS
export const SAVE_ADD_JOB_RECORD_DATA_TO_STATE =  "SAVE_ADD_JOB_RECORD_DATA_TO_STATE";
export const ADD_JOB_RECORD_ERROR = "ADD_JOB_RECORD_ERROR";
export const SAVE_GET_JOB_RECORD_DATA_TO_STATE = "SAVE_GET_JOB_RECORD_DATA_TO_STATE";
export const GET_JOB_RECORD_ERROR = "GET_JOB_RECORD_ERROR";
export const SAVE_REVIEW_JOB_RECORD_DATA_TO_STATE = "SAVE_REVIEW_JOB_RECORD_DATA_TO_STATE";
export const REVIEW_JOB_RECORD_ERROR = "REVIEW_JOB_RECORD_ERROR";
export const SET_SINGLE_JOB_RECORD = "SET_SINGLE_JOB_RECORD";
export const DELETED_JOB_RECORD_SUCCESSFULLY = "DELETED_JOB_RECORD_SUCCESSFULLY";
export const DELETE_JOB_RECORD_ERROR = "DELETE_JOB_RECORD_ERROR";
export const EDIT_JOB_RECORD_ERROR = "EDIT_JOB_RECORD_ERROR";
export const EDITED_JOB_RECORD_SUCCESSFULLY = "EDITED_JOB_RECORD_SUCCESSFULLY";


//PASSWORD UPDATE
export const SAVE_PASSWORD_UPDATE_DATA = "SAVE_PASSWORD_UPDATE_DATA";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

//PROFILE
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const SAVE_UPDATE_PROFILE_DATA = "SAVE_UPDATE_PROFILE_DATA";
export const LOGGED_IN_USER_DETAILS_ERROR = "LOGGED_IN_USER_DETAILS_ERROR";
export const SAVE_GET_LOGGED_IN_USER_DETAILS_DATA = "SAVE_GET_LOGGED_IN_USER_DETAILS_DATA";
