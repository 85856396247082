
import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WebSideNav from "../components/SideNav/IntroWebSideNav";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import { login, saveLoginUserDataToState,clearLoginRelatedErrors } from "../redux/actions/login";
import PasswordResetSuccess from "../components/Auth/PasswordResetSuccess";
// import ForgotPasswordBody from "../components/Auth/ForgotPasswordBody";


function PasswordResetSuccessPage(props) {
  return (
    <>
    {props.loginReducer.isAuthenticated && <Redirect to="/"/> }
      <Header showRegisterButton={true} showLoginButton={false}  haveAccountText="Don't have an account?"/>

      <div className="flex max-w-screen-2xl mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <WebSideNav title="We Are Super Excited To Have You Back On dikooko." body=""/>
        <PasswordResetSuccess />
      </div>

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {

  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  login,
  saveLoginUserDataToState,
  clearLoginRelatedErrors
})(PasswordResetSuccessPage);
