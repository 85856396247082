import React, { useState } from "react";
import RefereeBodyLayout from "../Layout/RefereeBodyLayout";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Button } from "@material-ui/core";
import { ReactComponent as StarIcon } from "../../assets/icons/StarIcon.svg";
import { ReactComponent as ReviewIcon } from "../../assets/icons/ReviewIcon.svg";
import { Link, useHistory } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { reviewProvider } from "../../redux/actions/register";
import { connect } from "react-redux";

function RefereeForm(props) {
  const history = useHistory();
  const [pricing, setPricing] = useState(0);
  const [reliability, setReliability] = useState(0);
  const [timeliness, setTimeliness] = useState(0);
  const [quality, setQuality] = useState(0);
  const [review, setReview] = useState("");

  const [showError, setshowError] = useState(false);

  const handleReviewChange = (event) => {
    setReview(event.target.value);

    if (review.length < 50) {
      setshowError(true);
    } else {
      setshowError(false);
    }
  };

   const handleSubmit = async (event)=>{
    event.preventDefault()
    await props.reviewProvider({
      userId: props.userId,
      providerId: props.providerId,
      pricing,
      reliability,
      timeliness,
      quality,
      review,
    });

    history.push("/review-thanks");
  }


  const userName = "Abdulkareem Kamsi";
  return (
    <RefereeBodyLayout>
      <form onSubmit={handleSubmit}>
      <div className="bg-black text-white flex justify-between p-3 sm:p-5 sm:rounded-t-lg">
        <div className="flex flex-col gap-3">
          <h3 className="text-xl sm:text-2xl md:text-3xl">Referee’s Form</h3>
          <p className="text-sm sm:text-base">
            Give your review about this service provider.
          </p>
        </div>
        <div>
          <Link to="/" className="p-3">
            <CloseRoundedIcon className="text-white" />
          </Link>
        </div>
      </div>


      <div className="px-6">
        <div className="flex justify-between py-6 gap-5">
          <div className="flex flex-col gap-2 text-base">
            <span>Service Provider’s Name</span>
            <span className="text-gray-500"> Abdulkareem Kamsi </span>
          </div>
          <div className="flex flex-col gap-2 text-base">
            <span>Service Provider’s Phone Number</span>
            <span className="text-gray-500">09045362713</span>
          </div>
        </div>
        
        <div className="pb-6">
          <div className="flex items-center gap-3">
            <StarIcon /> <h3 className="text-xl font-semibold">Job Ratings </h3>
          </div>
          <div className="flex">
            <div className="w-8"></div>
            <div className="pt-4 flex flex-col gap-3">
              <div className="flex gap-8">
                <span className="text-lg">Pricing</span>
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="pricing"
                    rating={pricing}
                    onChange={(event, newrating) => {
                      setPricing(newrating);
                    }}
                  />
                </Box>
              </div>
              <div className="flex gap-8">
                <span className="text-lg">Reliability</span>
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="reliability"
                    rating={reliability}
                    onChange={(event, newrating) => {
                      setReliability(newrating);
                    }}
                  />
                </Box>
              </div>
              <div className="flex gap-8">
                <span className="text-lg">Timeliness</span>
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="timeliness"
                    rating={timeliness}
                    onChange={(event, newrating) => {
                      setTimeliness(newrating);
                    }}
                  />
                </Box>
              </div>
              <div className="flex gap-8">
                <span className="text-lg">Quality</span>
                <Box component="fieldset" borderColor="transparent">
                  <Rating
                    name="quality"
                    rating={quality}
                    onChange={(event, newrating) => {
                      setQuality(newrating);
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>


        <div className="pt-2 pb-6">
          <div className="flex items-center gap-3">
            <ReviewIcon />
            <h3 className="text-xl font-semibold">Service Review</h3>
          </div>
          <div className="w-full pt-4">
            <textarea
              rows="10"
              required
              placeholder={`What can you say about the jobs done for you by ${userName}?`}
              style={{ minHeight: "6rem" }}
              className={`${
                showError
                  ? "border-red-500"
                  : " focus:border-gray-900 border-gray-400"
              }  w-full p-2 sm:p-3 border focus:outline-none rounded h-12 sm:h-20 md:h-28 lg:h-32 xl:h-36`}
              value={review}
              onChange={handleReviewChange}
            ></textarea>
            {showError && (
              <p className="text-sm text-red-500">
                You have to enter more than 50 characters
              </p>
            )}
          </div>
        </div>
      </div>
    
    
      <div className="mt-6 w-full">
        <div className="bg-indigo-100 w-full bg-opacity-40 flex justify-end py-2 pr-8">
          <div className="flex gap-6 spec-btns">
            <Button>
              <span className="text-base py-2 px-3 capitalize font-semibold">
                Cancel
              </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!Boolean(review) || showError}
            >
              <span className="text-base py-2 px-3 capitalize font-semibold text-white">
                Submit
              </span>
            </Button>
          </div>
        </div>
      </div>
      </form>
    </RefereeBodyLayout>
  );
}


const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  reviewProvider,
})(RefereeForm);
