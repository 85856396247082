const initialState = {
  user: {},
  isSuccessful: false,
  error: null,
  message: "",
  isPhoneVerified: false,
  userExistData: null,
  userExistError: null,
  emailCheck:{},
  phoneCheck:{},
};

export const registerReducer = (state = initialState, actions) => {
  const { type, payload } = actions;

  if (type === "SAVE_REGISTERED_USER_ID") {
    return {
      ...state,
      user: { _id: payload._id },
      isSuccessful: true,
      error: null,
      // message: payload.message
    };
  } else if (type === "SAVE_REGISTERED_USER_TOKEN") {
    return {
      ...state,
      token: payload,
      isSuccessful: true,
      error: null,
      // message: payload.message
    };
  } else if (type === "PHONE_VERIFIED") {
    return {
      ...state,
      isPhoneVerified: true,
    };
  } else if (type === "REGISTRATION_ERROR") {
    localStorage.removeItem("user");
    return {
      ...state,
      error: payload,
      isPhoneVerified: false,
      isSuccessful: false,
    };
  } else if (type === "LOGOUT") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return {
      ...state,
      user: {},
      isSuccessful: false,
      token: "",
    };
  } else if (type === "CLEAR_REGISTER_RELATED_ERROR") {
    let error = null;
    let message = "";

    return {
      ...state,
      error,
      message,
    };
  } else if (type === "SET_REGISTRATION_ACTION") {
    return {
      ...state,
      registrationAction: payload,
    };
  } else if (type === "SAVE_CHECK_IF_USER_EXIST_DATA_TO_STATE") {
    if(payload?.originalData?.email){
      return{
        ...state,
        userExistData: payload?.registeredUserData,
        userExistError: null,
        emailCheck:{
          email:payload?.originalData?.email,
          exist:payload?.registeredUserData?.exist ? true : false,
         },
      }
    }
    if(payload?.originalData?.phone){
      return{
        ...state,
        userExistData: payload?.registeredUserData,
        userExistError: null,
        phoneCheck:{
          phone:payload?.originalData?.phone,
          exist:payload?.registeredUserData?.exist? true : false,
         },
      }
    }

  } else if (type === "CHECK_IF_USER_EXIST_ERROR") {
    if(payload.originalData.email){
      return{
        ...state,
        userExistError: payload,
        userExistData: null,
        emailCheck:{
          email:payload.originalData.email,
          exist: false,
         },
      }
    }
    if(payload.originalData.phone){
      return{
        ...state,
        userExistError: payload,
        userExistData: null,
        phoneCheck:{
          phone:payload.originalData.phone,
          exist: false,
         },
      }
    }
  } else if (type === "RESET_CHECK_IF_USER_EXIST") {
    return {
      ...state,
      userExistData: null,
      emailCheck:{},
      phoneCheck:{},
    };
  }
  return state;
};
