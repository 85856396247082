import React, { useState } from "react";
import { ReactComponent as HomeIcon } from "../../assets/icons/HomeIcon.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/MessagesIcon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/NotificationIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/ProfileIcon.svg";
import { ReactComponent as ContactSupportIcon } from "../../assets/icons/ContactSupportIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/LogoutIcon.svg";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/actions/login";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as Logo2 } from "../../assets/icons/Logo2.svg";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import {setSearchValue} from "../../redux/actions/browseServiceProvider";

function WebUserAuthSideNav(props) {
  const { width } = useWindowDimensions();
  const [currentPath, setcurrentPath] = useState(window.location.pathname);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    props.logout();
  };
  return (
    <div className="max-w-sm hidden sm:block">
      <div className="h-full min-h-screen w-full text-black">
        <div className="bg-white fixed left-0 flex flex-col gap-32 xl:gap-52 h-full w-max">
          <div className="px-5 lg:px-10 flex flex-col gap-3">
            <Link to="/user" className="sm:hidden py-10 text-xl sm:text-2xl font-bold pl-3 sm:pl-0">
              {width > 640 ? <Logo className="p-1.5 sm:p-0" /> : <Logo2 className={width > 300 ? "ml-3" : ""} />}
            </Link>

            <NavLink
              to="/user"
              className={`flex gap-3 pr-4 whitespace-nowrap sm:mt-12  ${
                currentPath === "/user" ? "bg-black hover:text-white text-white py-3 pl-4 rounded" : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => {
                setcurrentPath("/user");
                props.setSearchValue("");
              }}
            >
              <HomeIcon className={`mr-2  ${currentPath === "/user" && "svg-fill-white"}`} />
              <span> HOME </span>
            </NavLink>
            <NavLink
              to="/user/messages"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                currentPath === "/user/messages" ? "bg-black hover:text-white text-white py-3 pl-4 rounded" : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => {
                setcurrentPath("/user/messages");
              }}
            >
              <MessagesIcon className={`mr-2  ${currentPath === "/user/messages" && "svg-fill-white"}`} />
              <span>MESSAGES </span>
            </NavLink>
            <NavLink
              to="/user/notifications"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                currentPath === "/user/notifications"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => setcurrentPath("/user/notifications")}
            >
              <NotificationIcon className={`mr-2  ${currentPath === "/user/notifications" && "svg-fill-white"}`} />
              <span> NOTIFICATIONS </span>
            </NavLink>
            <NavLink
              to="/user/profile"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                currentPath === "/user/profile" ? "bg-black hover:text-white text-white py-3 pl-4 rounded" : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => setcurrentPath("/user/profile")}
            >
              <ProfileIcon className={`mr-2  ${currentPath === "/user/profile" && "svg-stroke-white"}`} />
              <span> PROFILE </span>
            </NavLink>
          </div>

          <div className="pt-10 px-5 lg:px-10 border-t border-gray-200 flex flex-col gap-6">
            <NavLink
              to="/user/contact-support"
              className={`flex gap-3 pr-4 whitespace-nowrap  ${
                currentPath === "/user/contact-support"
                  ? "bg-black hover:text-white text-white py-3 pl-4 rounded"
                  : "text-black hover:text-black py-3 ml-4"
              }`}
              onClick={() => setcurrentPath("/user/contact-support")}
            >
              <ContactSupportIcon className="mr-2" />
              <span> CONTACT SUPPORT </span>
            </NavLink>
            <Link to="/" onClick={handleLogout} className="flex gap-3 pl-4 whitespace-nowrap">
              <LogoutIcon className="mr-2" />
              <span>LOGOUT </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { logout,setSearchValue })(WebUserAuthSideNav);
