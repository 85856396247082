import axios from "axios";
import { BASE_URL, requestHeaders } from "../../constants/config";
import * as actionTypes from "./types";

export const createServiceUser = (userData) => {
  return async (dispatch) => {
    try {
      const registeredUser = await axios.post(`${BASE_URL}users/register`, userData, requestHeaders);
      if (registeredUser.status === 201 || 200) {
        dispatch(saveRegisteredUserIDToState(registeredUser.data));
        // console.log("registeredUser", registeredUser);
      }
    } catch (error) {
      // console.log("Registration error", { error });
      dispatch(registrationError(error.response.data));
    }
  };
};

export const verifyPhoneNumber = (userData) => {
  return async (dispatch) => {
    try {
      const verifiedPhone = await axios.post(`${BASE_URL}users/verifyphone`, userData, requestHeaders);

      if (verifiedPhone.status === 201 || 200) {
        dispatch(saveRegisteredUserIDToState(verifiedPhone.data));
        dispatch(phoneVerified());
        // console.log("verifiedPhone", verifiedPhone);
      }
    } catch (error) {
      // console.log("Registration error", error);
      dispatch(registrationError(error.response.data));
    }
  };
};

export const phoneVerified = () => {
  return {
    type: actionTypes.PHONE_VERIFIED,
  };
};

export const setPassword = (userData) => {
  return async (dispatch) => {
    try {
      const passwordSetter = await axios.post(`${BASE_URL}users/setpassword`, userData, requestHeaders);

      if (passwordSetter.status === 201 || 200) {
        // console.log("passwordSetter", passwordSetter);
        dispatch(saveRegisteredUserIDToState(passwordSetter.data));
      }
    } catch (error) {
      // console.log("Registration error", error);
      dispatch(registrationError(error.response.data));
    }
  };
};

export const updateUserData = (userData) => {
  return async (dispatch) => {
    try {
      const userUpdater = await axios.post(`${BASE_URL}users/update`, userData, requestHeaders);

      if (userUpdater.status === 201 || 200) {
        dispatch(saveRegisteredUserIDToState(userUpdater.data));
        // console.log("userUpdater", userUpdater);
      }
    } catch (error) {
      // console.log("Registration error", error);
      dispatch(registrationError(error.response.data));
    }
  };
};

export const checkIfUserExist = (userData) => {
  return async (dispatch) => {
    try {
      const checkIfUserData = await axios.post(`${BASE_URL}users/check-exist`, userData, requestHeaders);

      if (checkIfUserData.status === 201 || 200) {
        dispatch(saveCheckIfUserExistDataToState(checkIfUserData.data, userData));
        // console.log("checkIfUserData", checkIfUserData);
      }
    } catch (error) {
      // console.log("checkIfUserExist error", error);
      dispatch(checkIfUserExistError(error?.response?.data, userData));
    }
  };
};
export const saveCheckIfUserExistDataToState = (registeredUserData, userData) => {
  return {
    type: actionTypes.SAVE_CHECK_IF_USER_EXIST_DATA_TO_STATE,
    payload: { registeredUserData: registeredUserData, originalData: userData },
  };
};
export const checkIfUserExistError = (err,userData) => {
  return {
    type: actionTypes.CHECK_IF_USER_EXIST_ERROR,
    payload: {
      error: err,
      originalData: userData,
    },
  };
};

export const resetCheckIfUserExist = () => {
  return {
    type: actionTypes.RESET_CHECK_IF_USER_EXIST,
  };
};

export const reviewProvider = (reviewedProviderData) => {
  return async (dispatch) => {
    try {
      const referredProvider = await axios.post(`${BASE_URL}feedbacks/review-provider`, reviewedProviderData, requestHeaders);

      if (referredProvider.status === 201 || 200) {
        dispatch(saveReviewedProviderDataToState(referredProvider.data));
        // console.log("referredProvider", referredProvider);
      }
    } catch (error) {
      // console.log("referredProvider error", error);
      dispatch(registrationError(error.response.data));
    }
  };
};

export const saveReviewedProviderDataToState = (reviewedProviderData) => {
  return {
    type: actionTypes.SAVE_REVIEWED_PROVIDER_DATA,
    payload: reviewedProviderData,
  };
};

export const saveRegisteredUserTokenToState = (registeredUserData) => {
  return {
    type: actionTypes.SAVE_REGISTERED_USER_TOKEN,
    payload: registeredUserData,
  };
};
export const saveRegisteredUserIDToState = (registeredUserData) => {
  return {
    type: actionTypes.SAVE_REGISTERED_USER_ID,
    payload: registeredUserData,
  };
};
export const saveRegisteredUserDataToState = (registeredUserData) => {
  return {
    type: actionTypes.SAVE_REGISTERED_USER_DATA,
    payload: registeredUserData,
  };
};
export const registrationError = (err) => {
  return {
    type: actionTypes.REGISTRATION_ERROR,
    payload: err,
  };
};
export const clearRegisterRelatedErrors = () => {
  return {
    type: actionTypes.CLEAR_REGISTER_RELATED_ERROR,
  };
};

export const setRegistrationAction = (value) => {
  return {
    type: actionTypes.SET_REGISTRATION_ACTION,
    payload: value,
  };
};
