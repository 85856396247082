import { TextField } from "@material-ui/core";
import React from "react";
import BodyLayout from "../Layout/BodyLayout";

export default function SignupProfession(props) {
  const handleJobChange = (event) => {
    props.otherInformation.profession = event.target.value;
    props.setIsFormFilled(true);
  };
  if (props.otherInformation.profession) {
    props.setIsFormFilled(true);
  } else {
    props.setIsFormFilled(false);
  }

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Profession</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Tell us what you do.
      </p>
      <br />

      <div className="flex flex-col gap-5 max-w-md pb-4">
        <form className="" validate="true" autoComplete="on">
          <div>
            <p className="font-medium text-gray-500 mb-3">Job Title</p>
            <TextField
              id="fname-field"
              onChange={handleJobChange}
              variant="outlined"
              name="job"
              autoComplete="job"
              className="w-full"
              defaultValue={props.otherInformation.profession}
            />
          </div>
        </form>
      </div>
      <br />
    </BodyLayout>
  );
}
