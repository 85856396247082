import { Button } from "@material-ui/core";
import React from "react";
import ProviderImage from "../../assets/icons/ProviderImage.svg";
import { ReadOnlyRating } from "../Rating/Rating";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { browseServiceProviderByID } from "../../redux/actions/browseServiceProvider";

function ResultCategoryCard({ browseServiceProviderByID, ...props }) {
  const { width } = useWindowDimensions();
  const { providerData } = props?.browseProviderReducer;
  const token = props.loginReducer.user.token;
  const { isAuthenticated } = props.loginReducer;


  if (providerData.filter(data=> data.reviewCount>=0.5)?.length !== 0) {
    return providerData.filter(data=> data.reviewCount>=0.5).map((provider, index) => (
      <div className="bg-white sm:bg-transparent max-w-xl mx-auto mb-4 sm:w-max pb-3 sm:pb-0 shadow-sm sm:shadow-none rounded" key={index}>
        <div className="bg-white rounded px-2 sm:px-4 py-3 sm:py-6 flex gap-4 sm:gap-6">
          <Link to={`/user/view-provider/${provider._id}`}>
            <div className="max-w-xts sm:max-w-ts max-h-ts">
              <img src={ProviderImage} alt="" className="rounded w-full" />
            </div>
          </Link>
          <div className="flex flex-col gap-1 sm:gap-3">
            <h4 className="text-base sm:text-xl font-medium truncate" style={width < 480 ? { maxWidth: "10rem" } : { maxWidth: "15rem" }}>
              {provider.profession}
            </h4>
            <Link
              to={`/user/view-provider/${provider._id}`}
              className="text-sm sm:text-base  cursor-pointer hover:text-blue-800 text-blue-800 truncate"
              style={{ color: "blue", maxWidth: "15rem" }}
              onClick={() => browseServiceProviderByID(provider._id, token)}
            >
              {provider.firstName} {provider.lastName}
            </Link>
            <i
              className="text-gray-500 text-sm  first-letter-capitalize truncate max-w-xs"
              style={width < 480 ? { maxWidth: "10rem" } : { maxWidth: "15rem" }}
            >
              {provider.area},{provider.city}, {provider.state}.
            </i>
            <div className="flex items-center gap-2">
              <ReadOnlyRating ratingCount={provider.rating} size="small" />
              <span className="text-sm">
                ({provider.reviewCount} review{provider.reviewCount > 1 ? "s" : ""})
              </span>
            </div>
            {width > 640 && (
              <div className="flex items-center gap-4 sm:mt-4">
                <div className="border-2 rounded border-black" style={{ maxHeight: "38px" }}>
                  <Button className="flex items-center gap-3" onClick={()=>props.handleCall(provider)}>
                    <span className="flex items-center gap-3 sm:pl-2 pr-2 sm:pr-4">
                      <PhoneOutlinedIcon />
                      <span className="font-medium capitalize">Call</span>
                    </span>
                  </Button>
                </div>

                {isAuthenticated ? (
                  <Link to="/user/messages">
                    <Button variant="contained" color="primary">
                      <span className="flex items-center gap-3 px-1">
                        <ChatBubbleOutlineIcon />

                        <span className="font-medium capitalize">Message</span>
                      </span>
                    </Button>
                  </Link>
                ) : (
                  <Button variant="contained" color="primary" onClick={props.handleMessage}>
                    <span className="flex items-center gap-3 px-1">
                      <ChatBubbleOutlineIcon />

                      <span className="font-medium capitalize">Message</span>
                    </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {width <= 640 && (
          <div className="flex items-center gap-4 sm:mt-4 px-3">
            <div className="border-2 rounded border-black">
              {isAuthenticated ? (
                <a href={`tel:${provider?.phone}`}>
                  <Button className="flex items-center gap-3">
                    <span className="flex items-center gap-3 sm:pl-2 pr-2 sm:pr-4">
                      <PhoneOutlinedIcon />
                      <span className="font-medium capitalize">Call</span>
                    </span>
                  </Button>
                </a>
              ) : (
                <Button className="flex items-center gap-3" onClick={()=>props.handleCall(provider)}>
                  <span className="flex items-center gap-3 sm:pl-2 pr-2 sm:pr-4">
                    <PhoneOutlinedIcon />
                    <span className="font-medium capitalize">Call</span>
                  </span>
                </Button>
              )}
            </div>
            <Button variant="contained" color="primary" onClick={props.handleMessage}>
              <span className="flex items-center gap-3 px-1">
                <ChatBubbleOutlineIcon />

                <span className="font-medium capitalize">Message</span>
              </span>
            </Button>
          </div>
        )}
      </div>
    ));
  } else {
    return (
      <div className="w-full py-3 text-center">
        <h3 className="text-black text-2xl w-full"> No Data</h3>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { browseServiceProviderByID })(ResultCategoryCard);
