import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../components/Header/Header";
import MessagingComponent from "../components/UserNProviderUtility/MessagingComponent";
import useWindowDimensions from "../Hooks/UseWindowDimension";

export default function MobileMessaging() {
  const { width } = useWindowDimensions();
  if (width < 1024) {
    return (
      <div className="flex flex-col h-screen justify-between">
        <Header />
        <MessagingComponent />
      </div>
    );
  } else {
    return <Redirect to="/user/messages" />;
  }
}
