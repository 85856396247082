import React from "react";

export default function WebUserRegistration() {
  return (
    <div className="max-w-xs hidden md:block w-full">
      <div className="bg-white h-screen w-full text-black">
        <div className="pt-20 px-10 flex flex-col gap-6">
          <div className="flex gap-3 whitespace-nowrap">
            <span className="w-6 h-6 rounded-full flex items-center justify-center text-sm border border-black mr-3">
              1
            </span>
            <span> NAME </span>
          </div>
          <div className="flex gap-3 whitespace-nowrap">
            <span className="w-6 h-6 rounded-full flex items-center justify-center text-sm border border-black mr-3">
              2
            </span>
            <span>CONTACT </span>
          </div>
          <div className="flex gap-3 whitespace-nowrap">
            <span className="w-6 h-6 rounded-full flex items-center justify-center text-sm border border-black mr-3">
              3
            </span>
            <span> LOCATION </span>
          </div>
        </div>
      </div>
    </div>
  );
}
