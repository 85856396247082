import React, { useEffect } from "react";
import BodyLayout from "../Layout/BodyLayout";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { connect } from "react-redux";
import { checkIfUserExist } from "../../redux/actions/register";

function SignupContact(props) {
  const emailExist = props.registerReducer?.emailCheck?.exist;
  const phoneExist = props.registerReducer?.phoneCheck?.exist;
  const handleEmailChange = (prop) => async (event) => {
    await props.setUserData({ ...props.userData, [prop]: event.target.value });
    props.checkIfUserExist({ email: event.target.value });
  };
  const handlePhoneChange = async (value) => {
    if (value) {
      await props.setUserData({ ...props.userData, phone: value });
      props.checkIfUserExist({ phone: value });
    }
  };

  useEffect(() => {
    if (props.userData.phone && props.userData.phone.length === 14 && emailExist === false && phoneExist === false) {
      props.setIsFormFilled(true);
    } else {
      props.setIsFormFilled(false);
    }
  }, [props, props.userData.phone, emailExist, phoneExist]);

  // const handleSubmit = () => {
  //   // your submit logic
  // };

  const { registrationAction } = props?.registerReducer;

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Contact</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">Provide your contact details.</p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4">
        <ValidatorForm
          className="flex flex-col gap-5 max-w-md pb-4"
          validate="true"
          autoComplete="on"
          ref={React.createRef("form")}
          // onSubmit={handleSubmit}
          // onError={(errors) => console.log(errors)}
        >
          <div>
            <div className="font-medium text-gray-500 mb-3">
              Email {registrationAction === "Service Provider" && <span className="text-sm">(optional)</span>}
            </div>
            <TextValidator
              id="email-field"
              type="email"
              placeholder="Email"
              onChange={handleEmailChange("email")}
              variant="outlined"
              className="w-full"
              name="email"
              autoComplete="email"
              value={props.userData.email}
              validators={["isEmail"]}
              errorMessages={["This field is required", "Email is not valid"]}
            />
          </div>
          <div>
            <p className="font-medium text-gray-500 mb-3">Phone Number</p>
            <MaterialUiPhoneNumber
              name="phone"
              data-cy="user-phone"
              defaultCountry={"ng"}
              variant="outlined"
              value={props.userData.phone}
              onChange={handlePhoneChange}
              autoComplete="phone-number"
              className="w-full"
            />
          </div>
          {props.registerReducer?.error ? (
            <p className=" text-center text-sm text-red-600 first-letter-capitalize">{props.registerReducer?.error}</p>
          ) : (
            ""
          )}
          {emailExist && <p className="text-red-500">Email already exists</p>}
          {props.userData.phone && props.userData.phone.length === 14 && phoneExist === true && (
            <p className="text-red-500">Phone number already exists</p>
          )}
        </ValidatorForm>
      </div>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { checkIfUserExist })(SignupContact);
