import React from "react";
import { Button } from "@material-ui/core";
import { ReactComponent as ReviewIcon } from "../../assets/icons/ReviewIcon.svg";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import { useHistory } from "react-router-dom";
import { ReactComponent as OneStar } from "../../assets/icons/OneStar.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icons/DocumentIcon.svg";
import { ReadOnlyRating } from "../Rating/Rating";
import { connect } from "react-redux";
import PhotoSpeaks from "../PhotoSpeaks/Index";

function Visitor(props) {
  const history = useHistory();
  // const { providerByID } = props.browseProviderReducer;
  const { singleJobRecord } = props?.jobRecordsReducer;

  const priceRating = singleJobRecord?.rating?.pricing;
  const reliabilityRating = singleJobRecord?.rating?.reliability;
  const timelinessRating = singleJobRecord?.rating?.timeliness;
  const qualityRating = singleJobRecord?.rating?.quality;

  return (
    <div className="pt-6 sm:pt-12">
      <div className="pl-3 sm:pl-6 pb-6 pt-6 pb-24">
        <div className=" pb-6 ">
          <div className="flex w-full">
            <div className="w-full max-w-2xl">
              <div className="flex items-center">
                <Button onClick={history.goBack}>
                  <KeyboardBackspaceRoundedIcon />
                </Button>

                <h3 className="text-blue-800 text-lg font-medium pl-4">
                  {singleJobRecord?.client?.firstName
                    ? singleJobRecord?.client?.firstName + " " + singleJobRecord?.client?.lastName
                    : singleJobRecord?.clientName}
                </h3>
              </div>
            </div>
          </div>

          <div className="mt-12 max-w-2xl sm:max-w-md lg:max-w-lg">
            <div className="flex items-center mt-12 gap-3">
              <DocumentIcon fontSize="medium" />
              <h3 className="text-lg font-medium">Job Description</h3>
            </div>

            <p className="mt-4 pl-10 text-gray-500">{singleJobRecord?.description}</p>
          </div>
          <hr className="max-w-2xl mt-6" />

          <div className="mt-6 max-w-2xl sm:max-w-md lg:max-w-lg">
            <div className="flex items-center gap-3">
              <OneStar style={{ filter: "brightness(0) saturate(100%)" }} />
              <h3 className="text-lg font-medium">Service Ratings</h3>
            </div>

            <div className="pt-4 ">
              <div className="flex p-2 pl-10">
                <span className="w-24">Pricing</span>
                <ReadOnlyRating ratingCount={priceRating} color="black" />
              </div>
              <div className="flex p-2 pl-10">
                <span className="w-24">Reliability</span>
                <ReadOnlyRating ratingCount={reliabilityRating} color="black" />
              </div>
              <div className="flex p-2 pl-10">
                <span className="w-24">Timeliness</span>
                <ReadOnlyRating ratingCount={timelinessRating} color="black" />
              </div>
              <div className="flex p-2 pl-10">
                <span className="w-24">Quality</span>
                <ReadOnlyRating ratingCount={qualityRating} color="black" />
              </div>
              <div className="flex p-2 pl-10">
                <span className="w-24">Overall</span>
                <ReadOnlyRating ratingCount={singleJobRecord?.overallRating} color="black" />
              </div>
            </div>
          </div>
          <hr className="max-w-2xl mt-6" />

          <div className="mt-6 max-w-2xl sm:max-w-md lg:max-w-lg">
            <div className="flex items-center gap-3">
              <ReviewIcon />
              <h3 className="text-lg font-medium">Service Review</h3>
            </div>
            <div className="pt-4 pl-10">
              <i className="text-gray-500">{singleJobRecord?.review ? singleJobRecord?.review : "No review yet"}</i>
            </div>
          </div>
          <hr className="max-w-2xl mt-6" />

          <PhotoSpeaks images={singleJobRecord?.imgUrls} />
          <hr className="max-w-2xl mt-6" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(Visitor);
