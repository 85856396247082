import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    maxWidth: "500px",
    // padding: theme.spacing(2, 4, 3),
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
}));


function LoginShowDetailsModal(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const {singleProviderView } = props?.browseProviderReducer;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`${classes.paper} flex flex-col mx-4 outline-none text-center max-w-xl`}>
          <div className="flex w-full items-center justify-between bg-black text-white rounded-t pl-2 py-3 sm:px-3">
            {props.isAuthenticated && width > 640 ? <h3>Provider Details</h3> : <h3 className="pl-3 text-base sm:text-xl">Welcome to dikooko</h3>}
            <span>
              <Button onClick={props.handleClose}>
                <CloseRoundedIcon className="text-white" />
              </Button>
            </span>
          </div>
          <div className="p-6 flex-1 max-w-lg mx-auto px-3">
            {props.isAuthenticated && width > 640 ? (
              <div className="p-6 text-lg sm:text-xl">Phone Number : {singleProviderView?.phone}</div>
            ) : (
              <>
                <h3 className="text-sm sm:text-base text-gray-500">To connect with this service provider, you need to be logged in on dikooko.</h3>
                <Link to="/login" className="flex border-2 rounded-lg border-black w-max mx-auto my-3 sm:my-6">
                  <Button onClick={props.handleCall}>
                    <span className="text-sm sm:text-base font-medium text-lg capitalize px-4 text-sm-important">Login</span>
                  </Button>
                </Link>
                <div>
                  <span className="pr-1 sm:pr-3 text-sm sm:text-base">If you do not have an account, please</span>
                  <Link to="/signup" className="font-semibold text-sm sm:text-base md:text-lg">
                    Register
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(LoginShowDetailsModal);
