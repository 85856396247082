import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: 0,
      height: "100%",
      textAlign: "center",
      boxSizing: "border-box",
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1) !important",
      borderRadius:"4px",

    },
    gridItem: {
      boxSizing: "border-box",
      padding: theme.spacing(1),
    },
    masonryItemFirst: {
      boxSizing: "border-box",
      borderRadius:"4px",
    },
  })
);

export default function CenteredGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ height: "100%" }}>
        <Grid item container xs={12} className={classes.gridItem}>
          <Grid
            item
            lg={3}
            xs={6}
            className={`${classes.masonryItemFirst} p-2`}
          >
            <Paper className={classes.paper}>{props.col1}</Paper>
          </Grid>
          <Grid
            item
            lg={3}
            xs={6}
            className={`${classes.masonryItemFirst} p-2`}
          >
            <Paper className={classes.paper}>{props.col2}</Paper>
          </Grid>

          <Grid
            item
            lg={3}
            xs={6}
            className={`${classes.masonryItemFirst} p-2`}
          >
            <Paper className={classes.paper}>{props.col3}</Paper>
          </Grid>

          <Grid
            item
            lg={3}
            xs={6}
            className={`${classes.masonryItemFirst} p-2`}
          >
            <Paper className={classes.paper}>{props.col4}</Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
