import React, { useState } from "react";
import Search from "../Search/Search";
import CategoryCardGroup from "../Category/LandingFinalCardGroup";
import { connect } from "react-redux";
import ResultCategoryCard from "../Category/ResultCategoryCard";
import CardRatingLocation from "../Rating/CardRatingLocation";

function LandingComponent(props) {
  const { isAuthenticated } = props.loginReducer;

  const [isSearching, setisSearching] = useState(false);

  return (
    <>
      <div className={`w-full max-w-screen-md-xl mx-auto pt-8 ${isAuthenticated ? "sm:pt-16 lg:pt-20" : ""} `}>
        <Search isSearching={isSearching} setisSearching={setisSearching} />

        {isSearching ? (
          <>
            <div className="text-center pt-5 sm:pt-10">
              <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center">Browse Services by Category</h1>
            </div>
            <div className="mt-5 sm:mt-10">
              <CategoryCardGroup />
            </div>
          </>
        ) : (
          <div className="flex gap-3 justify-between w-full py-3">
            <div className="w-full">
              <ResultCategoryCard handleCall={props.handleCall} handleMessage={props.handleMessage} />
            </div>

            <div className="hidden lg:block ">
              <CardRatingLocation />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(LandingComponent);
