import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function CategoryButton(props) {
  return (
    <button
      type="checkbox"
      className={`flex px-2 py-1.5 border-2 border-black rounded-full items-center text-sm sm:text-base  whitespace-nowrap
        ${
          props.clickedBtnArray.includes(props.itemName)
            ? "bg-black text-white"
            : ""
        }`}
      onClick={(event) => props.handleClick(event, props.name)}
      name={props.name}
    >
      {props.IconGrp}

      {props.itemName}
    </button>
  );
}

export function MuiCategoryButton(props) {
  return (
    <div>
      <FormControlLabel
        disabled = {props.disabled}
        control={
          <Checkbox
            checked={props.checked} 
            onChange={props.handleCheckChange}
            icon={
              <button className="flex px-2 py-1.5 border-2 border-black rounded-full items-center text-sm sm:text-base  whitespace-nowrap">
                {props.IconGrp}

                {props.itemName}
              </button>
            }
            checkedIcon={
              <button className="flex px-2 py-1.5 border-2 border-black rounded-full items-center text-sm sm:text-base  whitespace-nowrap bg-black text-white">
                {props.IconGrp}

                {props.itemName}
              </button>
            }
            name={props.name}
          />
        }
        // label="Custom icon"
      />
    </div>
  );
}
