import React, { useEffect, useState } from "react";
import { Button, Snackbar } from "@material-ui/core";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import { useHistory } from "react-router-dom";
import { ReactComponent as ReviewIcon } from "../../assets/icons/ReviewIcon.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/StarIcon.svg";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { ReactComponent as DocumentIcon } from "../../assets/icons/DocumentIcon.svg";
import PhotoSpeaks from "../PhotoSpeaks/Index";
import { connect } from "react-redux";
import { reviewJobRecord } from "../../redux/actions/jobRecords";
import { getClientJobRecord, getProviderJobRecord } from "../../redux/actions/jobRecords";

function Index({ getProviderJobRecord, getClientJobRecord, ...props }) {
  //   const classes = useStyles();
  const history = useHistory();

  const jobRecordId = props?.match?.params?.jobRecordId;
  const { singleJobRecord, addJobRecordSuccessful, reviewJobRecordSuccessful } = props?.jobRecordsReducer;
  const token = props?.loginReducer?.user?.token;
  const userType = props?.loginReducer?.user?.userType;
  const serviceUserType = props?.loginReducer?.userType;

  const id = props?.loginReducer?.user?._id;

  const [snackMessage, setsnackMessage] = useState("Reviewed Successfully");
  const [openSnack, setOpenSnack] = React.useState(false);

  const [pricing, setPricing] = useState(0);
  const [reliability, setReliability] = useState(0);
  const [timeliness, setTimeliness] = useState(0);
  const [quality, setQuality] = useState(0);
  const [review, setreview] = useState("");
  const [showError, setshowError] = useState(false);

  const handleReviewChange = (event) => {
    setreview(event.target.value);

    if (review.length < 50) {
      setshowError(true);
    } else {
      setshowError(false);
    }
  };
  const [reviewedSuccessfully, setreviewedSuccessfully] = useState(false);

  const handleSubmit = async () => {
    await props.reviewJobRecord(
      jobRecordId,
      {
        rating: {
          pricing: pricing,
          reliability: reliability,
          timeliness: timeliness,
          quality: quality,
        },
        review: review,
      },
      token
    );

    if (addJobRecordSuccessful || reviewJobRecordSuccessful) {
      await setreviewedSuccessfully(true);
      await setsnackMessage("Reviewed Successfully");
      await handleClickSnack();
      if (token) {
        await setTimeout(() => history.push(`/${serviceUserType === "Service Provider"?"provider":"user"}/notifications`), 2000);
      }
    }
  };

  useEffect(() => {
    userType === "provider" && serviceUserType === "Service Provider" ? getProviderJobRecord(id) : getClientJobRecord(id);
  }, [getProviderJobRecord, getClientJobRecord, id, serviceUserType, userType]);

  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <div className="pt-6 sm:pt-12">
      <div className="flex flex-col outline-none text-center w-full max-w-xl">
        <div className="flex w-full sm:flex-row flex-col rounded-t p-3">
          <div className="w-max">
            <Button onClick={history.goBack}>
              <KeyboardBackspaceRoundedIcon />
            </Button>
          </div>

          <div className="pl-3 text-left">
            <h3 className="text-xl font-medium">Review</h3>
            <p className="pt-1 text-sm">Give your review about the job that was done for you.</p>
          </div>
        </div>

        <div className="p-3 sm:p-6 pb-0">
          <div className="text-left">
            <div className="flex items-center gap-3">
              <DocumentIcon fontSize="medium" />
              <h3 className="text-lg font-medium">Job Description</h3>
            </div>

            <p className="mt-1 pl-10 text-gray-500">{singleJobRecord?.description}</p>
          </div>

          <PhotoSpeaks images={singleJobRecord?.imgUrls} />

          <div className="py-4">
            <div className="flex items-center gap-3">
              <StarIcon />
              <h3 className="text-lg font-medium">Job Ratings </h3>
            </div>

            <div className="flex transform scale-90">
              <div className="w-2"></div>
              <div className="flex flex-col gap-3">
                <div className="flex gap-8">
                  <span className="text-lg">Pricing</span>
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="pricing"
                      rating={pricing}
                      onChange={(event, newrating) => {
                        setPricing(newrating);
                      }}
                    />
                  </Box>
                </div>
                <div className="flex gap-8">
                  <span className="text-lg">Reliability</span>
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="reliability"
                      rating={reliability}
                      onChange={(event, newrating) => {
                        setReliability(newrating);
                      }}
                    />
                  </Box>
                </div>
                <div className="flex gap-8">
                  <span className="text-lg">Timeliness</span>
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="timeliness"
                      rating={timeliness}
                      onChange={(event, newrating) => {
                        setTimeliness(newrating);
                      }}
                    />
                  </Box>
                </div>
                <div className="flex gap-8">
                  <span className="text-lg">Quality</span>
                  <Box component="fieldset" borderColor="transparent">
                    <Rating
                      name="quality"
                      rating={quality}
                      onChange={(event, newrating) => {
                        setQuality(newrating);
                      }}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <ReviewIcon />
            <h3 className="text-lg font-medium">Service Review</h3>
          </div>
          <div className="w-full pt-4">
            <textarea
              required
              placeholder="What do you have to say about this user?"
              style={{ minHeight: "6rem" }}
              className={`${
                showError ? "border-red-500" : " focus:border-gray-900 border-gray-400"
              }  w-full p-2 sm:p-3 border focus:outline-none rounded h-12 sm:h-20 md:h-28 lg:h-32 xl:h-36 `}
              value={review}
              onChange={handleReviewChange}
            ></textarea>
            {showError && <p className="text-sm text-red-500">You have to enter more than 50 characters</p>}

            {reviewedSuccessfully && <p className="text-base text-left mt-3 text-green-500">Job Record Reviewed Successfully!</p>}

            {(!Boolean(review) || showError || !Boolean(pricing) || !Boolean(reliability) || !Boolean(timeliness) || !Boolean(quality)) && (
              <p className="text-sm text-gray-500">Make sure to add a rating</p>
            )}
          </div>
        </div>

        <div className="my-6 pb-12 w-full">
          <div className=" w-full bg-opacity-40 flex justify-end py-2 pr-8">
            <div className="flex gap-6 spec-btns">
              <Button>
                <span className="text-base py-2 px-3 capitalize font-semibold">Cancel</span>
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!Boolean(review) || showError || !Boolean(pricing) || !Boolean(reliability) || !Boolean(timeliness) || !Boolean(quality)}
                onClick={handleSubmit}
              >
                <span className="text-base py-2 px-3 capitalize font-semibold text-white">Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {reviewedSuccessfully && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={snackMessage}
          autoHideDuration={2500}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { reviewJobRecord,getClientJobRecord, getProviderJobRecord })(Index);
