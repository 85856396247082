import React from "react";

export default function RefereeBodyLayout(props) {
  return (
    <div
      className="w-full sm:px-4 pb-24 lg:px-8 xl:pr-20"
    >
      <div className="md:mt-8 sm:mt-4 bg-white sm:rounded sm:shadow-sm2 max-w-3xl mx-auto sm:shadow-ref">
        {props.children}
      </div>
    </div>
  );
}
