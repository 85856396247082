import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, Divider } from "@material-ui/core";
import MessageInput from "./MessageInput";
import { useHistory } from "react-router-dom";

const messageData = [
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
  {
    sentMessage: "Hello, I have a job that requires your service. Willing to talk?",
    receivedMessage: "Yes, ofcourse.",
    time: "Date and time",
  },
];
export default function MessagingComponent() {
  const history = useHistory();
  return (
    <div className="flex flex-col justify-between bg-white sm:bg-transparent w-full h-full">
      <div className="mt-2 sm:mx-4">
        <Button onClick={()=>history.goBack()} className="sm:hidden">
          <ArrowBackIcon />
        </Button>

        <div className="mx-4 pt-4 sm:pt-2">
          <div className="pb-3">
            <h3 className="text-lg sm:text-xl font-medium text-blue-800 pl-2">
              Service Provider’s Name
            </h3>
          </div>
          <div className=" -mx-4">
            <Divider />
          </div>

          <div className="bg-white w-full mt-6 ml-2 max-height-chat">
            {messageData.map((message, index) => (
              <div key={index}>
                <div>
                  <div className="bg-messageSent text-sm sm:text-base w-max max-w-4/5 sm:max-w-2/3 py-2 px-3 ml-auto rounded-lg sm:mr-3">
                    {message.sentMessage}
                  </div>
                  <div className="text-xs w-max ml-auto p-2 text-gray-500">Date and time</div>
                </div>
                <div>
                  <div className="bg-messageReceived text-sm sm:text-base w-max max-w-4/5 sm:max-w-2/3 py-2 px-3 mr-auto rounded-lg sm:mr-3">
                    {message.receivedMessage}
                  </div>
                  <div className="text-xs w-max mr-auto p-2 text-gray-500">Date and time</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-4 pb-8  sm:mx-4">
        <Divider />
        <div className="pt-6">
          <MessageInput />
        </div>
      </div>
    </div>
  );
}
