import React, { useState } from "react";
import ProviderImage from "../../assets/icons/ProviderImage.svg";
import { Avatar, Button } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ReactComponent as ReviewIcon } from "../../assets/icons/ReviewIcon.svg";
import { ReactComponent as WhiteStar } from "../../assets/icons/whiteStar.svg";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import { useHistory } from "react-router-dom";
import { ReactComponent as FiveStars } from "../../assets/icons/FiveStars.svg";
import { ReactComponent as FourStars } from "../../assets/icons/FourStars.svg";
import { ReactComponent as ThreeStars } from "../../assets/icons/ThreeStars.svg";
import { ReactComponent as TwoStars } from "../../assets/icons/TwoStars.svg";
import { ReactComponent as OneStar } from "../../assets/icons/OneStar.svg";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";
import { browseServiceProviderByID } from "../../redux/actions/browseServiceProvider";
import { getJobRecord,setSingleJobRecord } from "../../redux/actions/jobRecords";
import { connect } from "react-redux";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
}));

function ViewProvider({ browseServiceProviderByID, getJobRecord, ...props }) {
  const history = useHistory();
  const classes = useStyles();
  const {isAuthenticated} = props?.loginReducer;
  const { providerId } = props?.match?.params;
  const { providerByID } = props.browseProviderReducer;
  const getJobRecordsData = props?.jobRecordsReducer?.getJobRecordsData;
  const token = props.loginReducer.user.token;
  const { width } = useWindowDimensions();

  React.useEffect(() => {
    getJobRecord(providerId, token);
    browseServiceProviderByID(providerId, token);
  }, [browseServiceProviderByID, getJobRecord, providerId, token]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [feedback, setfeedback] = useState("");

  const [showError, setshowError] = useState(false);

  const handleFeedbackChange = (event) => {
    setfeedback(event.target.value);

    if (feedback.length < 50) {
      setshowError(true);
    } else {
      setshowError(false);
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   await props.feedbackProvider({
  //     userId: props.userId,
  //     providerId: props.providerId,
  //     feedback,
  //   });
  // };


  return (
    <div className="sm:pt-12 text-sm sm:text-base">
      <div className="pl-3 sm:pl-6 xl:pl-12 pb-6 pt-6 pb-24">
        <div className=" pb-6 ">
          <div className="flex flex-col lg:flex-row w-full">
            <div className="w-full max-w-2xl">
              <div className="flex items-center">
                <Button onClick={history.goBack}>
                  <KeyboardBackspaceRoundedIcon />
                </Button>

                <h3 className="text-blue-800 text-lg font-medium pl-4">{providerByID?.firstName + " " + providerByID?.lastName}</h3>
              </div>

              <div className="flex gap-3 mt-6 ">
                <div className="min-h-24 min-w-1/3">
                  <Avatar src={providerByID?.image} />
                </div>
                <div className="pl-3">
                  <h3 className="pb-1.5 text-lg font-medium">{providerByID?.profession}</h3>
                  <i className="mb-1.5 text-gray-500">{providerByID?.area + " " + providerByID?.city + " " + providerByID?.state}</i>
                  <p className="pb-2">{providerByID?.uniqueId}</p>

                  <div className="flex items-center transform scale-90 sm:scale-100 gap-4 mt-4 -ml-16 sm:ml-0">
                    <div className="border-2 rounded border-black">
                      {width > 640 ? (
                        <a href={`tel:${providerByID?.phone}`} className="flex items-center gap-3">
                          <Button>
                            <span className="flex items-center gap-3 pr-2 sm:px-0">
                              <PhoneOutlinedIcon />
                              <span className="font-medium capitalize text-sm sm:text-base">Call</span>
                            </span>
                          </Button>
                        </a>
                      ) : (
                        <a href={`tel:${providerByID?.phone}`} className="flex items-center gap-3">
                          <Button>
                            <span className="flex items-center gap-3 pr-2 sm:px-0">
                              <PhoneOutlinedIcon />
                              <span className="font-medium capitalize text-sm sm:text-base">Call</span>
                            </span>
                          </Button>
                        </a>
                      )}
                    </div>
                    {isAuthenticated ? (
                      <Link to="/user/messages">
                        <Button variant="contained" color="primary">
                          <span className="flex items-center gap-3 px-1">
                            <ChatBubbleOutlineIcon />

                            <span className="font-medium capitalize">Message</span>
                          </span>
                        </Button>
                      </Link>
                    ) : (
                      <Button variant="contained" color="primary">
                        <span className="flex items-center gap-3 px-1 sm:px-0">
                          <ChatBubbleOutlineIcon />

                          <span className="font-medium capitalize">Message</span>
                        </span>
                      </Button>
                    )}
                    <button className="border-2 border-black bg-transparent py-1 px-2.5 font-semibold rounded-full" onClick={handleOpen}>
                      <AnnouncementOutlinedIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 lg:mt-0 mx-3 xl:mr-16">
              <h3>RATINGS</h3>
              {providerByID?.ratings ? (
                <>
                  <div className="flex p-2 pl-0">
                    <FiveStars style={{ filter: "brightness(0) saturate(100%)" }} />
                    <span className="pl-3">{providerByID?.ratings?.five} </span>
                  </div>
                  <div className="flex p-2 pl-0">
                    <FourStars style={{ filter: "brightness(0) saturate(100%)" }} />
                    <span className="pl-3">{providerByID?.ratings?.four}</span>
                  </div>
                  <div className="flex p-2 pl-0">
                    <ThreeStars style={{ filter: "brightness(0) saturate(100%)" }} />
                    <span className="pl-3">{providerByID?.ratings?.three}</span>
                  </div>
                  <div className="flex p-2 pl-0">
                    <TwoStars style={{ filter: "brightness(0) saturate(100%)" }} />
                    <span className="pl-3">{providerByID?.ratings?.two}</span>
                  </div>
                  <div className="flex p-2 pl-0">
                    <OneStar style={{ filter: "brightness(0) saturate(100%)" }} />
                    <span className="pl-3">{providerByID?.ratings?.one}</span>
                  </div>
                </>
              ) : (
                <i className="text-gray-500">No rating yet</i>
              )}
            </div>
          </div>

          <div className="mt-12 max-w-2xl sm:max-w-md lg:max-w-lg">
            <div className="flex items-center mt-12 gap-3">
              <InfoOutlinedIcon />
              <h3 className="text-xl font-medium">About My Service(s)</h3>
            </div>

            <p className="mt-4 text-gray-500">{providerByID?.about ? providerByID?.about : "No about yet"}</p>
          </div>
          <hr className="max-w-2xl mt-12" />
        </div>

        <div className="mt-6 max-w-2xl pr-6 space-y-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <ReviewIcon />
              <h3 className="text-xl font-medium">Jobs and Reviews</h3>
            </div>
            <div>{getJobRecordsData?.length}</div>
          </div>
          {getJobRecordsData?.length < 1 ? (
            <div className="mt-3 text-gray-500 italic">No job records for this provider</div>
          ) : (
            getJobRecordsData?.map((data, index) => (
              <Link to="/user/provider-review" className="flex justify-between" key={index} onClick={()=>props?.setSingleJobRecord(data)}>
                <div className="flex gap-6">
                  <div className="rounded-full">
                    <img src={data?.imgUrls[0]?.url||data?.imgUrls[0]} alt=""  style={{ width:"144px",maxHeight:"96px" }} className="w-full object-cover rounded"
                    
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src={ProviderImage};
                    }}/>
                  </div>
                  <div className="flex flex-col gap-3">
                    <p className="text-blue-800  whitespace-nowrap">{data?.client?.firstName ? data?.client?.firstName + " " + data?.client?.lastName: data?.clientName}</p>

                    {data?.overallRating ? (
                      <div className="flex items-center justify-center w-max gap-1 bg-black rounded-full px-4 py-0.5 mt-3 text-white">
                        <WhiteStar />
                        <span>{data?.overallRating}</span>
                      </div>
                    ) : (
                      <i className="text-gray-500">No rating yet</i>
                    )}
                  </div>
                </div>

                <div className="flex items-center">{data?.reviewedAt ? <p className="-ml-28 mt-6 sm:m-0">{moment.utc(new Date(data?.reviewedAt)).format("MMM Do 'YY")}</p> : <p className="text-gray-500 whitespace-nowrap italic -ml-28 mt-6 sm:m-0">No date</p>}</div>
              </Link>
            ))
          )}
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} flex flex-col outline-none text-center w-full max-w-xl`}>
            <div className="flex w-full items-center justify-between bg-black text-white rounded-t p-3">
              <div className="pl-3 text-left">
                <h3 className="text-xl">Feedback</h3>
                <p className="pt-1 text-sm">Give feedback about this service provider</p>
              </div>

              <span>
                <Button onClick={handleClose}>
                  <CloseRoundedIcon className="text-white" />
                </Button>
              </span>
            </div>

            <div className="p-6">
              <div className="flex items-center gap-3">
                <ReviewIcon />
                <h3 className="text-xl font-semibold">Feedback</h3>
              </div>
              <div className="w-full pt-4">
                <textarea rows="10" required placeholder="What do you have to say about this user?" style={{ minHeight: "6rem" }} className={`${showError ? "border-red-500" : " focus:border-gray-900 border-gray-400"}  w-full p-2 sm:p-3 border focus:outline-none rounded h-12 sm:h-20 md:h-28 lg:h-32 xl:h-36`} value={feedback} onChange={handleFeedbackChange}></textarea>
                {showError && <p className="text-sm text-red-500">You have to enter more than 50 characters</p>}
              </div>
            </div>

            <div className="mt-6 w-full">
              <div className="bg-indigo-100 w-full bg-opacity-40 flex justify-end py-2 pr-8">
                <div className="flex gap-6 spec-btns">
                  <Button onClick={handleClose}>
                    <span className="text-base py-2 px-3 capitalize font-semibold">Cancel</span>
                  </Button>
                  <Button color="primary" variant="contained" type="submit" disabled={!Boolean(feedback) || showError}>
                    <span className="text-base py-2 px-3 capitalize font-semibold text-white">Submit</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { browseServiceProviderByID, getJobRecord, setSingleJobRecord })(ViewProvider);
