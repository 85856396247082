import axios from "axios";
import { BASE_URL, requestHeaders } from "../../constants/config";
import * as actionTypes from "./types";
// import { userType } from "../reducers/loginReducer";

export const browseServiceProvider = (userData, city, state, rating) => {
  let newCity = city || "";
  let newState = state || "";
  let newRating = rating || "";

  return async (dispatch) => {
    try {
      const providerData = await axios.get(
        `${BASE_URL}providers/search/?keyword=${userData}?${newCity && `city=${newCity}`}${newState && `&state=${newState}`}${newRating && `&rating=${newRating}`}`,
        requestHeaders
      );
      //   console.log(providerData.data);
      //   console.log(userType);
      if (providerData.status === 200) {
        dispatch(saveServiceProviderDataToState(providerData.data));
      }
      // console.log("providerData=", providerData.data);
    } catch (error) {
      //   console.log("login error", error);
      dispatch(serviceProviderError(error.response));
    }
  };
};

export const browseServiceProviderByID = (ID, token) => {
  return async (dispatch) => {
    try {
      const providerData = await axios.get(`${BASE_URL}providers/${ID}`, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": token,
        },
      });

      //   console.log(userType);
      if (providerData.status === 200) {
        dispatch(saveServiceProviderByIDDataToState(providerData.data));
      }
    } catch (error) {
      console.log("login error", error);
      dispatch(serviceProviderError(error.response));
    }
  };
};

export const saveServiceProviderByIDDataToState = (providerData) => {
  return {
    type: actionTypes.SAVE_PROVIDER_BY_ID_DATA,
    payload: providerData,
  };
};

export const saveServiceProviderDataToState = (providerData) => {
  return {
    type: actionTypes.SAVE_BROWSE_PROVIDER_DATA,
    payload: providerData,
  };
};

export const serviceProviderError = (error) => {
  return {
    type: actionTypes.BROWSE_PROVIDER_ERROR,
    payload: error,
  };
};

export const clearServiceProviderRelatedErrors = () => {
  return {
    type: actionTypes.CLEAR_BROWSE_PROVIDER_RELATED_ERROR,
  };
};

export const viewSingleProvider = (data) => {
  return {
    type: actionTypes.VIEW_SINGLE_PROVIDER,
    payload: data,
  };
};

export const setCategoryName=(name)=>{
  return {
    type: actionTypes.SET_CATEGORY_NAME,
    payload: name,
  };
}

export const setSearchValue = (value)=>{
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    payload: value
  }
}