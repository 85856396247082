import React from "react";
// import { ReactComponent as Triangle } from "../../assets/icons/Triangle.svg";

export default function WebSideNav(props) {
  const { title, body } = props;
  return (
      <div className="max-w-sm hidden md:block">
        <div className="bg-black h-screen w-full text-white">
          <div className="pt-20 px-10">
            <h3 className="text-3xl"> {title}</h3>
            <p className="pt-8">{body}</p>
          </div>
        </div>
      </div>
  );
}
