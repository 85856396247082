import { Button } from "@material-ui/core";
import React from "react";
import ThanksBodyLayout from "../Layout/ThanksBodyLayout";
import { Link } from "react-router-dom";
import {
  setUserType,
  clearLoginRelatedErrors,
} from "../../redux/actions/login";
import { connect } from "react-redux";

function RefereeThanks(props) {
  return (
    <ThanksBodyLayout height="h-full" shadow="shadow-none">
      <div className="max-w-md mx-auto text-center my-12">
        <h3 className="text-xl sm:text-2xl">
          Thank You For Referring Abdulamid
        </h3>
        <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2 mt-4">
          Your referral has been sent.
        </p>
        <br /> <br /> 
        <Link to="/">
          <Button color="primary" variant="contained">
            <span className="capitalize text-sm sm:text-base px-2 py-1">
              Continue to Home Page
            </span>
          </Button>
        </Link>
        <br />
        <br />
      </div>
    </ThanksBodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  setUserType,
  clearLoginRelatedErrors,
})(RefereeThanks);
