import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { getClientJobRecord, getProviderJobRecord, setSingleJobRecord } from "../../redux/actions/jobRecords";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
  button: {
    textTransform: "none",
    paddingLeft: 0,
    marginBottom: "1rem",
  },
}));

function NotificationsBody({ getProviderJobRecord, getClientJobRecord, ...props }) {
  // const history = useHistory();
  const classes = useStyles();
  const serviceUserType = props?.loginReducer?.userType;
  const jobRecordData = props?.jobRecordsReducer?.getJobRecordsData;


  const { userType, _id } = props?.loginReducer?.user;

  useEffect(() => {
    userType === "provider" && serviceUserType === "Service Provider" ? getProviderJobRecord(_id) : getClientJobRecord(_id);
  }, [getProviderJobRecord, getClientJobRecord, _id, serviceUserType, userType]);

  return (
    <div className="lg:mt-24 p-4 sm:p-6 w-full sm:ml-4">
      <div className="mb-6 notificationStyle h-full">
        {(jobRecordData || [])?.filter((data) =>
          userType === "provider" && serviceUserType === "Service Provider" ? data.reviewed === true : data.reviewed === false
        ).length < 1 ? (
          <div className="h-full w-full text-lg text-center table">No notifications yet</div>
        ) : (
          (jobRecordData || [])
            ?.filter((data) => (userType === "provider" && serviceUserType === "Service Provider" ? data.reviewed === true : data.reviewed === false))
            ?.map((data, index) => (
              <React.Fragment key={index}>
                <p className="text-xs font-medium pl-3">{moment.utc(new Date(data?.requestedAt)).format("MMM Do 'YY")}</p>

                <Link
                  to={serviceUserType === "Service User" ? `/user/review-form/${data?._id}` : "/provider/user-review/"}
                  onClick={() => props.setSingleJobRecord(data)}
                >
                  <Button className={classes.button}>
                    <span className="pl-3 py-3 pr-12 text-sm border-b border-gray-300 text-left first-letter-capitalize">
                      {((serviceUserType === "Service Provider" ? data?.client : data?.provider)?.firstName || "") +
                        " " +
                        (serviceUserType === "Service Provider" ? data?.client : data?.provider)?.lastName}{" "}
                      {serviceUserType === "Service User"
                        ? "requests for a review on a job they did for you."
                        : "provided the review you requested on a job you did."}
                    </span>
                  </Button>
                </Link>
              </React.Fragment>
            ))
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { setSingleJobRecord, getClientJobRecord, getProviderJobRecord })(NotificationsBody);
