import React, { useState } from "react";
import BiggerWhite from "../SplitScreen/BiggerWhite";
import SplitScreen from "../SplitScreen/Index";
import SmallerTransparent from "../SplitScreen/SmallerTransparent";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import EditPersonalProfile from "./EditPersonalProfile";
import EditSecurity from "./EditSecurity";
import EditWorkProfile from "./EditWorkProfile";

function ProfileBody(props) {
  const [currentTab, setcurrentTab] = useState("personalProfile");
const {userType} = props?.loginReducer;

  return (
    <SplitScreen>
      <SmallerTransparent>
        <div className="flex mt-8 sm:mt-24">
          <div className="flex flex-row lg:flex-col">
            <div>
              <Button onClick={() => setcurrentTab("personalProfile")}>
                <p
                  className={`${
                    currentTab === "personalProfile" ? "text-black " : "text-gray-500"
                  } capitalize font-body font-semibold p-2`}
                >
                  Edit Personal Profile
                </p>
              </Button>
            </div>
           { userType === "Service Provider" &&<div>
              <Button onClick={() => setcurrentTab("workProfile")}>
                <div
                  className={`${
                    currentTab === "workProfile" ? "text-black " : "text-gray-500"
                  } capitalize font-body font-semibold p-2`}
                >
                  Edit Work Profile
                </div>
              </Button>
            </div>}
            <div>
              <Button onClick={() => setcurrentTab("security")}>
                <div
                  className={`${
                    currentTab === "security" ? "text-black " : "text-gray-500"
                  } capitalize font-body font-semibold p-2`}
                >
                  Security
                </div>
              </Button>
            </div>
          </div>
        </div>
      </SmallerTransparent>
      <BiggerWhite>
        {currentTab === "personalProfile" && <EditPersonalProfile onClick={props.onClick}/>}
        {currentTab === "workProfile" && <EditWorkProfile onClick={props.onClick}/> }
        {currentTab === "security" && <EditSecurity onClick={props.onClick}/>}
      </BiggerWhite>
    </SplitScreen>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(ProfileBody);
