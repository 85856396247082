import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button } from "@material-ui/core";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import BodyLayout from "../Layout/BodyLayout";
import { connect } from "react-redux";
import { login, saveLoginUserDataToState, clearLoginRelatedErrors } from "../../redux/actions/login";

function LoginBody(props) {
  const [values, setValues] = useState({
    email: "",
    password: "",
    phoneNumber: "",
    showPassword: false,
    keepLoggedIn: false,
  });

  const [showEmailField, setshowEmailField] = useState(false);
  const [showPhoneField, setshowPhoneField] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const { error } = props.loginReducer;
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleCheckChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  const handlePhoneChange = (value) => {
    if (value) {
      setValues({ ...values, phoneNumber: value });
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailSectionClicked = () => {
    setshowEmailField(true);
    setshowPhoneField(false);
  };
  const handlePhoneSectionClicked = () => {
    setshowEmailField(false);
    setshowPhoneField(true);
  };

  const handleSubmit = async (event) => {
    setIsSpinning(true);
    event.preventDefault();
    if (showEmailField) {
      await props.login({ email: values.email, password: values.password });
    }
    if (showPhoneField) {
      await props.login({ phone: values.phoneNumber, password: values.password });
    }
    setIsSpinning(false);
  };

  useEffect(() => {
    // custom rule will have name 'isPasswordValid'
    ValidatorForm.addValidationRule("isPasswordValid", (value) => {
      if (value.length < 8) {
        return false;
      }
      return true;
    });
    return () => {
      //cleanup: remove rule when it is not needed
      ValidatorForm.removeValidationRule("isPasswordValid");
    };
  }, [values.password]);

  return (
    <BodyLayout>
      <h3 className="text-lg sm:text-2xl">{props?.loginReducer?.userType} Login</h3>
      <p className="text-sm sm:text-lg text-gray-500 pt-2">Login to keep enjoying dikooko.</p>
      <br />

      <ValidatorForm
        className="flex flex-col gap-5 max-w-md pb-4"
        validate="true"
        autoComplete="on"
        ref={React.createRef("form")}
        onSubmit={handleSubmit}
        // onError={(errors) => console.log(errors)}
      >
        <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
          <span
            onClick={handlePhoneSectionClicked}
            className={`cursor-pointer ${showPhoneField ? "underline text-black" : ""}`}
          >
            Phone Number
          </span>
          <span className="px-2"> / </span>
          <span
            onClick={handleEmailSectionClicked}
            className={`cursor-pointer ${showEmailField ? "underline text-black" : ""}`}
          >
            Email Address
          </span>
        </div>

        {showEmailField && (
          <TextValidator
            id="email-field"
            type="email"
            label="Email"
            onChange={handleChange("email")}
            variant="outlined"
            className="w-full"
            name="email"
            autoComplete="email"
            value={values.email}
            validators={["required", "isEmail"]}
            errorMessages={["This field is required", "Email is not valid"]}
          />
        )}

        {showPhoneField && (
          <MaterialUiPhoneNumber
            name="phone"
            label="Phone Number"
            data-cy="user-phone"
            defaultCountry={"ng"}
            variant="outlined"
            value={values.phoneNumber}
            onChange={handlePhoneChange}
            autoComplete="phone-number"
          />
        )}

        <TextValidator
          id="outlined-adornment-password"
          variant="outlined"
          className="w-full"
          label="Password"
          onChange={handleChange("password")}
          name="password"
          autoComplete="password"
          type={values.showPassword ? "text" : "password"}
          validators={["required", "isPasswordValid"]}
          errorMessages={["This field is required", "Password is less than 8 characters"]}
          value={values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error?.name && <p className="text-red-500 text-sm">{error?.name || "Error"} </p>}

        <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between sm:items-center">
          <FormControlLabel
            className="text-gray-500 text-sm"
            control={
              <Checkbox
                checked={values.keepLoggedIn}
                onChange={handleCheckChange}
                name="keepLoggedIn"
                color="primary"
              />
            }
            label="Keep me logged in."
          />
          <Link to="/forgot-password">
            <p className="text-sm ">Forgot Password?</p>
          </Link>
        </div>

        <Button
          type="submit"
          disabled={(values.email && values.password) || (values.phoneNumber && values.password) ? false : true}
          className="shadow flex items-center"
          style={
            (values.email && values.password) || (values.phoneNumber && values.password)
              ? { backgroundColor: "black", color: "white" }
              : { backgroundColor: "grey", color: "white" }
          }
        >
          {((values.email && values.password) || (values.phoneNumber && values.password)) && isSpinning && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          <span className="py-1">Login</span>
        </Button>
      </ValidatorForm>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  login,
  saveLoginUserDataToState,
  clearLoginRelatedErrors,
})(LoginBody);
