import React from "react";
import Header from "../../components/Header/Header";
// import Footer from "../../components/Footer/Footer";
// import WebUserAuthSideNav from "../../components/SideNav/WebUserAuthSideNav";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Footer from "./Footer";

function TermsNconditions(props) {
  const { width } = useWindowDimensions();
  const { isAuthenticated, user, userType } = props.loginReducer;

  return (
    <>
      {isAuthenticated && user.userType === "user" && <Redirect to="/user" />}
      {isAuthenticated && user.userType === "provider" && userType === "Service Provider" && (
        <Redirect to="/provider/job-records" />
      )}
      {!isAuthenticated || width < 640 ? (
        <Header
          showRegisterButton={true}
          showLoginButton={width > 280 ? true : false}
          headingText="Privacy Policy"
        />
      ) : (
        ""
      )}

      <div className="gap-3 text-sm sm:text-base w-full  px-6 pb-24 md:px-0 md:h-full bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <div className="w-full max-w-screen-md-xl mx-auto pt-8  ">
          <h3 className="sm:hidden text-xl font-medium text-center">Privacy Policy</h3>
          <br />
          <div> Last Updated: September 30, 2021</div>
          <br />
          <div className="my-3">
            <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">SECTION 1 - Introduction </div>
            <div>
              This Global Privacy Policy (“Privacy Policy”) describes how Dikooko, and its
              affiliates will gather, use, and maintain your Personal Information on the Dikooko
              Platform. It will also explain your legal rights with respect to that information. By
              using the Dikooko Platform, you confirm that you have read and understand this Privacy
              Policy, and our Global Terms of Service (together referred to herein as the
              “Agreement”). The Agreement governs the use of the Dikooko Platform. Dikooko will
              collect, use, and maintain information consistent with the Agreement. If you have any
              questions or wish to exercise your rights and choices, please contact us as set out in
              the “Contact Us” section below.
            </div>
          </div>
          <br />
          <div className="my-3">
            <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 2 - General Terms In this Privacy Policy
            </div>

            <ol className="list-disc list-inside flex flex-col gap-2 mt-3">
              <li>Dikooko may be referred to as "Dikooko," “we,” “our,” or “us.”</li>
              <li>
                We call a user of the Dikooko Platform “Service User”, “User,” “Users,” “you,”
                “your,” or “Client” as appropriate.
              </li>
              <li>
                The community platform that we offer at our website www.dikooko.com is referred to
                as the “Site”.
              </li>
              <li>
                The mobile applications (whether on iOS or Android) are referred to as the “Apps.”
              </li>
              <li>
                Dikooko Sites, Apps and related services, information and communications are
                collectively referred to as the “Dikooko Platform.”
              </li>
              <li>
                “Terms of Service” refers to our Global Terms of Service, which can be found here.
                This Privacy Policy is incorporated into, and considered a part of, the Terms of
                Service.
              </li>
            </ol>
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 3 - Information Collection 
            </div>
            Information you provide to Dikooko Dikooko collects certain
            personally identifiable information about you, including information that can identify,
            relate to, describe, be associated with you, or is reasonably capable of being
            associated with you (“Personal Information”). Examples of Personal Information that
            Dikooko collects include but are not limited to: Contact Information. This may include
            your first and last name, Area address, telephone number, and email address. Promotional
            Information. Certain offerings of the Dikooko Platform, such as newsletters, surveys,
            contests, and the like are optional and so you are not required to enter them or to give
            us your data in connection with them. Where you do consent to take advantage of Dikooko
            Platform offerings, we will use your data to (as applicable) send you newsletters and
            other communications that are tailored based on information we have about you, or to
            operate and manage the survey, contest or similar offering in connection with our
            legitimate interest in promoting our business and the Dikooko Platform. To opt-out of
            receiving marketing communications from us, please see Section 7, Your Rights and
            Choices. Content Information. You also may choose to send Dikooko Personal Information
            in an email or chat message containing inquiries about the Dikooko Platform and we use
            this Information in order to help us respond to your inquiry. We also collect content
            within any messages you exchange with other Users through the Dikooko Platform (such as
            through our chat functionality). We require that you furnish certain information when
            you register an account with us in order to provide services through the Dikooko
            Platform. For example, if you are a Service Provider, we collect your first and last
            name, email address, and your Area Address in order to create and administer your
            Dikooko account. If you are a Service User, we collect your first and last name, email
            address, mobile phone number and Area Address in order to create and administer your
            Dikooko account and facilitate communications between you and your Service Providers
            through the Dikooko Platform.
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 4 - Information Dikooko Collects
            </div>
             Automatically We automatically collect certain
            information when you use the Dikooko Platform. The categories of information we
            automatically collect and have collected, including in the last 12 months, are as
            follows: Service Use Data, including data about features you use, pages you visit,
            emails and advertisements you view, portions of the DikookoPlatform that you view and
            interact with, the time of day you browse, and your referring and exiting pages. Device
            Data, including data about the type of device or browser you use, your device’s
            operating system, your internet service provider, your device’s regional and language
            settings, and device identifiers such as IP address and Ad Id. When you visit and
            interact with the DikookoPlatform, Dikookomay collect certain information automatically
            through cookies or other technologies, including, but not limited to, the type of
            computer or mobile device you use, your mobile device’s unique device ID, the IP address
            of your computer or mobile device, your operating system, the type(s) of internet
            browser(s) you use, and information about the way you use the DikookoPlatform (“Device
            Information”). We may use Device Information to monitor the geographic regions from
            which Users navigate the DikookoPlatform, and for security and fraud prevention
            purposes. Location Data, including imprecise location data (such as location derived
            from an IP address or data that indicates a city or postal code level), and, with your
            consent, precise location data (such as latitude/longitude data). When you visit the
            DikookoPlatform via a native mobile application, we use, with your consent when required
            under applicable law, GPS technology (or other similar technology) to determine your
            current location in order to determine the city you are located in and display a
            relevant location map. We will not share your current location obtained in this manner
            with other Users. Cookies and similar technologies are described in our Cookie Policy,
            which sets out the different categories of cookies and similar technologies that the
            DikookoPlatform uses and why we use them.
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 5 - Dikooko's Use of Information
            </div>
             We collect and use information for business and
            commercial purposes in accordance with the practices described in this Privacy Policy.
            Our business purposes for collecting and using information include:
            <ol className="list-disc list-inside flex flex-col gap-2 mt-3">
              <li>
                To operate and make available the DikookoPlatform. We use your data to connect you
                with other Users to enable the posting of, selection for, completion of, and payment
                for services, in order to fulfill our contracts with Users;
              </li>
              <li>
                For billing and fraud prevention, on the basis of our legitimate interests in
                ensuring a safe and secure environment in which ServiceUsers and ServiceProviders
                can meet and conduct business, and in order to comply with our legal obligations;
              </li>
              <li>
                To conduct identification and criminal background checks, if applicable and to the
                extent permitted by local law, on Users, in order to advance our legitimate
                interests as well as for the substantial public interest of ensuring the safety of
                our Users both online and offline, preventing or detecting unlawful acts, protecting
                the public against dishonesty, and maintaining the integrity of the DikookoPlatform
                given that ServiceUsers often interact directly with ServiceProviders and may enter
                their homes;
              </li>
              <li>
                To analyze DikookoPlatform usage as necessary for our legitimate interest in
                improving the DikookoPlatform to grow our business;
              </li>
              <li>
                To contact you and deliver (via email, SMS, push notifications, or otherwise)
                transactional information, administrative notices, marketing notifications, offers
                and communications relevant to your use of the DikookoPlatform, with your consent
                when required under applicable law, as necessary for our legitimate interests in
                proper communication and engagement with our Users, and in promoting our business;
              </li>
              <li>
                To provide you with customer support in order to fulfill our contracts with Users;
              </li>
              <li>
                For internal market research for our legitimate interest in improving the
                DikookoPlatform to grow our business;
              </li>
              <li>
                For troubleshooting problems for our legitimate interests in ensuring a safe and
                secure environment in which Users can meet;
              </li>
              <li>
                To assist Users in the resolution of complaints and disputes between them, as
                necessary for our legitimate interests in facilitating good relations among Users;
              </li>
              <li>
                To enforce our Terms of Service and our legitimate interests in ensuring our
                Agreement is complied with; and
              </li>
              <li>
                As otherwise set forth in the Agreement. Interest-Based Advertising. Ads are a
                standard part of user experience on the Internet, and Dikooko believes that targeted
                advertising enhances this experience. Dikooko and affiliate third parties may use
                cookies and other technologies to place ads where they believe interested Users will
                see them. In addition to banner ads, Dikooko may advertise products, companies and
                events that we think might interest you through the email address and/or phone
                number you provide. For more information and to understand your choices regarding
                third-party ads, please see our Cookie Policy. Advertising and marketing is carried
                out as necessary for our legitimate interests in providing an engaging and relevant
                experience, promoting our services, and growing our business. Analytics and Market
                Analysis. Dikooko may analyze information (“Market Analysis”) as necessary for our
                legitimate interests in providing an engaging and relevant experience, and promoting
                and growing the Dikooko Platform. Dikooko uses information to offer services to
                Users who express an interest in these services, through a poll for example, or to
                Users who can be presumed to have an interest based on results from our Market
                Analysis. We do this as necessary for our legitimate interests in providing an
                engaging and relevant experience, promoting our services, and growing our business.
                Cell Phone Numbers. Dikooko may use your cell phone number to call or send recurring
                text messages to you, using an autodialer or prerecorded voice, in order to provide
                you notifications about Service Requests, for marketing purposes (with your consent
                where required by law), and to administer the Dikooko Platform. If you would like
                more information about our policy, or how to opt out, please review the “Your Rights
                and Choices” section below or Section 9 of our Terms of Service. You may be liable
                for standard SMS and per-minute charges by your mobile carrier. Dikooko may also
                message you via push notifications (with your consent when required under applicable
                law), which you can opt-out of on your mobile device. Data rates may apply.
              </li>
            </ol>
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 6 - Information Sharing 
            </div>
            We only share the Information we collect about you as described
            in this Privacy Policy or as described at the time of collection or sharing, including
            as follows: Third Party Agents. We share information, including Identity Information,
            with entities that process information on our behalf for our business purposes. We
            contractually prohibit our Third-Party Agents from retaining, using, or disclosing
            information about you for any purposes other than performing the services for us,
            although we may permit them to use information that does not identify you (including
            information that has been aggregated or de-identified) for any purpose except as
            prohibited by applicable law. To operate the Dikooko Platform, including processing your
            transactions, we may share your Personal Information with our agents, representatives,
            vendors and service providers (“Third Party Agents”) so they can provide us with support
            services as follows:
            <ol className="list-disc list-inside flex flex-col gap-2 mt-3">
            <li>Email origination;</li>
            <li> Fraud prevention and detection </li>
            <li> Support services; </li>
            <li> Customer relationship management services;</li>
            <li> Data analytics; </li>
            <li> Marketing and advertising;</li>
            <li> Website hosting; </li>
            <li> Communications services; </li>
            <li>Technical support;</li>
            <li>To otherwise help us provide the DikookoPlatform. Other Users. Dikooko facilitates
              contact between ServiceProviders and ServiceUsers and reserves the right to share one
              User’s contact information (e.g., name, phone number, email, or Area address) with
              other Users, or with the recipient User’s legal or other authorized representative, in
              order to: 
              <div>
              (1) facilitate or communicate the resolution of an investigation or dispute
              related to or arising from an interaction between two or more Users of the
              DikookoPlatform; or
                </div> 
                <div>
                (2) facilitate the performance of a service. This exchange of
              information is essential to the operation of the DikookoPlatform. Legal Obligations.
              Dikooko and our Third Party Agents may disclose your Personal Information or User
              Generated Content to courts, law enforcement, governmental or public authorities, tax
              authorities or authorised third parties, if and to the extent required or permitted to
              do so by law or where disclosure is reasonably necessary to: 
              <div>(i) comply with Dikooko’s
              legal or regulatory obligations;</div>
              <div>(ii) respond to a court order, warrant or subpoena;</div>
              <div>(iii) respond to a valid legal request relating to an investigation into alleged
              criminal or illegal activity; or </div>  
              <div>
              (iv) respond to or address any other activity that
              may expose us to legal or regulatory liability. Dikooko reserves the right to disclose
              Personal Information from both private and public areas of the DikookoPlatform in the
              absence of a court order, warrant, or subpoena, to the extent permitted by applicable
              law, if we are given reason to believe, in our sole discretion, that someone is
              causing injury to or interfering with the rights of Users, the general public, or
              Dikooko or its partners, parents or affiliates. It is our policy to provide notice to
              Users before producing their information in response to law enforcement requests
              unless (i) we are prohibited or otherwise constrained by law or court order from doing
              so, (ii) we have reason to believe the User’s account has been compromised such that
              the notice would go to the wrong person, or notice would otherwise be
              counterproductive or would create a risk to safety, or (iii) it is an emergency
              request and prior notice would be impractical (in which case we may provide notice
              after the fact). Merger or Acquisition. Dikooko reserves the right to share
              information in connection with, or during negotiations of, any proposed or actual
              merger, purchase, sale, or any other type of acquisition or business combination of
              all or any portion of our assets, or transfer of all or a portion of our business to
              another business. Public Areas. Your profile on the Dikooko Platform consists of
              information about you and your business, and may include information such as
              photographs, your years in business, skills and expertise, feedback/rating
              information, and other information, including your username (“Profile”). The
              information in your User Profile may be visible to all Users and the general public.
              If you choose to post a JobOrder via the DikookoPlatform, the contents of such listing
              will be viewable to ServiceUsers on the DikookoPlatform.
              </div>
             
                  </div>
            </li>
            </ol>
          
         
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION  7 - Your Rights and Choices 
            </div>
           You may opt-out of receiving promotional communications from
            us, including promotional communications related to the DikookoPlatform, and request the
            removal of your Personal Information from our databases, or deactivate your account, by
            logging on to the Site or App and clicking on the “Account” tab or by contacting us at
            admin@dikooko.com Account Settings. During registration you choose whether to receive
            marketing correspondence from Dikooko. This information remains on your Profile where
            you can, at any point, easily edit it. After logging on, click on the “Account” tab,
            then select “Notifications” to make your preferred selections. Push Notifications. You
            have the option to receive updates and relevant offers via push notifications in your
            app. These notifications can be configured in the settings of your mobile device at any
            time. Corrections to Profile. You have the right to access, update, and correct
            inaccuracies in your DikookoProfile at any time by logging in and clicking on the
            “Account” tab. There, you can view, update, and correct your Account information. So
            that we may protect the integrity of the DikookoPlatform, there are certain pieces of
            information, such as your age, that you cannot alter yourself Promotional
            Communications. You can opt out of receiving promotional communications from Dikooko via
            the Account tab in your Profile. In addition, you can opt out of email marketing by
            clicking on the unsubscribe link in any message. You can opt out of promotional
            communications from Dikooko sent via text message at any time by following the
            instructions provided in those messages to text the word "STOP". Please note that your
            opt-out is limited to the phone number used and will not affect subsequent
            subscriptions. If you opt-out of only certain communications, other subscription
            communications may continue. Even if you opt-out of receiving promotional
            communications, Dikooko will continue to send you non-promotional communications, such
            as those about your account, transactions, servicing, or Dikooko’s ongoing business
            relationship with you. If you receive unsolicited promotional communications from a
            Dikooko domain, please let us know
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 8 - Dikooko's Information Retention Policy
            </div>
             We retain personal data for as long as you are
            a User in order to meet our contractual obligations to you, and for such longer period
            as may be in our legitimate interests and to comply with our legal obligations. We may
            also retain data from which you cannot directly be identified, for example where stored
            against a randomly-generated identifier so we know the data relates to a single User,
            but we cannot tell who that User is. We use this kind of data for research purposes and
            to help us develop and improve our services, and we take appropriate measures to ensure
            you cannot be re-identified from this data.
          </div>
          <br />
          <div className="my-3">
          <div className="text-primary text-lg sm:text-xl capitalize mb-3 ">
              SECTION 9 - Dikooko’s Security of Collected Information 
            </div>
             Your Dikooko account is
            password-protected so that only you and authorized Dikooko staff have access to your
            account information. In order to maintain this protection, do not give your password to
            anyone. Also, if you share a computer, you should sign out of your Dikooko account and
            close the browser window before someone else logs on. This
          </div>
        </div>
      </div>
    
    
    <Footer/>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(TermsNconditions);
