import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { connect } from "react-redux";
import WebUserAuthSideNav from "../../components/SideNav/WebUserAuthSideNav";
import CategoryPageComponent from "../../components/Category/CategoryPageComponent";
import LoginShowDetailsModal from "../../components/Modals/LoginShowDetailsModal";
import {viewSingleProvider} from "../../redux/actions/browseServiceProvider";
import { useHistory } from "react-router-dom";

function CategoryPage(props) {
  const { categoryName } = props?.match?.params;
  const { width } = useWindowDimensions();
  const history = useHistory();

  const { isAuthenticated } = props.loginReducer;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = (data) => {
    setOpen(true);
    props.viewSingleProvider(data);
  };

  const handleMessage = () => {
    if (isAuthenticated === false) {
      setOpen(true);
    } else {
      history.push("/messaging");
    }
  };

  return (
    <>
      {(!isAuthenticated || width < 640) && <Header showRegisterButton={true} showLoginButton={width > 280 ? true : false} />}
      <div className="flex w-full px-2 sm:px-4 sm:pb-24 md:px-0 min-h-screen bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        {isAuthenticated && <WebUserAuthSideNav />}

        {isAuthenticated && <div className="hidden sm:block w-1/2 md:w-1/3 xl:w-1/4"></div>}

        <CategoryPageComponent open={open} setOpen={setOpen} categoryName={categoryName} handleMessage={handleMessage} handleCall={handleCall} />
      </div>
      <Footer />

      <LoginShowDetailsModal isAuthenticated={isAuthenticated} open={open} handleClose={handleClose} handleCall={handleCall} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {viewSingleProvider})(CategoryPage);
