import React, { useState, useEffect } from "react";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import useWindowDimension from "../../Hooks/UseWindowDimension";
import {browseServiceProvider} from "../../redux/actions/browseServiceProvider";
import { connect } from "react-redux";
import {setSearchValue} from "../../redux/actions/browseServiceProvider";

function Search({setisSearching, browseServiceProvider, ...props}) {
  const [searchValue, setsearchValue] = useState("");
  // const {token} = props?.loginReducer
  const { width } = useWindowDimension();
  const categoryName = props?.browseProviderReducer?.categoryName
  const handleSearch = (event) => {
    setsearchValue(event.target.value);
    props.setSearchValue(event.target.value);
  };
 const reduxSearchValue = props?.browseProviderReducer?.searchValue;



  useEffect(() => {
    if (searchValue) {
        browseServiceProvider(searchValue)
        setisSearching(false);
        
      if(searchValue?.length===1){
        browseServiceProvider(categoryName);
      }
     
    } else {
      browseServiceProvider(categoryName);
      setisSearching(true);
     
    }
    if(!reduxSearchValue){
      setisSearching(true);
      setsearchValue("");
     } 
   
  }, [searchValue,categoryName,reduxSearchValue, browseServiceProvider, setisSearching]);

  return (
    <div className="sm:pr-3">
      <div className="relative flex items-center sm:mx-3">
        <input
          type="text"
          placeholder={
            width > 640 ? `Search for Professional Services` : `Search`
          }
          value={reduxSearchValue}
          onChange={handleSearch}
          className="text-center centeredPlaceholder transition duration-500 ease-in-out w-full p-2 sm:py-3 sm:pl-12 sm:pr-4 border rounded border-gray-500 focus:outline-none focus:border-gray-900 focus:shadow-lg"
        />
        <SearchRoundedIcon className="absolute ml-4 gray-700-important" />
      </div>

      {searchValue ? (
        <div className="text-lg sm:text-xl md:text-2xl text-center font-medium max-w-3xl break-all mx-auto py-5">
          <span className="pr-1 sm:pr-3 text-gray-500">Showing results for </span>
          <span>"{searchValue}"</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  browseServiceProvider,
  setSearchValue
})(Search);
