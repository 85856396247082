import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LandingPage from "./pages/Related/LandingPage";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { store, persistor } from "./redux/store/store";
import SignupUserStepper from "./pages/SignupUserStepper";
import SignupProviderStepper from "./pages/SignupProviderStepper";
import Referee from "./pages/Referee";
import RefereeThanks from "./components/Auth/RefereeThanks";
import ForgotPassword from "./pages/ForgotPassword";
import RecoveryPage from "./pages/RecoveryPage";
import CreateNewPasswordPage from "./pages/CreateNewPasswordPage";
import PasswordResetSuccessPage from "./pages/PasswordResetSuccessPage";
import ServiceProviderPage from "./pages/ServiceProviderPage";
import CategoryPage from "./pages/Related/CategoryPage";
import ServiceUserPage from "./pages/Related/ServiceUserPage";
import TermsNconditions from "./components/Footer/TermsNconditions";
import Unavailable from "./pages/Unavailable";
import MobileMessaging from "./pages/MobileMessaging";
import ContactSupportPage from "./pages/ContactSupportPage";
// import ProtectedRoute from "./routes/ProtectedRoute";



const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
    },
    inactiveBlue: {
      main: "#BFBFD9",
    },
  },
});

export default function App() {

    return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/login" component={Login} />
              <Route exact path="/referee/:userId/:providerId/" component={Referee} />
              <Route path="/signup" component={SignUp} />
              <Route path="/signup-user" component={SignupUserStepper} />
              <Route path="/signup-provider" component={SignupProviderStepper} />
              <Route path="/review-thanks" component={RefereeThanks} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/recover-password" component={RecoveryPage} />
              <Route path="/create-new-password" component={CreateNewPasswordPage} />
              <Route path="/reset-success" component={PasswordResetSuccessPage} />
              <Route path="/provider" component={ServiceProviderPage} />
              <Route path="/user" component={ServiceUserPage} />
              <Route path="/contact" component={ContactSupportPage} />
              <Route path="/messaging" component={MobileMessaging} />
              <Route path="/termsNconditions" component={TermsNconditions} />
              <Route exact path="/category/:categoryName" component={CategoryPage} />
              <Route path="*" component={Unavailable} />

            </Switch>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}
