import axios from "axios";
import { BASE_URL } from "../../constants/config";
import * as actionTypes from "./types";
// import { userType } from "../reducers/loginReducer";

export const updateProfile = (userData, token) => {
  return async (dispatch) => {
    try {
      const updateUserData = await axios.put(`${BASE_URL}users/update-user-details`, userData, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (updateUserData.status === 200) {
        //   console.log(updateUserData);
        //   console.log("updateUserData=", updateUserData.getAllResponseHeaders());
        dispatch(saveUpdateProfileDataToState(updateUserData.data));
      }
    } catch (error) {
        //   console.log("login error", error);
        dispatch(updateProfileError(error.response));
    }
  };
};

export const updateProfileError = (error) => {
  return {
    type: actionTypes.UPDATE_PROFILE_ERROR,
    payload: error,
  };
};

export const saveUpdateProfileDataToState = (profileData) => {
  return {
    type: actionTypes.SAVE_UPDATE_PROFILE_DATA,
    payload: profileData,
  };
};

export const getLoggedInUserDetails = (token) => {
  return async (dispatch) => {
    try {
      const userDetailsData = await axios.get(`${BASE_URL}users/`, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      });
      if (userDetailsData.status === 200) {
        //   console.log(userDetailsData);
        //   console.log("userDetailsData=", userDetailsData.getAllResponseHeaders());
        dispatch(saveLoggedInUserDetailsDataToState(userDetailsData.data));
      }
    } catch (error) {
        //   console.log("login error", error);
        dispatch(loggedInUserDetailsError(error.response));
    }
  };
};

export const loggedInUserDetailsError = (error) => {
  return {
    type: actionTypes.LOGGED_IN_USER_DETAILS_ERROR,
    payload: error,
  };
};

export const saveLoggedInUserDetailsDataToState = (userDetailsData) => {
  return {
    type: actionTypes.SAVE_GET_LOGGED_IN_USER_DETAILS_DATA,
    payload: userDetailsData,
  };
};
