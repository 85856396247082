import React from "react";
import WebUserRegistration from "../components/SideNav/WebUserRegistration";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export default function SignupUserLayout(props) {
  return (
    <>
      <Header
        signupPage={true}
        showLoginButton={true}
        haveAccountText="You have a dikooko account already?"
      />

      <div className="flex max-w-screen-2xl mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <WebUserRegistration />
        {props.children}
      </div>

      <Footer />
    </>
  );
}