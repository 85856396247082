const initialState = {
  updatePasswordSuccessful: false,
  updateError: null,
  message:null,
};

export const updatePasswordReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  if (type === "SAVE_PASSWORD_UPDATE_DATA" ) {
    return {
      ...state,
      updateError: null,
      updatePasswordSuccessful:true,
      message:payload.data,
    };
  }else if (type === "UPDATE_PASSWORD_ERROR") {
    return {
      ...state,
      updateError: payload,
      message:payload.data,
      updatePasswordSuccessful:false,
    };
  }
  return state;
};
