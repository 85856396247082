import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import ContactSupportBody from '../components/UserNProviderUtility/ContactSupportBody'

export default function ContactSupportPage() {
  return (
    <div class="flex flex-col gap-3 justify-between w-full h-screen bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
    <Header />

    <ContactSupportBody/>

    <Footer />
  </div>
  )
}
