import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import BodyLayout from "../Layout/BodyLayout";

export default function SignupName(props) {
  const handleFormChange = (prop) => (event) => {
    props.setUserData({ ...props.userData, [prop]: event.target.value });
  };

  useEffect(() => {
    if (props.userData.firstName && props.userData.lastName) {
      props.setIsFormFilled(true);
    } else {
      props.setIsFormFilled(false);
    }
  }, [props, props.userData.firstName, props.userData.lastName]);



  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Name</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Fill in your legal name so we can know you.
      </p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4">
        <form className="" validate="true" autoComplete="on">
          <div>
            <p className="font-medium text-gray-500 mb-3">First Name</p>
            <TextField
              id="fname-field"
              onChange={handleFormChange("firstName")}
              variant="outlined"
              name="name fname"
              autoComplete="given-name"
              placeholder="First Name"
              className="w-full"
              value={props.userData.firstName}
            />
          </div>
          <br />
          <div>
            <p className="font-medium text-gray-500 mb-3">Last Name</p>
            <TextField
              id="lname-field"
              onChange={handleFormChange("lastName")}
              variant="outlined"
              name="name lname"
              autoComplete="family-name"
              placeholder="Last Name"
              className="w-full"
              value={props.userData.lastName}
            />
          </div>
          <br />
        </form>
      </div>
    </BodyLayout>
  );
}
