import { Button } from "@material-ui/core";
import React from "react";
import ThanksBodyLayout from "../Layout/ThanksBodyLayout";
import { Link } from "react-router-dom";
import {
  setUserType,
  clearLoginRelatedErrors,
} from "../../redux/actions/login";
import { connect } from "react-redux";

function PasswordResetSuccess(props) {
  return (
    <ThanksBodyLayout height="h-full" shadow="shadow-none">
      <div className="max-w-md mx-auto text-center my-12">
        <h3 className="text-xl sm:text-2xl">Password Reset Successful</h3>
        <div className="text-sm sm:text-base md:text-lg text-gray-500 pt-2 mt-4">
          <p>Your Password has been reset successfully.</p>
          <p>Login to start using dikooko again.</p>
        </div>
        <br /> <br />
        <Link to="/">
          <Button color="primary" variant="contained">
            <span className="capitalize text-sm sm:text-base px-2 py-1">
              Login
            </span>
          </Button>
        </Link>
        <br />
        <br />
      </div>
    </ThanksBodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  setUserType,
  clearLoginRelatedErrors,
})(PasswordResetSuccess);
