import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { ReactComponent as CircularRoundIcon } from "../../assets/icons/CircularRoundIcon.svg";
import AddJobRecordModal from "../Modals/AddJobRecordModal";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  addJobRecord,
  getJobRecord,
  getClientJobRecord,
  getProviderJobRecord,
  setSingleJobRecord,
  deleteJobRecord,
  editJobRecord,
} from "../../redux/actions/jobRecords";
import { checkIfUserExist } from "../../redux/actions/register";
import { browseServiceProviderByID } from "../../redux/actions/browseServiceProvider";
import moment from "moment";
import { ReadOnlyRating } from "../Rating/Rating";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    maxWidth: "500px",
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
  uploadButton: {
    backgroundColor: "rgba(79,70,229,0.1) !important",
  },
  imgContainer: {
    "& > *": {
      margin: theme.spacing(1),
      cursor: "pointer",
    },
  },
  input: {
    display: "none",
  },
  popover: {
    pointerEvents: "none",
  },
  paper2: {
    padding: theme.spacing(1),
  },
}));

function JobRecordsBody({ checkIfUserExist, browseServiceProviderByID, getJobRecord, getClientJobRecord, getProviderJobRecord, ...props }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { userExistData } = props?.registerReducer;
  const { userType, token, _id } = props?.loginReducer?.user;
  const serviceUserType = props?.loginReducer?.userType;
  const { getJobRecordsData, singleJobRecord, editedSuccessfully } = props?.jobRecordsReducer;
  const [values, setValues] = useState({
    clientIsOnDikooko: false,
  });

  const providerByID = props?.browseProviderReducer?.providerByID;
  const [modalType, setModalType] = useState("add");
  const handleCheckChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  const [userExist, setuserExist] = useState(userExistData?.exist);
  const [snackMessage, setsnackMessage] = useState(props?.jobRecordsReducer?.message);
  const [openSnack, setOpenSnack] = useState(false);
  const [particularThingsBeingEdited, setparticularThingsBeingEdited] = useState({});

  const [userData, setUserData] = useState(
    useEffect(() => {
      if (userExistData?.exist) {
        setuserExist(true);
        return { location: "", clientPhone: "+234", description: "" };
      } else {
        setuserExist(false);
        return {
          clientName: "",
          location: "",
          clientPhone: "+234",
          clientEmail: "",
          description: "",
        };
      }
    }, [userExistData?.exist, userExistData?._id, providerByID?.firstName, providerByID?.lastName, providerByID?.email])
  );

  const [initialEditUserData, setinitialEditUserData] = useState({
    location: singleJobRecord?.location || "",
    description: singleJobRecord?.description || "",
    client: singleJobRecord?.client?._id || "",
    clientName:
      singleJobRecord?.client?.firstName || singleJobRecord?.client?.lastName
        ? singleJobRecord?.client?.firstName + " " + singleJobRecord?.client?.lastName
        : singleJobRecord?.clientName,
    clientEmail: singleJobRecord?.client?.email || singleJobRecord?.clientEmail,
    clientPhone: singleJobRecord?.client?.phone || singleJobRecord?.clientPhone,
    image: singleJobRecord?.imgUrls || [],
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (prop) => (event) => {
    if (modalType !== "edit") {
      if (userExistData?.exist) {
        setUserData({ ...userData, [prop]: event.target.value, provider: _id, client: userExistData?._id });
      } else {
        setUserData({ ...userData, [prop]: event.target.value, provider: _id });
      }
    } else {
      if (userExistData?.exist) {
        setparticularThingsBeingEdited({ ...particularThingsBeingEdited, [prop]: event.target.value, client: userExistData?._id });
        setinitialEditUserData({ ...initialEditUserData, ...userData, [prop]: event.target.value, provider: _id, client: userExistData?._id });
      } else {
        setparticularThingsBeingEdited({ ...particularThingsBeingEdited, [prop]: event.target.value });
        setinitialEditUserData({ ...initialEditUserData, ...userData, [prop]: event.target.value, provider: _id });
      }
    }
  };

  useEffect(() => {
    checkIfUserExist({ phone: userData?.clientPhone ? userData?.clientPhone : initialEditUserData?.clientPhone });
    browseServiceProviderByID(userExistData?._id, token);
  }, [checkIfUserExist, userData?.clientPhone, initialEditUserData?.clientPhone, browseServiceProviderByID, token, userExistData?._id]);




  const [isSubmitted, setisSubmitted] = useState(false);

  const [deleteImages, setdeleteImages] = useState([]);
  const handleSubmit = async () => {
    handleClose();

    await props.addJobRecord(userData, userExist, token);

    await handleClickSnack();

    await setisSubmitted(true);

    if (userType === "provider" && serviceUserType === "Service Provider") {
      getProviderJobRecord(_id);
    } else {
      getClientJobRecord(_id);
    }
  };

  const handleDeleteJobRecord = async (jobRecordId) => {
    await props.deleteJobRecord(jobRecordId, token);

    handleClickSnack();

    if (userType === "provider" && serviceUserType === "Service Provider") {
      getProviderJobRecord(_id);
    } else {
      getClientJobRecord(_id);
    }
  };

  useEffect(() => {
    (userType === "provider" && serviceUserType === "Service Provider") ?getProviderJobRecord(_id): getClientJobRecord(_id);
  }, [getProviderJobRecord,getClientJobRecord, _id,serviceUserType,userType]);

  const handleEditJobRecord = async (jobRecordData) => {
    await props.setSingleJobRecord(jobRecordData);

    setinitialEditUserData({
      ...initialEditUserData,
      location: jobRecordData?.location || "",
      description: jobRecordData?.description || "",
      clientName:
        jobRecordData?.client?.firstName || jobRecordData?.client?.lastName
          ? jobRecordData?.client?.firstName + " " + jobRecordData?.client?.lastName
          : jobRecordData?.clientName,
      clientEmail: jobRecordData?.client?.email || jobRecordData?.clientEmail,
      clientPhone: jobRecordData?.client?.phone || jobRecordData?.clientPhone,
      image: jobRecordData?.imgUrls || [],
      client: jobRecordData.client?._id || "",
    });
    await setModalType("edit");
    await setuserExist(true);

    handleOpen();

    // props.setUserData();
    // props.browseServiceProviderByID(jobRecordData?.client?._id, token);
    if (userType === "provider" && serviceUserType === "Service Provider") {
      getProviderJobRecord(_id);
    } else {
      getClientJobRecord(_id);
    }  };

  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  };

  const handleSave = async () => {
    let cleanedEditUserData = clean(particularThingsBeingEdited);
    let form_data = new FormData();
    for (var key in cleanedEditUserData) {
      form_data.append(key, cleanedEditUserData[key]);
    }
    await props.editJobRecord(singleJobRecord?._id, token, form_data);

    handleClickSnack();
    handleClose();
  };

  const handleAddJobButtonClick = async () => {
    await setModalType("add");
    handleOpen();
  };

  const handleRemoveServerImage = (data, index) => {
    setinitialEditUserData({
      ...initialEditUserData,
      image: initialEditUserData.image.splice(index + 1, 1),
    });
    setdeleteImages([...deleteImages, data?.id]);
    setparticularThingsBeingEdited({ ...particularThingsBeingEdited, deleteImages: [...deleteImages, data?.id] });
  };

  

  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  

  useEffect(() => {
    let isCancelled = false;

    if(!isCancelled){
      setsnackMessage(props.jobRecordsReducer?.message);
    }
    return () => {
      isCancelled = true;
    }
  }, [props.jobRecordsReducer?.message])
  

  
  if (isSubmitted || getJobRecordsData?.length > 0) {
    return (
      <div className="mt-4 md:mt-8 xl:mt-24 md:px-6 px-4 d-flex flex-column w-full pb-12">
        <div className="flex flex-col lg:w-1/3 lg:mx-auto min-h-screen sm:mr-4">
          <button
            className="bg-black text-white py-2 px-4 text-base rounded mt-4 flex items-center space-x-2 w-max"
            onClick={handleAddJobButtonClick}
          >
            <AddRoundedIcon />
            <span>Add Job Record</span>
          </button>

          {(getJobRecordsData || [])?.map((jobrecord, index) => (
            <div className="p-4 pt-6 min-w-sm xl:min-w-sm2 mt-4 lg:mt-6 lg:px-8 lg:pt-6 lg:pb-4 bg-white rounded shadow-sm2" key={index}>
              <Link to="/provider/user-review/" onClick={() => props.setSingleJobRecord(jobrecord)}>
                <div className="text-lg">
                  {jobrecord?.client?.firstName || jobrecord?.client?.firstName ? (
                    <>
                      <span className="text-blue-700 font-medium pr-1">{jobrecord?.client?.firstName}</span>
                      <span className="text-blue-700 font-medium">{jobrecord?.client?.lastName}</span>
                    </>
                  ) : (
                    <span className="text-blue-700 font-medium">{jobrecord?.clientName}</span>
                  )}
                </div>
                <div className="flex items-center justify-between mb-3">
                  <p className="capitalize">
                    <i>{jobrecord?.location}</i>
                  </p>
                  <p>{moment.utc(new Date(jobrecord?.requestedAt)).format("MMM Do 'YY")}</p>
                </div>
              </Link>
              <div className="flex justify-between">
                {jobrecord?.reviewed ? (
                  <ReadOnlyRating ratingCount={jobrecord?.overallRating} color="black" />
                ) : (
                  <i className="text-gray-500">No rating Yet</i>
                )}

                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <div>
                      <IconButton {...bindTrigger(popupState)}>
                        <CircularRoundIcon />
                      </IconButton>

                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="text-left">
                          {!jobrecord?.reviewed && (
                            <>
                              <Button onClick={() => handleEditJobRecord(jobrecord)}>
                                <div className="w-full capitalize text-left">Edit</div>
                              </Button>
                              <br />
                            </>
                          )}

                          <Button onClick={() => handleDeleteJobRecord(jobrecord?._id)}>
                            <div className="w-full capitalize text-left">Delete</div>
                          </Button>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </div>
            </div>
          ))}
        </div>

        <AddJobRecordModal
          modalType={modalType}
          initialEditUserData={initialEditUserData}
          setinitialEditUserData={setinitialEditUserData}
          handleRemoveServerImage={handleRemoveServerImage}
          setUserData={setUserData}
          userData={userData}
          open={open}
          handleClose={handleClose}
          handleInputChange={handleInputChange}
          values={values}
          handleCheckChange={handleCheckChange}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          classes={classes}
          particularThingsBeingEdited={particularThingsBeingEdited}
          setparticularThingsBeingEdited={setparticularThingsBeingEdited}
        />

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={snackMessage}
          autoHideDuration={2500}
        />
      </div>
    );
  } else {
    return (
      <div className="h-screen sm:h-full w-full flex flex-col text-center justify-center items-center gap-4 sm:pt-24 lg:pt-32 xl:pt-48 max-w-lg mx-auto px-4">
        <h3 className="text-2xl">You do not have a job record on your profile yet</h3>
        <p className="text-gray-500 ">Fill a job record from your past works and request ratings and review from the client/service user.</p>
        <button className="bg-black text-white py-2 px-4 text-base rounded mt-4" onClick={handleOpen}>
          Fill A Job Record
        </button>

        <AddJobRecordModal
          initialEditUserData={initialEditUserData}
          setinitialEditUserData={setinitialEditUserData}
          setUserData={setUserData}
          userData={userData}
          open={open}
          handleClose={handleClose}
          handleInputChange={handleInputChange}
          values={values}
          handleCheckChange={handleCheckChange}
          handleSubmit={handleSubmit}
          classes={classes}
          particularThingsBeingEdited={particularThingsBeingEdited}
          setparticularThingsBeingEdited={setparticularThingsBeingEdited}
        />

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          // message={editedSuccessfully ? "Job Record Edited Successfully" : "Try again"}
          message={editedSuccessfully ? "Job Record Edited Successfully" : "Job Record Edited Successfully"}
          autoHideDuration={2500}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  addJobRecord,
  getJobRecord,
  getClientJobRecord,
  getProviderJobRecord,
  checkIfUserExist,
  browseServiceProviderByID,
  setSingleJobRecord,
  deleteJobRecord,
  editJobRecord,
})(JobRecordsBody);
