import React, { useState } from "react";
import Search from "../Search/Search";
import { connect } from "react-redux";
import ResultCategoryCard from "./ResultCategoryCard";
import CardRatingLocation from "../Rating/CardRatingLocation";

function CategoryPageComponent(props) {
  const categoryName = props?.categoryName;
  const editedCategoryName = categoryName?.replace(/_/g, " ");
  const [isSearching, setisSearching] = useState(false);

  return (
    <div className="max-w-screen-md-xl mx-auto py-8 flex gap-20">
      <div>
        <Search isSearching={isSearching} setisSearching={setisSearching} />

        {isSearching ? (
          <div className="text-center pt-5 sm:pt-10">
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center">{editedCategoryName}</h1>
          </div>
        ) : (
          ""
        )}

        <div className="mt-5 sm:mt-10 sm:pr-4">
          <ResultCategoryCard handleCall={props.handleCall} handleMessage={props.handleMessage} />
        </div>
      </div>

      <div className="hidden lg:block relative pt-32">
        <CardRatingLocation />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(CategoryPageComponent);
