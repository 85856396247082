import axios from "axios";
import { BASE_URL } from "../../constants/config";
import * as actionTypes from "./types";

export const updatePassword = (token, currentPassword, password) => {
  return async (dispatch) => {
    try {
      const updatePasswordX = await axios.post(
        `${BASE_URL}users/update-password/`,
        { currentPassword, password },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      if (updatePasswordX.status === 200 || 201) {
        dispatch(savePasswordUpdateDataToState(updatePasswordX));
        
      }
    } catch (error) {
      dispatch(updateError(error.response));
    }
  };
};

export const savePasswordUpdateDataToState = (data) => {
  return {
    type: actionTypes.SAVE_PASSWORD_UPDATE_DATA,
    payload:data,
  };
};

export const updateError = (err) => {
  return {
    type: actionTypes.UPDATE_PASSWORD_ERROR,
    payload: err,
  };
};
