import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WebSideNav from "../components/SideNav/IntroWebSideNav";
import RefereeForm from "../components/Auth/RefereeForm";

export default function Referee(props) {
  const { userId, providerId } = props?.match?.params;
  return (
    <>
      <Header
        signupPage={true}
        showLoginButton={true}
        haveAccountText="You have a dikooko account already?"
      />

      <div className="flex max-w-screen-2xl mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <WebSideNav
          title="Register to Enjoy What dikooko is Offering"
          body="Registering on the platform grants you access to service users and providers."
        />
        <RefereeForm userId={userId} providerId={providerId}/>
      </div>

      <Footer />
    </>
  );
}
