import { combineReducers } from "redux";
import { registerReducer } from "./registerReducer";
import { loginReducer } from "./loginReducer";
import { browseProviderReducer } from "./browseProviderReducer";
import { jobRecordsReducer } from "./jobRecords";
import { updatePasswordReducer } from "./updatePasswordReducer";
import {profileReducer} from "./profileReducer";

export default combineReducers({
  registerReducer,
  loginReducer,
  browseProviderReducer,
  jobRecordsReducer,
  updatePasswordReducer,
  profileReducer
});
