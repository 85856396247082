import { Button } from "@material-ui/core";
import React, { useState } from "react";
import BodyLayout from "../Layout/BodyLayout";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { sendOTPtoPhone } from "../../redux/actions/login";

function ForgotPasswordBody(props) {
  const [phoneNumber, setphoneNumber] = useState("");
  const history = useHistory();
  const handlePhoneChange = (value) => {
    if (value) {
      setphoneNumber(value);
    }
  };

  const handleSubmit = async () => {
    if (Boolean(phoneNumber.length === 14)) {
      await props.sendOTPtoPhone({ phone: phoneNumber });
      props.loginReducer.codeSent && history.push("/recover-password");
    }
  };

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Password Reset</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Reset your password to gain access to your dikooko account.
      </p>
      <br />

      <div className="flex flex-col gap-5 max-w-md pb-4">
        <form
          className=""
          validate="true"
          autoComplete="on"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="w-full mb-6">
            <MaterialUiPhoneNumber
              className="w-full"
              name="phone"
              label="Phone Number"
              data-cy="user-phone"
              defaultCountry={"ng"}
              variant="outlined"
              value={phoneNumber}
              onChange={handlePhoneChange}
              autoComplete="phone-number"
            />
            <p className="text-sm text-red-500">
              {props.loginReducer?.error?.data}
            </p>
          </div>
          <div className="flex justify-between">
            <Button>
              <Link
                to="/login"
                className="text-base py-2 px-3 capitalize font-semibold"
              >
                Back
              </Link>
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!Boolean(phoneNumber.length === 14)}
              onClick={handleSubmit}
            >
              <span className="text-base px-3 capitalize font-semibold text-white">
                Send Code
              </span>
            </Button>
          </div>
        </form>
      </div>
      <br />
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  sendOTPtoPhone,
})(ForgotPasswordBody);
