import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { updatePassword } from "../../redux/actions/updatePassword";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Snackbar from "@material-ui/core/Snackbar";

function EditSecurity(props) {

  const [isFormFilled, setIsFormFilled] = useState(false);

  const [snackMessage, setsnackMessage] = useState(props.updatePasswordReducer?.message);
  const [openSnack, setOpenSnack] = React.useState(false);

  const [values, setValues] = useState({
    password: "",
    currentPassword: "",
    confirmPassword: "",
    showPassword: false,
  });

  const token = props.loginReducer?.user?.token;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // custom rule will have name 'isPasswordValid'
    ValidatorForm.addValidationRule("isPasswordValid", (value) => {
      if (value.length < 8) {
        return false;
      }
      return true;
    });
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== values.password) {
        return false;
      }
      return true;
    });
    return () => {
      //cleanup: remove rule when it is not needed
      ValidatorForm.removeValidationRule("isPasswordValid");
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [values.password]);

  useEffect(() => {
    if (
      values.password &&
      values.password?.length > 7 &&
      values.confirmPassword?.length > 7 &&
      values.confirmPassword &&
      values.password === values.confirmPassword
    ) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [props, values, values.password, values.confirmPassword]);

  const handlePasswordUpdate = async () => {
    if (values.password === values.confirmPassword && values.password > 7) {
      await props.updatePassword(token, values.currentPassword, values.password);

      await handleClickSnack();
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      setsnackMessage(props.updatePasswordReducer?.message);
    }
    return () => {
      isCancelled = true;
    };
  }, [props.updatePasswordReducer?.message]);

  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <div className="sm:mt-12 sm:mt-14 sm:pl-4">
      <div className="max-w-2xl pb-8 sm:p-6" onClick={props.onClick}>
        <div className="flex flex-col sm:flex-row w-full">
          <ValidatorForm
            className="w-full pb-4 pr-4"
            validate="true"
            autoComplete="on"
            ref={React.createRef("form")}
            onSubmit={handlePasswordUpdate}
            // onError={(errors) => console.log(errors)}
          >
            <div className="w-full px-3">
              <div className="flex flex-col xl:flex-row gap-4 w-full justify-between">
                <div className="w-full mt-6">
                  <p className="ml-3 mb-3 w-full text-sm sm:text-base font-medium text-gray-500">Current Password</p>

                  <TextValidator
                    id="outlined-adornment-password"
                    variant="outlined"
                    className="w-full"
                    placeholder="Password"
                    onChange={handleChange("currentPassword")}
                    name="password"
                    value={values.currentPassword}
                    autoComplete="password"
                    type={values.showPassword ? "text" : "password"}
                    validators={["required", "isPasswordValid"]}
                    errorMessages={["This field is required", "Password is less than 8 characters"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="w-full mt-6">
                  <p className="ml-3 mb-3 w-full text-sm sm:text-base font-medium text-gray-500">New Password</p>

                  <TextValidator
                    id="outlined-adornment-password"
                    variant="outlined"
                    className="w-full"
                    placeholder="Password"
                    onChange={handleChange("password")}
                    name="password"
                    value={values.password}
                    autoComplete="password"
                    type={values.showPassword ? "text" : "password"}
                    validators={["required", "isPasswordValid"]}
                    errorMessages={["This field is required", "Password is less than 8 characters"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col xl:flex-row gap-4 w-full justify-between items-center">
                <div className="w-full xl:w-1/2 mt-6">
                  <p className="ml-3 mb-3 w-full text-sm sm:text-base font-medium text-gray-500">Confirm New Password</p>

                  <TextValidator
                    id="outlined-adornment-password"
                    variant="outlined"
                    className="w-full"
                    placeholder="Confirm Password"
                    onChange={handleChange("confirmPassword")}
                    name="password"
                    value={values.confirmPassword}
                    autoComplete="password"
                    type={values.showPassword ? "text" : "password"}
                    validators={["required", "isPasswordValid", "isPasswordMatch"]}
                    errorMessages={["This field is required", "Password is less than 8 characters", "Password mismatch"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="">
                  <div className="sm:py-8"></div>
                  <Button variant="contained" color="primary" disabled={!isFormFilled} onClick={handlePasswordUpdate}>
                    <span className="capitalize text-base p-1 font-body font-semibold">Update Password</span>
                  </Button>
                </div>
              </div>

              {/* {message && isSubmitting && (
                  <p className="text-green-500 pt-4">{message}</p>
                )} */}
            </div>
          </ValidatorForm>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnack}
        onClose={handleCloseSnack}
        message={snackMessage}
        autoHideDuration={2500}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  updatePassword,
  // updateAvatar,
  // updateProfileAvatar,
  // saveLoginUserDataToState,
  // updateProfileText,
})(EditSecurity);
