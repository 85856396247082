import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../Hooks/UseWindowDimension";

function Footer(props) {
  const { width } = useWindowDimensions();
  const { isAuthenticated} = props.loginReducer;

  return (
    <div className={`${isAuthenticated?"hidden":"block"} flex justify-center py-4 bg-white w-full`}>
      <div className="flex gap-4 md:gap-8 text-xs sm:text-sm md:text-base">
        <span>{width < 640 ? "About" : "About dikooko"}</span>
        <span className="border-b border-gray-400 border-dashed">
          <Link to="/termsNconditions">Terms and Policy</Link>
        </span>
        <span>FAQs</span>
        <span>Twitter</span>
        <span className="border-b border-gray-400 border-dashed"><Link to="/contact"> {width < 640 ? "Contact": "Contact Support"} </Link></span>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(Footer);
