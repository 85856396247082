import React from "react";

export default function BodyLayout(props) {
  return (
    <div
      className={`w-full sm:px-4 pb-24 md:px-0 ${
        props.height ? props.height : "h-screen"
      }  xl:pr-20`}
    >
      <div className="md:mt-8 md:mx-6  px-4 lg:pt-10 pt-5 sm:pt-12 lg:pl-20 pb-32 sm:mt-4  bg-white sm:rounded sm:shadow-sm2">
        <div
          className={` ${
            props.maxWidth ? props.maxWidth : ""
          } max-w-md mx-auto lg:mx-0`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
