import React, { useRef, useState } from "react";
import { Button,Snackbar } from "@material-ui/core";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import emailjs from "@emailjs/browser";
import { EmailJsConstants } from "../../constants";

export default function ContactSupportBody() {
  const form = useRef();
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    details: "",
  });
  const { width } = useWindowDimensions();
  const [isSpinning, setIsSpinning] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isEmailValid, setisEmailValid] = useState(false);

  const [snackMessage, setsnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email") {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
        setisEmailValid(true);
      } else {
        setisEmailValid(false);
      }
    }
  };

  const handlePhoneChange = (value) => {
    if (value) {
      setValues({ ...values, phoneNumber: value });
    }
  };

  // let filteredSubmissionData = Object.fromEntries(
  //   Object.entries(values).filter(([_, v]) => v !== null && v !== "" && v !== undefined)
  // );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSpinning(true);

    if (values.name && values.subject && values.details && values.phoneNumber) {
      await emailjs
        .sendForm(EmailJsConstants.SERVICE_ID, EmailJsConstants.TEMPLATE_ID, form.current, EmailJsConstants.PUBLIC_KEY)
        .then(
          (result) => {
            // console.log(result.text);
            setIsMessageSent(true);
          },
          (error) => {
            // console.log(error.text);
             setIsMessageSent(false);
             handleClickSnack();
            setsnackMessage("An Error occured");
          }
        );
    }

    setIsSpinning(false);
  };

    //======SnackBar======
    const handleClickSnack = () => {
      setOpenSnack(true);
    };
  
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpenSnack(false);
    };

  return (
    <div className="h-screen sm:h-full w-full flex flex-col text-center justify-center items-center mx-auto sm:px-4">
      <div className="bg-white shadow px-8 sm:px-20 py-4 sm:py-8">
        {isMessageSent ? (
          <div>
            <p className="py-6"> Thank You {values.name}!</p>
            <p className="pb-12"> Your message has been sent.</p>
          </div>
        ) : (
          <form
            className="flex flex-col gap-1 sm:gap-2 max-w-xl pb-4"
            validate="true"
            autoComplete="on"
            ref={form}
            onSubmit={handleSubmit}
          >
            <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
              <span className="required-field">Full name</span>
            </div>

            <input
              id="name-field"
              type="name"
              onChange={handleChange("name")}
              variant="outlined"
              style={width >= 768 ? { width: 400 } : { minWidth: 270 }}
              name="name"
              autoComplete="name"
              value={values.name}
              className="border border-gray-400 px-3 py-2.5 rounded"
            />

            <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
              <span>Email Address</span> <span className="px-2 text-sm pt-0.5 font-normal">(optional)</span>
            </div>

            <input
              id="email-field"
              type="email"
              onChange={handleChange("email")}
              variant="outlined"
              name="email"
              autoComplete="email"
              value={values.email}
              className="border border-gray-400 px-3 py-2.5 rounded"
            />
            {values.email.length > 0 && !isEmailValid && (
              <span className="text-sm text-red-500 text-left">Email is not valid</span>
            )}

            <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
              <span className="required-field">Phone Number</span>
            </div>
            <MaterialUiPhoneNumber
              name="phone"
              data-cy="user-phone"
              defaultCountry={"ng"}
              variant="outlined"
              value={values.phoneNumber}
              onChange={handlePhoneChange}
              autoComplete="phone-number"
              required
            />

            <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
              <span className="required-field">Subject</span>
            </div>

            <input
              id="subject-field"
              type="subject"
              onChange={handleChange("subject")}
              variant="outlined"
              className="border border-gray-400 px-3 py-2.5 rounded"
              name="subject"
              autoComplete="subject"
              value={values.subject}
            />

            <div className="flex mt-3 text-sm sm:text-base text-gray-500 font-medium">
              <span className="required-field">Details</span>
            </div>

            <textarea
              name="message"
              id=""
              cols="15"
              rows="3"
              className="border border-gray-300 rounded w-full mb-3 p-3"
              value={values.details}
              onChange={handleChange("details")}
            ></textarea>
            <Button
              type="submit"
              disabled={values.name && values.subject && values.details && values.phoneNumber ? false : true}
              className="shadow flex items-center"
              style={
                values.name && values.subject && values.phoneNumber && values.details
                  ? { backgroundColor: "black", color: "white" }
                  : { backgroundColor: "grey", color: "white" }
              }
            >
              {isSpinning && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              <span className="py-1">Submit</span>
            </Button>
          </form>
        )}

<Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={snackMessage}
          autoHideDuration={2500}
        />
      </div>
    </div>
  );
}
