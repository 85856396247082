import React, { useEffect, useRef, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
// import { ReactComponent as Logo2 } from "../../assets/icons/Logo2.svg";
import useWindowDimension from "../../Hooks/UseWindowDimension";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/login";
import { connect } from "react-redux";
import {
  Button,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import LoginComponent from "./LoginComponent";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import MobileSideNav from "../SideNav/MobileSideNav";

const useStyles = makeStyles((theme) => ({
  menulist: {
    padding: "0px 0px",
    [theme.breakpoints.up("sm")]: {
      padding: "8px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10px 0px",
    },
  },
  list: {
    width: 230,
  },
  fullList: {
    width: "auto",
  },
}));

function Header(props) {
  const { width } = useWindowDimension();
  const history = useHistory();
  const [currentPath, setcurrentPath] = useState(window.location.pathname);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const { user, isAuthenticated, userType } = props.loginReducer;
  const username = `Hi, ${user.firstName}`;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    props.logout();
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChangeRoute = async (routeProp) => {
    await setcurrentPath(routeProp);
    history.replace(routeProp);
    history.push(routeProp);
    window.location.reload();
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <Logo className="px-1.5 sm:p-0" />
          </ListItemIcon>
          <ListItemText />
        </ListItem>
      </List>
      <Divider />

      <MobileSideNav
        userType={userType}
        handleChangeRoute={handleChangeRoute}
        currentPath={currentPath}
        handleLogout={handleLogout}
      />
    </div>
  );

  return (
    <div className="sticky top-0 z-10 bg-white shadow">
      <div className="max-w-screen-2xl mx-auto py-3 lg:py-4 px-3 sm:px-5 md:px-20">
        <div className="flex justify-between items-center">
          <div className="flex sm:justify-between items-center">
            {isAuthenticated && (
              <div>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <div
                      className="block sm:hidden cursor-pointer"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <MenuIcon />
                    </div>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            )}
            <Link to="/" className="text-xl sm:text-2xl font-bold pl-3 sm:pl-0 cursor-pointer">
              {/* {width > 640 ? ( */}
                <Logo className="p-1.5 sm:p-0" />
              {/* ) : (
                <Logo2 className={width > 300 ? "ml-3" : ""} />
              )} */}
            </Link>
          </div>
          {props?.headingText ? (
            <h3 className="hidden sm:block text-xl font-medium text-center">
              {props?.headingText}
            </h3>
          ) : (
            ""
          )}

          <div className={`flex ${width > 400 ? "gap-4" : "gap-2"} md:gap-10 items-center`}>
            {props.haveAccountText && (
              <span className="hidden sm:block text-gray-500 font-medium">
                {props.haveAccountText}
              </span>
            )}
            {props.loginReducer.isAuthenticated ? (
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <span className="whitespace-nowrap capitalize sm:text-base">{username}</span>
                  {width < 640 ? <ExpandMoreRoundedIcon /> : ""}
                </Button>
                {width < 640 ? (
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                              onClick={() => {
                                handleLogout();
                                handleClose();
                              }}
                              className={classes.menulist}
                            >
                              <MenuItem>
                                <span className="px-3">Logout</span>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                ) : (
                  ""
                )}
              </div>
            ) : (
              props.showRegisterButton && (
                <Link
                  to="/signup"
                  className="font-medium px-2 py-1.5 sm:px-3 sm:py-2 text-xs sm:text-base bg-black rounded-md text-white shadow-md font-body capitalize outline-none"
                >
                  Register
                </Link>
              )
            )}

            {props.loginReducer.isAuthenticated ? (
              width > 640 ? (
                <Link
                  to="/"
                  onClick={handleLogout}
                  className={`font-medium bg-white rounded-md  ${
                    props.loginReducer.isAuthenticated ? "sm:px-3.5" : "sm:px-6"
                  } px-2 py-1.5 sm:py-2 text-xs sm:text-base  border border-black text-black shadow-md font-body capitalize outline-none`}
                >
                  Logout
                </Link>
              ) : (
                ""
              )
            ) : (
              <div className="font-medium bg-white rounded-md text-xs sm:text-base text-black shadow-md font-body capitalize outline-none">
                <LoginComponent>
                  <span className="sm:py-1 sm:pl-1 text-sm sm:text-base items-center whitespace-nowrap capitalize">
                    Login {width > 480 ? <KeyboardArrowDownRoundedIcon /> : ""}
                  </span>
                </LoginComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { logout })(Header);
