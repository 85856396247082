import React, { Fragment, useState } from "react";
import BodyLayout from "../Layout/BodyLayout";
// import AddRemoveForm from "../Forms/AddRemoveRefreeForm";
import { Button, TextField } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { connect } from "react-redux";

function SignupReferees(props) {
  const [referees, setreferees] = useState([{ fullName: "", phone: "+234" }]);
  const { error } = props?.registerReducer;


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...referees];
    list[index][name] = value;
    setreferees(list);
    props.otherInformation.referees = list;
    // props.otherInformation._id = props.registerReducer.user._id;  //to_add_back
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...referees];
    list.splice(index, 1);
    setreferees(list);
    props.otherInformation.referees = list;
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setreferees([...referees, { fullName: "", phone: "+234" }]);
    props.otherInformation.referees = referees;
  };

  if (
    referees.length < 3 ||
    referees[0].fullName === "" ||
    referees[0].phone === "+234" ||
    referees[1]?.fullName === "" ||
    referees[1]?.phone === "+234" ||
    referees[2]?.fullName === "" ||
    referees[2]?.phone === "+234" ||
    referees[0].fullName === referees[1]?.fullName ||
    referees[0].fullName === referees[2]?.fullName ||
    referees[1]?.fullName === referees[2]?.fullName ||
    referees[0].phone === referees[1]?.phone ||
    referees[0].phone === referees[2]?.phone ||
    referees[1]?.phone === referees[2]?.phone ||
    referees[0]?.phone.length !== 14 ||
    referees[1]?.phone.length !== 14 ||
    referees[2]?.phone.length !== 14
  ) {
    props.setIsFormFilled(false);
  } else {
    props.setIsFormFilled(true);
  }


  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Referees</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Add people that have good reviews about your works so we can reach out
        to them.
      </p>
      <br />
      <p
        className={`text-sm ${
          props.isFormFilled ? "text-black" : "text-red-500"
        }`}
      >
        You can only add between 3-5 different referees.
      </p>
      <br />

      {/* <AddRemoveForm /> */}

      <div className="">
        {referees.map((x, i) => {
          return (
            <Fragment key={i}>
              <div className="w-full flex-col items-center pt-3">
                <div className="flex gap-20">
                  <p className="font-medium text-gray-500 mb-3">
                    Referee’s Full Name
                  </p>
                  <p className="font-medium text-gray-500 mb-3">
                    Referee’s Phone Number
                  </p>
                </div>

                <div className="flex">
                  <div className="w-full gap-8 flex items-center min-w-sm1">
                    <div className="w-full">
                      <TextField
                        id="fname-field"
                        value={x.fullName}
                        onChange={(e) => handleInputChange(e, i)}
                        variant="outlined"
                        name="fullName"
                        autoComplete="name"
                        className="w-full"
                      />
                    </div>
                    <div className="w-full">
                      <TextField
                        id="fname-field"
                        value={x.phone}
                        onChange={(e) => handleInputChange(e, i)}
                        variant="outlined"
                        name="phone"
                        autoComplete="phone"
                        className="w-full"
                      />
                    </div>
                  </div>
                  {referees.length !== 1 ? (
                    <div className="pl-1">
                      <Button onClick={() => handleRemoveClick(i)}>
                        <CloseRoundedIcon color="primary" />
                      </Button>
                    </div>
                  ) : (
                    <div className="w-20"></div>
                  )}
                </div>
                <br />
                {referees.length - 1 === i && referees.length < 5 && (
                  <Button
                    className="flex items-center"
                    onClick={handleAddClick}
                  >
                    <AddRoundedIcon />
                    <span className="pl-1 capitalize text-base font-medium">
                      Add Referee
                    </span>
                  </Button>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>

      {error && typeof error === "string" && (
        <p className="text-red-500">{error}</p>
      )}
      <br />
      <br />
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(SignupReferees);
