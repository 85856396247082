import React from "react";

export default function ThanksBodyLayout(props) {
  return (
    <div
      className={`w-full sm:px-4 pb-24 md:px-0 ${
        props.height ? props.height : "md:h-screen"
      }  xl:pr-20`}
    >
      <div
        className={`md:mt-8 md:mx-6  px-4 lg:pt-10 pt-5 sm:pt-12 lg:pl-20 pb-32 sm:mt-4  bg-white sm:rounded ${
          props.shadow ? props.shadow : "sm:shadow-sm2"
        } `}
      >
        <div className="w-full text-center lg:mx-0">{props.children}</div>
      </div>
    </div>
  );
}
