const initialState = {
    message: "",
    profileData: {},
    profileError:null,
    userDetails: {},
    userDetailsError: null,
  };
  
  export const profileReducer = (state = initialState, actions) => {
    const { type, payload } = actions;

    if (type === "SAVE_UPDATE_PROFILE_DATA") {
      return {
        ...state,
        message: payload,
        profileData: payload,
        profileError:null,
      };
    }
    else if (type === "UPDATE_PROFILE_ERROR") {
      return {
        ...state,
        message: payload?.data,
        profileError: payload,
      };
    }
    else if (type === "SAVE_GET_LOGGED_IN_USER_DETAILS_DATA") {
      return {
        ...state,
        userDetails: payload,
        userDetailsError: null,
      };
    }
    else if (type === "LOGGED_IN_USER_DETAILS_ERROR") {
      return {
        ...state,
        userDetailsError: payload,
        message: payload?.data,
      };
    }
 return state;
  };
  