import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { connect } from "react-redux";
import WebUserAuthSideNav from "../../components/SideNav/WebUserAuthSideNav";
import LandingComponent from "../../components/UserNProviderUtility/LandingComponent";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import LoginShowDetailsModal from "../../components/Modals/LoginShowDetailsModal";
import {viewSingleProvider} from "../../redux/actions/browseServiceProvider";

function LandingPage(props) {
  const { width } = useWindowDimensions();
  const { isAuthenticated, user, userType } = props.loginReducer;
  const username = `Welcome, ${user.firstName}`;

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = (data) => {
    setOpen(true);
    props.viewSingleProvider(data);
  };

  const handleMessage = () => {
    if (isAuthenticated === false) {
      setOpen(true);
    } else {
      history.push("/messaging");
    }
  };

  return (
    <>
      {isAuthenticated && user.userType === "user" && <Redirect to="/user" />}
      {isAuthenticated && user.userType === "provider" && userType === "Service Provider" && <Redirect to="/provider/job-records" />}
      {!isAuthenticated || width < 640 ? <Header showRegisterButton={true} showLoginButton={width > 280 ? true : false} /> : ""}
      {isAuthenticated && <div className="absolute z-2 right-0 hidden sm:block float-right pt-5 pr-12">{username}</div>}

      <div className="flex gap-3 w-full px-2 sm:px-4 pb-24 md:px-0 md:h-full bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        {isAuthenticated && <WebUserAuthSideNav />}
        {isAuthenticated && <div className="hidden sm:block sm:pl-72"></div>}
        <LandingComponent open={open} setOpen={setOpen} handleCall={handleCall} handleMessage={handleMessage} handleClose={handleClose}/>
      </div>
      <Footer />
      <LoginShowDetailsModal isAuthenticated={isAuthenticated} open={open} handleClose={handleClose} handleCall={handleCall} />

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {viewSingleProvider})(LandingPage);
