const initialState = {
  user: {},
  token: "",
  error: null,
  isAuthenticated: false,
  codeSent: false,
  userType: "",
};

export let userType;

export const loginReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  if (type === "SAVE_LOGGED_IN_USER_DATA" || type === "LOGIN_SUCCESS") {
    localStorage.setItem("token", JSON.stringify(payload?.accessToken));
    localStorage.setItem("user", JSON.stringify(payload));
    // let error = null;
    return {
      ...state,
      token: payload?.accessToken,
      user: payload,
      isSuccessful: true,
      isAuthenticated: true,
      error: null,
    };
  } else if (type === "LOGIN_ERROR") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    return {
      ...state,
      error: payload,
      isSuccessful: false,
      isAuthenticated: false,
      codeSent: false,
    };
  } else if (type === "LOGOUT") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    let user = {};
    return {
      ...state,
      user,
      isSuccessful: false,
      isAuthenticated: false,
      token: "",
      error: null,
      codeSent: false,
    };
  } else if (type === "CLEAR_LOGIN_RELATED_ERROR") {
    let error = null;
    return {
      ...state,
      error,
    };
  } else if (type === "SET_USER_TYPE") {
    userType = payload;
    return {
      ...state,
      userType: payload,
    };
  } else if (type === "SAVE_PASSWORD_RESET_DATA") {
    return {
      ...state,
      passwordResetData: payload,
      codeSent: true,
      error: null,
    };
  }

  return state;
};
