import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ReactComponent as AutomotiveTiny } from "../../assets/icons/AutomotiveTiny.svg";
import { ReactComponent as BuildingTiny } from "../../assets/icons/BuildingTiny.svg";
import { ReactComponent as CaregivingTiny } from "../../assets/icons/CaregivingTiny.svg";
import { ReactComponent as CateringTiny } from "../../assets/icons/CateringTiny.svg";
import { ReactComponent as CleaningTiny } from "../../assets/icons/CleaningTiny.svg";
import { ReactComponent as EventPlanningTiny } from "../../assets/icons/EventPlanningTiny.svg";
import { ReactComponent as FashionDesigningTiny } from "../../assets/icons/FashionDesigningTiny.svg";
import { ReactComponent as HealthTiny } from "../../assets/icons/HealthTiny.svg";
import { ReactComponent as LaundryTiny } from "../../assets/icons/LaundryTiny.svg";
import { ReactComponent as LogisticsTiny } from "../../assets/icons/LogisticsTiny.svg";
import { ReactComponent as MaintenanceTiny } from "../../assets/icons/MaintenanceTiny.svg";
import { ReactComponent as OthersTiny } from "../../assets/icons/OthersTiny.svg";
import { MuiCategoryButton } from "../Button/CategoryButton";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    maxWidth: "500px",
  },
  bottomNav: {
    width: "100%",
    height: "60px",
  },
}));

function ServiceCategoryModal({ setotherInformation, ...props }) {
  const classes = useStyles();

  const [categoryLength, setcategoryLength] = useState(props.otherInformation.categories.length);

  const [check, setCheck] = React.useState({
    Automotive: props.otherInformation.categories.includes("Automotive") ? true : false,
    Building: props.otherInformation.categories.includes("Building") ? true : false,
    Caregiving: props.otherInformation.categories.includes("Caregiving") ? true : false,
    Catering: props.otherInformation.categories.includes("Catering") ? true : false,
    Cleaning: props.otherInformation.categories.includes("Cleaning") ? true : false,
    Event_Planning: props.otherInformation.categories.includes("Event_Planning") ? true : false,
    Fashion_Clothing: props.otherInformation.categories.includes("Fashion_Clothing") ? true : false,
    Wellness_Beauty: props.otherInformation.categories.includes("Wellness_Beauty") ? true : false,
    Telecoms: props.otherInformation.categories.includes("Telecoms") ? true : false,
    Logistics: props.otherInformation.categories.includes("Logistics") ? true : false,
    Maintenance: props.otherInformation.categories.includes("Maintenance") ? true : false,
    Others: props.otherInformation.categories.includes("Others") ? true : false,
  });

  const handleCheckChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };
  let keys = Object.keys(check);

  props.otherInformation.categories = keys.filter(function (key) {
    return check[key];
  });

  const [greaterThanOrEqualTo3, setgreaterThanOrEqualTo3] = useState(false);
  useEffect(() => {
    if (categoryLength >= 3) {
      setgreaterThanOrEqualTo3(true);
    } else {
      setgreaterThanOrEqualTo3(false);
    }

    setotherInformation(props.otherInformation);
  }, [categoryLength, props.otherInformation, setotherInformation]);

  useEffect(() => {
    setcategoryLength(props.otherInformation.categories.length);
  }, [props.otherInformation.categories.length]);

  const handleSaveCategory = () => {

    props.handleClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`${classes.paper} flex flex-col mx-4 outline-none text-center max-w-xl`}>
          <div className="p-6 flex-1 max-w-lg mx-auto px-3">
            <div className="flex justify-between pl-4">
              <div className="text-left">
                <h3 className="text-xl sm:text-2xl">Service Category</h3>
                <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">What are you offering to the public?</p>
              </div>
              <div>
                <span>
                  <Button onClick={props.handleClose}>
                    <CloseRoundedIcon className="text-black" />
                  </Button>
                </span>
              </div>
            </div>
            <br />
            <p className={`text-left pl-4 text-sm ${greaterThanOrEqualTo3 ? "text-red-500" : ""}`}>You can only select up to 3 categories</p>

            <div className="flex flex-col gap-2 mt-4 pl-4">
              <div className="flex gap-1  flex-wrap MuiCategory_wrapper">
                <MuiCategoryButton
                  name="Automotive"
                  itemName="Automotive"
                  disabled={greaterThanOrEqualTo3 === true && check.Automotive === false ? true : false}
                  checked={check.Automotive}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<AutomotiveTiny className={check.Automotive ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Building"
                  itemName="Building"
                  disabled={greaterThanOrEqualTo3 === true && check.Building === false ? true : false}
                  checked={check.Building}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<BuildingTiny className={check.Building ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Catering"
                  itemName="Catering"
                  disabled={greaterThanOrEqualTo3 === true && check.Catering === false ? true : false}
                  checked={check.Catering}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<CateringTiny className={check.Catering ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Caregiving"
                  itemName="Caregiving"
                  disabled={greaterThanOrEqualTo3 === true && check.Caregiving === false ? true : false}
                  checked={check.Caregiving}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<CaregivingTiny className={check.Caregiving ? "mr-2 filter invert" : "mr-2"} />}
                />
                <MuiCategoryButton
                  name="Cleaning"
                  itemName="Cleaning"
                  disabled={greaterThanOrEqualTo3 === true && check.Cleaning === false ? true : false}
                  checked={check.Cleaning}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<CleaningTiny className={check.Cleaning ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Event_Planning"
                  itemName="Event Planning"
                  disabled={greaterThanOrEqualTo3 === true && check.Event_Planning === false ? true : false}
                  checked={check.Event_Planning}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<EventPlanningTiny className={check.Event_Planning ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Fashion_Clothing"
                  itemName="Fashion & Clothing"
                  disabled={greaterThanOrEqualTo3 === true && check.Fashion_Clothing === false ? true : false}
                  checked={check.Fashion_Clothing}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<FashionDesigningTiny className={check.Fashion_Clothing ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Wellness_Beauty"
                  itemName="Wellness & Beauty"
                  disabled={greaterThanOrEqualTo3 === true && check.Wellness_Beauty === false ? true : false}
                  checked={check.Wellness_Beauty}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<HealthTiny className={check.Wellness_Beauty ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Telecoms"
                  itemName="Telecoms"
                  disabled={greaterThanOrEqualTo3 === true && check.Telecoms === false ? true : false}
                  checked={check.Telecoms}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<LaundryTiny className={check.Telecoms ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Logistics"
                  itemName="Logistics"
                  disabled={greaterThanOrEqualTo3 === true && check.Logistics === false ? true : false}
                  checked={check.Logistics}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<LogisticsTiny className={check.Logistics ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Maintenance"
                  itemName="Maintenance"
                  disabled={greaterThanOrEqualTo3 === true && check.Maintenance === false ? true : false}
                  checked={check.Maintenance}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<MaintenanceTiny className={check.Maintenance ? "mr-2 filter invert" : "mr-2"} />}
                />

                <MuiCategoryButton
                  name="Others"
                  itemName="Others"
                  disabled={greaterThanOrEqualTo3 === true && check.Others === false ? true : false}
                  checked={check.Others}
                  handleCheckChange={handleCheckChange}
                  IconGrp={<OthersTiny className={check.Others ? "mr-2 filter invert" : "mr-2"} />}
                />
              </div>
            </div>

            <div className="w-full flex justify-between mt-4 px-4">
              <Button variant="contained" color="primary" className="px-3" onClick={props.handleClose}>
                <span className="capitalize ">Cancel</span>
              </Button>
              <Button variant="contained" color="primary" className="px-3" onClick={handleSaveCategory}>
                <span className="capitalize ">Save Changes</span>
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(ServiceCategoryModal);
