import React from "react";
import ImageAvatar from "../Avatar/ImageAvatar";
import BiggerWhite from "../SplitScreen/BiggerWhite";
import SplitScreen from "../SplitScreen/Index";
import SmallerTransparent from "../SplitScreen/SmallerTransparent";
import { Divider } from "@material-ui/core";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import {
  Link,
  // Redirect,
  useHistory,
} from "react-router-dom";
import MessageInput from "./MessageInput";

export default function MessagesBody(props) {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const handleProfileClick = (id) => {
    if (width <= 1024) {
      history.replace("/messaging");
      history.push("/messaging");
      window.location.reload();
    } else {
      console.log("handleProfileClick clicked");
    }
  };
  return (
    <SplitScreen newClassName="justify-between">
      <SmallerTransparent>
        <div className="mt-16 lg:mt-20 ml-3 mr-4 sm:-ml-3 sm:mr-3">
          {window.location.pathname.includes("contact-support") ? (
            ""
          ) : (
            <div className="flex items-center ">
              <input
                type="text"
                placeholder="Search Service Provider's Name"
                // value={searchValue}
                // onChange={handleSearch}
                className="mb-6 rounded-lg bg-transparent w-full p-2 sm:px-4 border border-gray-500 focus:outline-none focus:border-gray-900"
              />
            </div>
          )}
          <div>
            {width < 1024 ? (
              <Link to="/messaging" className="flex pb-2" onClick={() => handleProfileClick("60897qgah872d")}>
                <ImageAvatar src="" />
                <div>
                  <p className="text-blue-700">{props.name}</p>
                  <p className="text-gray-500">{props.lastMessage}</p>
                </div>
              </Link>
            ) : (
              <div className="flex pb-2">
                <ImageAvatar src="" />
                <div>
                  <p className="text-blue-700">{props.name}</p>
                  <p className="text-gray-500">{props.lastMessage}</p>
                </div>
              </div>
            )}
            <Divider />
          </div>
        </div>
      </SmallerTransparent>

      {width >= 1024 && (
        <BiggerWhite>
          <div className="mt-12 lg:mt-24 pl-4">
            <h3 className="text-blue-700 font-semibold">{window.location.pathname.includes("contact-support") ? "dikooko Support" : props.name}</h3>
            <hr className="-ml-4 my-4 w-full" />
          </div>
          <div className="relative w-full h-full">
            <div className="absolute bottom-56 w-full my-4">
              <hr className="my-4 " />
              <MessageInput />
            </div>
          </div>
        </BiggerWhite>
      )}
    </SplitScreen>
  );
}
