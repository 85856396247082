import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import BodyLayout from "../Layout/BodyLayout";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { verifyPhoneNumber } from "../../redux/actions/register";

function RecoveryCode(props) {
  const [code, setcode] = useState("");

  const handleCodeChange = (event) => {
    setcode(event.target.value);
  };
  const history = useHistory();

  const handleSubmit = async () => {
    if (code) {
      await props.verifyPhoneNumber({
        code,
        phone: props.loginReducer?.passwordResetData?.phone,
      });
      if (
        props.registerReducer.isSuccessful ||
        props.registerReducer.isPhoneVerified
      ) {
        history.push("/create-new-password");
      }
    }
  };

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Recovery Code </h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Enter the 4-digit recovery code that was sent to your number.
      </p>
      <br />

      <div className="flex flex-col gap-5 max-w-md pb-4">
        <form
          className=""
          validate="true"
          autoComplete="on"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="w-full mb-6">
            <TextField
              id="code-field"
              value={code}
              onChange={handleCodeChange}
              variant="outlined"
              name="code"
              autoComplete="code"
              placeholder="Code"
              className="w-full"
            />
            <p className="text-sm text-red-500">
              {props.registerReducer?.error}
            </p>
          </div>
          <div className="flex justify-between">
            <Button>
              <Link
                to="/forgot-password"
                className="text-base py-2 px-3 capitalize font-semibold"
              >
                Back
              </Link>
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!Boolean(code)}
              onClick={handleSubmit}
            >
              <span className="text-base px-3 capitalize font-semibold text-white">
                Continue
              </span>
            </Button>
          </div>
        </form>
      </div>
      <br />
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  verifyPhoneNumber,
})(RecoveryCode);
