import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import SignupUserLayout from "./SignupUserLayout";
import SignupName from "../components/Auth/SignupName";
import useWindowDimensions from "../Hooks/UseWindowDimension";
import SignupContact from "../components/Auth/SignupContact";
import SignupLocation from "../components/Auth/SignupUserLocation";
import SignupSecurity from "../components/Auth/SignupSecurity";
// import VerifyPhoneNumber from "../components/Auth/VerifyPhoneNumber";  //to_add_back
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  createServiceUser,
  verifyPhoneNumber,
  setPassword,
  updateUserData,
  saveRegisteredUserDataToState,
  clearRegisterRelatedErrors,
  checkIfUserExist,
  resetCheckIfUserExist
} from "../redux/actions/register";
import RegistrationThanks from "../components/Auth/RegistrationThanks";
import { Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    paddingLeft: "0rem",
    paddingRight: "0rem",
    transform: "scale(0.9)",
    marginLeft: "-0.9rem",
    marginTop: "-0.5rem",
    backgroundColor: "transparent",
  },
}));

function SignupUserStepper({ resetCheckIfUserExist, ...props }) {
  const { width } = useWindowDimensions();
  function getSteps() {
    return [
      "Name",
      "Contact",
      // `${width > 700 ? "Verify Phone Number" : "Verify"}`, //to_add_back
      "Location",
      "Security",
      "Thanks",
    ];
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [isRegisteredSuccessful] = useState(true);
  // const [isCodeCorrect] = useState(true);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isProcessing] = useState(false);
  const userExist = props?.registerReducer?.emailCheck?.exist && props?.registerReducer?.phoneCheck?.exist;
  const { userExistError } = props?.registerReducer;
  const [openSnack, setOpenSnack] = React.useState(false);

  const handleWidthOfButtonTwo = () => {
    if (width > 480) {
      return { position: "relative", top: "-14rem", left: "12.5rem" };
    } else return { position: "relative", top: "-14rem", left: "4rem" };
  };

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  });
   //to_add_back
  // const [verifyUserPhone, setverifyUserPhone] = useState({
  //   _id: "",
  //   code: "",
  // });
  const [serviceUserPassword, setserviceUserPassword] = useState({
    _id: props?.registerReducer?.user?._id,
    password: "",
  });
  const [locationData, setlocationData] = useState({
    //to_add_back
    // _id: "",
    state: "",
    city: "",
    area: "",
  });


  useEffect(() => {
    resetCheckIfUserExist();
  }, [resetCheckIfUserExist]);

  const handleNext = async() => {
    if (activeStep === 0) {
     await props.clearRegisterRelatedErrors();
    }
    // if (activeStep === 1) {
    //   await props.createServiceUser(userData);
    // }
    // if (activeStep === 2) {
    //   await props.verifyPhoneNumber(verifyUserPhone);
    // }
    // if (activeStep === 2) {
    //   // console.log("currentData  from stepper", {
    //   //   ...userData,
    //   //   ...locationData,
    //   // });
    //   await props.updateUserData({ ...userData, ...locationData });
    // }

    //to_remove_later
     if (activeStep === 2) {
      handleClickSnack()
      await props.createServiceUser({...userData, ...locationData});
      handleCloseSnack()
    }
    if (activeStep === 3) {
      handleClickSnack()
      await props.setPassword(serviceUserPassword);
      handleCloseSnack()
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // console.log("from signupStepper", { ...userData, ...locationData });


  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };



   function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <SignupName
              setUserData={setUserData}
              userData={userData}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );
      case 1:
          return (
            <>
              <SignupContact
               userExist={userExist} 
               userExistError={userExistError} 
                isProcessing={isProcessing}
                setUserData={setUserData}
                userData={userData}
                isFormFilled={isFormFilled}
                setIsFormFilled={setIsFormFilled}
              />
            </>
          );
       
      // case 2:
      //   if ( props.registerReducer.isSuccessful) {
      //     return (
      //     <>
      //       <VerifyPhoneNumber
      //         isCodeCorrect={isCodeCorrect}
      //         userData={userData}
      //         verifyUserPhone={verifyUserPhone}
      //         setverifyUserPhone={setverifyUserPhone}
      //         isFormFilled={isFormFilled}
      //         setIsFormFilled={setIsFormFilled}
      //         signupPerson="Service User"
      //       />
      //     </>
      //   );
      // } else {
      //   handleBack();
      // }
      // break;


      case 2:
        return (
          <>
            <SignupLocation
              userData={userData}
              locationData={locationData}
              setlocationData={setlocationData}
            />
          </>
        );

      case 3:
          if (props.registerReducer.isSuccessful) {
        return (
          <>
            <SignupSecurity
              serviceUserPassword={serviceUserPassword}
              setserviceUserPassword={setserviceUserPassword}
              isFormFilled={isFormFilled}
              setIsFormFilled={setIsFormFilled}
            />
          </>
        );
      } else {
        handleBack();
      }
      break;

      case 4:
        if (props.registerReducer.isSuccessful) {
          return (
            <>
              <RegistrationThanks userData={userData} />
            </>
          );
        } else {
          handleBack();
        }
        break;

  

      default:
        return "Unknown stepIndex";




        
    }
  }

  return (
    <SignupUserLayout>
      <div className={classes.root}>
        {width < 768 ? (
          <div className="w-full">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        ) : (
          ""
        )}

        <div>
          {activeStep === steps.length ? (
            isRegisteredSuccessful ? (
              <Redirect to="/login" />
            ) : (
              handleBack()
            )
          ) : (
            <div>
              {getStepContent(activeStep)}
              <>
                { activeStep ===4 ? (
                 ""
                ) : (
                  <>
                  <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={
                    width > 480
                      ? { position: "relative", top: "-14rem", left: "6rem" }
                      : { position: "relative", top: "-14rem", left: "1rem" }
                  }
                >
                  <span className="capitalize text-base font-medium px-3">
                    Back
                  </span>
                </Button>
                
                 {/* to_add_back
                 {activeStep === 3 && (
                  <Button
                    color="primary"
                    onClick={handleNext}
                    style={handleWidthOfButtonTwo()}
                  >
                    <span className="capitalize text-base font-medium px-3">
                      Skip
                    </span>
                  </Button>
                )}  */}
          

                <Button
                  disabled={!isFormFilled}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={handleWidthOfButtonTwo()}
                >
                  <span className="capitalize text-base font-medium px-2">
                    {activeStep === 3 ? "Finish" : "Continue"}
                  </span>
                </Button>
                </>
                )}
              </>
            </div>
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message="Please wait..."
          autoHideDuration={2500}
        />
      </div>
    </SignupUserLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  checkIfUserExist,
  createServiceUser,
  verifyPhoneNumber,
  setPassword,
  updateUserData,
  saveRegisteredUserDataToState,
  clearRegisterRelatedErrors,
   resetCheckIfUserExist
})(SignupUserStepper);
