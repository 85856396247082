import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { Badge, Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { TextField } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

function AddJobRecordModal(props) {
  const { userExistData } = props?.registerReducer;

  const [newPhotos, setnewPhotos] = useState([]);

  const handleImageInputChange = (event) => {
    if (props.modalType === "edit") {
      props.initialEditUserData.image.push(event.target.files[0]);
    }

    props.setUserData({ ...props.userData, image: event.target.files[0] });
    setnewPhotos([
      ...newPhotos,
      {
        documentAvatar: URL?.createObjectURL(event.target.files[0]),
      },
    ]);

    props.setparticularThingsBeingEdited({
      ...props.particularThingsBeingEdited,
      image: [props.particularThingsBeingEdited?.image, event.target.files[0]].filter(item=>item!==undefined),
    });

  };

  const handleRemoveUploadImage = (index) => {
    setnewPhotos(newPhotos.splice(index + 1, 1));
    props.initialEditUserData.image.pop();
  };


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={props.classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={`${props.classes.paper} flex flex-col mx-4 outline-none text-center max-w-2xl`}>
          <div className="flex w-full items-center justify-between bg-black text-white rounded-t pl-2 py-3 sm:px-3">
            <div className="text-left pl-3">
              <h3 className="text-base sm:text-xl">Job Record</h3>
              <span className="text-sm">Fill in details about the job</span>
            </div>

            <span>
              <Button onClick={props.handleClose}>
                <CloseRoundedIcon className="text-white" />
              </Button>
            </span>
          </div>
          <div className="p-6 flex-1 max-w-lg mx-auto">
            <div className="w-full gap-8 flex items-center min-w-sm1">
              <div className="w-full">
                <div className="text-left pb-3">Client's Phone <span className="text-xs pl-1"> (+234...)</span></div>
                <TextField
                  id="fname-field"
                  onChange={props.handleInputChange("clientPhone")}
                  variant="outlined"
                  name="phone"
                  autoComplete="phone"
                  className="w-full"
                  placeholder="Enter phone number"
                  value={props?.modalType === "edit" ? props.initialEditUserData?.clientPhone : props.userData?.clientPhone}
                />
              </div>

              <div className="w-full">
                <div className="text-left pb-3">Job Location</div>
                <TextField
                  id="fname-field"
                  onChange={props.handleInputChange("location")}
                  variant="outlined"
                  name="location"
                  autoComplete="location"
                  className="w-full"
                  placeholder="Enter job location"
                  value={props?.modalType === "edit" ? props.initialEditUserData?.location : props.userData?.location}
                />
              </div>
            </div>
            <div className="flex justify-content-start mt-2 mb-4">
              <p className="text-xs">
                User {userExistData?.exist ? "" : <span className="text-red-500">does not</span>} exist{userExistData?.exist && "s"} on dikooko
              </p>
            </div>

            {!userExistData?.exist && (
              <div className="w-full gap-8 flex items-center min-w-sm1">
                <div className="w-full">
                  <div className="text-left pb-3">Client's FullName</div>

                  <TextField
                    id="fname-field"
                    onChange={props.handleInputChange("clientName")}
                    variant="outlined"
                    name="fullName"
                    autoComplete="name"
                    className="w-full"
                    placeholder="Enter name & Surname"
                    value={props?.modalType === "edit" ? props.initialEditUserData?.clientName : props.userData?.clientName}
                  />
                </div>

                <div className="w-full">
                  <div className="text-left pb-3">Client's Email Address</div>
                  <TextField
                    id="fname-field"
                    onChange={props.handleInputChange("clientEmail")}
                    variant="outlined"
                    name="email"
                    autoComplete="email"
                    className="w-full"
                    placeholder="Enter email address"
                    value={props?.modalType === "edit" ? props.initialEditUserData?.clientEmail : props.userData?.clientEmail}
                  />
                </div>
              </div>
            )}

            <div className="w-full gap-8 flex items-center min-w-sm1">
              <div className="w-full mt-4">
                <div className="text-left pb-3">Job Description</div>
                <textarea
                  className="w-full border border-gray-400 rounded p-1 sm:p-3"
                  name=""
                  id=""
                  rows="4"
                  onChange={props.handleInputChange("description")}
                  placeholder="Enter job description"
                  value={props?.modalType === "edit" ? props.initialEditUserData?.description : props.userData?.description}
                ></textarea>
              </div>
            </div>
            <div className="flex">
              <div className="text-left">
                <p>Add Photos</p>
                <Button className={`${props.classes.uploadButton} w-max py-2 px-6 rounded`}>
                  <div className="text-center bg-white">
                    <div className={props.classes.imgContainer}>
                      <input
                        accept="image/*"
                        className={props.classes.input}
                        id="icon-button-file"
                        type="file"
                        onChange={(event) => handleImageInputChange(event)}
                      />
                      <label htmlFor="icon-button-file">
                        <AddRoundedIcon />
                      </label>
                    </div>
                  </div>
                </Button>
              </div>

              {props?.modalType === "edit" && Array.isArray(props?.initialEditUserData?.image) &&
                (props?.initialEditUserData?.image || []).map((data, index) => (
                  <Badge
                    key={index}
                    badgeContent={<CloseRoundedIcon className="text-white" style={{ fontSize: "16px" }} />}
                    color="primary"
                    className="mt-3 cursor-pointer"
                    onClick={() => props.handleRemoveServerImage(data,index)}
                  >
                    <img
                      src={data?.url || data}
                      className="ml-6 mt-1"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.className = "hidden";
                      }}
                      alt=""
                    />
                  </Badge>
                ))}

              {newPhotos.length > 0 &&
                newPhotos.map((data, index) => (
                  <Badge
                    key={index}
                    badgeContent={<CloseRoundedIcon className="text-white" style={{ fontSize: "16px" }} />}
                    color="primary"
                    className="mt-3 cursor-pointer"
                    onClick={() => handleRemoveUploadImage(index)}
                  >
                    <img
                      src={data.documentAvatar || ""}
                      className="ml-6 mt-1"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </Badge>
                ))}
            </div>
          </div>

          <div className="mt-1 w-full">
            <div className="w-full bg-indigo-100  bg-opacity-40 flex justify-end py-2 pr-8">
              <div className="flex gap-6 spec-btns">
                <Button>
                  <span className="text-base py-2 px-3 capitalize font-semibold">Cancel</span>
                </Button>
                <>
                  {props?.modalType === "edit" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      // disabled={!Boolean(review) || showError}
                      onClick={props.handleSave}
                    >
                      <span className="text-base py-2 px-3 capitalize font-semibold text-white">Save</span>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      // disabled={!Boolean(review) || showError}
                      onClick={props.handleSubmit}
                    >
                      <span className="text-base py-2 px-3 capitalize font-semibold text-white">Submit</span>
                    </Button>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(AddJobRecordModal);
