import { Button } from "@material-ui/core";
import React from "react";
import ThanksBodyLayout from "../Layout/ThanksBodyLayout";
import { Link } from "react-router-dom";
import {
  setUserType,
  clearLoginRelatedErrors,
} from "../../redux/actions/login";
import { connect } from "react-redux";

function RegistrationThanks(props) {
  const { registrationAction } = props.registerReducer;
  return (
    <ThanksBodyLayout height="h-full">
      <div className="max-w-md mx-auto text-center my-12">
        <h3 className="text-xl sm:text-2xl">
          Thank You For Registering On dikooko, {props.userData?.firstName}
        </h3>

        {/* add_back  */}
        {/* <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2 mt-4">
          Your registration details is under verification and you would be
          contacted soon about your status as a Service Provider.
        </p>
        <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2 mt-4">
          In the meantime you can continue as a Service User.
        </p>
        <br /> <br />
        <Link
          to="/login"
          onClick={() => {
            props.setUserType("Service User");
            props.clearLoginRelatedErrors();
          }}
        >
          <Button color="primary" variant="contained">
            <span className="capitalize text-sm sm:text-base px-2 py-1">
              Login as a Service User
            </span>
          </Button>
        </Link> */}
        {/* remove_later */}

        <br /> <br />
        
        <Link
          to="/login"
          onClick={() => {
            props.setUserType(registrationAction);
          }}
        >
          <Button color="primary" variant="contained">
            <span className="capitalize text-sm sm:text-base px-8 py-1">
              Login
            </span>
          </Button>
        </Link>
        <br />
        <br />
      </div>
    </ThanksBodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  setUserType,
  clearLoginRelatedErrors,
})(RegistrationThanks);
