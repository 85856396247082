import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import BodyLayout from "../Layout/BodyLayout";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { setPassword } from "../../redux/actions/register";

function CreateNewPasswordBody(props) {
  const history = useHistory();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
   
    if (values.password === values.confirmPassword && values.password > 7) {
      await props.setPassword({
        _id: props.registerReducer?.user?._id,
        password: values.password,
      });
      if (props.registerReducer.isSuccessful) {
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    // custom rule will have name 'isPasswordValid'
    ValidatorForm.addValidationRule("isPasswordValid", (value) => {
      if (value.length < 8) {
        return false;
      }
      return true;
    });
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== values.password) {
        return false;
      }
      return true;
    });
    return () => {
      //cleanup: remove rule when it is not needed
      ValidatorForm.removeValidationRule("isPasswordValid");
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [values.password]);

  useEffect(() => {
    if (
      values.password &&
      values.confirmPassword &&
      values.password === values.confirmPassword
    ) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [props, values, values.password, values.confirmPassword]);

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Create New Password</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Create a new password to secure your dikooko account.
      </p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4">
        <ValidatorForm
          className="flex flex-col gap-5 max-w-md pb-4"
          validate="true"
          autoComplete="on"
          ref={React.createRef("form")}
          onSubmit={handleSubmit}
          // onError={(errors) => console.log(errors)}
        >
          <div>
            <p className="font-medium text-gray-500 mb-3">Password</p>
            <TextValidator
              id="outlined-adornment-password"
              variant="outlined"
              className="w-full"
              placeholder="Password"
              onChange={handleChange("password")}
              name="password"
              value={values.password}
              autoComplete="password"
              type={values.showPassword ? "text" : "password"}
              validators={["required", "isPasswordValid"]}
              errorMessages={[
                "This field is required",
                "Password is less than 8 characters",
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <p className="font-medium text-gray-500 mb-3">Confirm Password</p>
            <TextValidator
              id="outlined-adornment-password"
              variant="outlined"
              className="w-full"
              placeholder="Confirm Password"
              onChange={handleChange("confirmPassword")}
              name="password"
              value={values.confirmPassword}
              autoComplete="password"
              type={values.showPassword ? "text" : "password"}
              validators={["required", "isPasswordValid", "isPasswordMatch"]}
              errorMessages={[
                "This field is required",
                "Password is less than 8 characters",
                "Password mismatch",
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex justify-between">
            <Button>
              <Link
                to="/recover-password"
                className="text-base py-2 px-3 capitalize font-semibold"
              >
                Back
              </Link>
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!isFormFilled}
              onClick={handleSubmit}
            >
              <span
                to="/reset-success"
                className="text-base px-3 capitalize font-semibold text-white"
              >
                Finish
              </span>
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { setPassword })(CreateNewPasswordBody);
