import axios from "axios";
import { BASE_URL, requestHeaders } from "../../constants/config";
import * as actionTypes from "./types";
import { userType } from "../reducers/loginReducer";

export const login = (userData) => {
  return async (dispatch) => {
    try {
      const loggedInUser = await axios.post(
        `${BASE_URL}auth`,
        userData,
        requestHeaders
      );
      if (loggedInUser.status === 200) {
        // console.log("loggedInUser=",loggedInUser.getAllResponseHeaders());
        // console.log(loggedInUser.headers.get("Authorization"));
        if (
          userType === "Service Provider" &&
          loggedInUser.data.userType === "user"
        ) {
          dispatch(loginError("You're not a provider, try login as a user"));
        } else {
          dispatch(saveLoginUserDataToState(loggedInUser.data));
        }
      }

      //   console.log(loggedInUser);
    } catch (error) {
      //   console.log("login error", error);
      dispatch(loginError(error));
    }
  };
};

export const sendOTPtoPhone = (phoneNumber) => {
  return async (dispatch) => {
    try {
      const sendOTPtoPhoneData = await axios.post(
        `${BASE_URL}users/reset-password/`,
        phoneNumber,
        requestHeaders
      );

      (sendOTPtoPhoneData.status === 200 || 201) &&
        dispatch(
          savePasswordResetDataToState(sendOTPtoPhoneData.data, phoneNumber)
        );
    } catch (error) {
      dispatch(loginError(error.response));
    }
  };
};

export const savePasswordResetDataToState = (message, phoneNumber) => {
  return {
    type: actionTypes.SAVE_PASSWORD_RESET_DATA,
    payload: {
      message: message,
      phone: phoneNumber.phone,
    },
  };
};

export const saveLoginUserDataToState = (loggedInUserData) => {
  return {
    type: actionTypes.SAVE_LOGGED_IN_USER_DATA,
    payload: loggedInUserData,
  };
};

export const loginError = (err) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: err,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};
export const clearLoginRelatedErrors = () => {
  return {
    type: actionTypes.CLEAR_LOGIN_RELATED_ERROR,
  };
};

export const socialLogin = (userData) => {
  return async (dispatch) => {
    try {
      const loggedInUser = await axios.post(
        `${BASE_URL}users/oauth/google`,
        userData,
        requestHeaders
      );

      loggedInUser.success &&
        dispatch(saveLoginUserDataToState(loggedInUser.data));
    } catch (error) {
      //   console.log("login error", error);
      dispatch(loginError(error.response.data));
    }
  };
};

export const setUserType = (value) => {
  return {
    type: actionTypes.SET_USER_TYPE,
    payload: value,
  };
};
