import React from "react";
import BodyLayout from "../Layout/BodyLayout";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TextField } from "@material-ui/core";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));
function SignupUserLocation(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { error } = props.registerReducer;
  const handleFormChange = (prop) => (event) => {
    props.setlocationData({
      ...props.locationData,
      // _id: props.registerReducer?.user?._id, //to_add_back
      [prop]: event.target.value,
    });
  };

  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Location</h3>
      <p
        className={`${
          width > 480 ? "text-sm" : "text-xs"
        } sm:text-base md:text-lg text-gray-500 pt-2`}
      >
        Your address would help in connecting you with service providers in your
        locality.
      </p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4">
        <form className="" validate="true" autoComplete="on">
          <div>
            <p className="font-medium text-gray-500 mb-2">State</p>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.locationData.state}
                onChange={handleFormChange("state")}
                className={classes.select}
              >
                <MenuItem value="Lagos">Lagos</MenuItem>
                <MenuItem value="Oyo">Oyo</MenuItem>
              </Select>
            </FormControl>
          </div>
          <br />
          <div>
            <p className="font-medium text-gray-500 mb-2">City</p>
            <FormControl variant="outlined" className={classes.formControl}>
            
                {props.locationData.state==="Lagos" ? (
                   <Select
                   labelId="demo-simple-select-outlined-label"
                   id="demo-simple-select-outlined"
                   value={props.locationData.city}
                   onChange={handleFormChange("city")}
                 >
                    <MenuItem value=" Victoria Island">Victoria Island</MenuItem>
                    <MenuItem value="Ikeja">Ikeja</MenuItem>
                    <MenuItem value="Lekki">Lekki</MenuItem>
                    <MenuItem value="Ajah">Ajah</MenuItem>
                    <MenuItem value="Surulere">Surulere</MenuItem>
                    <MenuItem value="Ikoyi">Ikoyi</MenuItem>
                    </Select>
                ) : (
                  <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={props.locationData.city}
                  onChange={handleFormChange("city")}
                >
                    <MenuItem value="Ibadan- Oluyole/Onireke">Ibadan- Oluyole/Onireke</MenuItem>
                    <MenuItem value="Ibadan- Agodi/Bodija">Ibadan- Agodi/Bodija</MenuItem>
                    <MenuItem value="Ibadan- Iyaganku/Jericho">Ibadan- Iyaganku/Jericho</MenuItem>
                    <MenuItem value="Ibadan- Ologuneru">Ibadan- Ologuneru</MenuItem>
                    <MenuItem value="Ibadan- Akobo">Ibadan- Akobo</MenuItem>
                    </Select>
                )}
             
            </FormControl>
          </div>
          <br />
          <div>
            <p className="font-medium text-gray-500 mb-2">Area / Locality</p>

            <TextField
              id="area-field"
              onChange={handleFormChange("area")}
              variant="outlined"
              name="area"
              autoComplete="area"
              placeholder="Area"
              className="w-full"
              value={props.locationData.area}
            />
          </div>
          <br />
          {error && <p className="text-sm text-red-500">{error}</p>}
          <br />
        </form>
      </div>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(SignupUserLocation);
