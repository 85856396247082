import React from "react";
import CategoryCard from "./LandingCategoryCard";
import { ReactComponent as AutomotiveIcon } from "../../assets/icons/Automotive.svg";
import { ReactComponent as BuildingIcon } from "../../assets/icons/Building.svg";
import { ReactComponent as CateringIcon } from "../../assets/icons/Catering.svg";
import { ReactComponent as CaregivingIcon } from "../../assets/icons/Caregiving.svg";
import { ReactComponent as CleaningIcon } from "../../assets/icons/Cleaning.svg";
import { ReactComponent as EventPlanningIcon } from "../../assets/icons/EventPlanning.svg";
import { ReactComponent as FashionDesigningIcon } from "../../assets/icons/FashionDesigning.svg";
import { ReactComponent as HealthIcon } from "../../assets/icons/Health.svg";
import { ReactComponent as LaundryIcon } from "../../assets/icons/Laundry.svg";
import { ReactComponent as LogisticsIcon } from "../../assets/icons/Logistics.svg";
import { ReactComponent as MaintenanceIcon } from "../../assets/icons/Maintenance.svg";
import { ReactComponent as MiscellaneousIcon } from "../../assets/icons/Miscellaneous.svg";
import CategoryCardGroup from "./LandingCategoryCardGroup";

export default function FinalCardGroup() {
  return (
    <div>
      <CategoryCardGroup
        col1={
          <CategoryCard categoryName="Automotive" icon={<AutomotiveIcon />} />
        }
        col2={<CategoryCard categoryName="Building" icon={<BuildingIcon />} />}
        col3={<CategoryCard categoryName="Catering" icon={<CateringIcon />} />}
        col4={
          <CategoryCard categoryName="Caregiving" icon={<CaregivingIcon />} />
        }
      />

      <CategoryCardGroup
        col1={<CategoryCard categoryName="Cleaning" icon={<CleaningIcon />} />}
        col2={
          <CategoryCard
            categoryName="Event Planning"
            icon={<EventPlanningIcon />}
          />
        }
        col3={
          <CategoryCard
            categoryName="Fashion & Clothing"
            icon={<FashionDesigningIcon />}
          />
        }
        col4={<CategoryCard categoryName="Wellness & Beauty" icon={<HealthIcon />} />}
      />

      <CategoryCardGroup
        col1={<CategoryCard categoryName="Telecoms" icon={<LaundryIcon />} />}
        col2={
          <CategoryCard categoryName="Logistics" icon={<LogisticsIcon />} />
        }
        col3={
          <CategoryCard categoryName="Maintenance" icon={<MaintenanceIcon />} />
        }
        col4={
          <CategoryCard
            categoryName="Others"
            icon={<MiscellaneousIcon />}
          />
        }
      />
    </div>
  );
}
