import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, saveLoginUserDataToState, clearLoginRelatedErrors } from "../../redux/actions/login";
import MessagesBody from "../../components/UserNProviderUtility/MessagesBody";
import NotificationsBody from "../../components/Notifications/NotificationsBody";
import ProfileBody from "../../components/UserNProviderUtility/ProfileBody";
import WebUserAuthSideNav from "../../components/SideNav/WebUserAuthSideNav";
import LandingComponent from "../../components/UserNProviderUtility/LandingComponent";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import Header from "../../components/Header/Header";
import ViewProvider from "../../components/ServiceUser/ViewProvider";
import Visitor from "../../components/ServiceUser/Visitor";
import ReviewForm from "../../components/ReviewForm/Index";
import CategoryPageComponent from "../../components/Category/CategoryPageComponent";
import { useHistory } from "react-router-dom";
import LoginShowDetailsModal from "../../components/Modals/LoginShowDetailsModal";
import {viewSingleProvider} from "../../redux/actions/browseServiceProvider";
import ContactSupportBody from "../../components/UserNProviderUtility/ContactSupportBody";

function ServiceUserPage(props) {
  const { isAuthenticated, user } = props.loginReducer;
  const { width } = useWindowDimensions();
  const { categoryName } = props?.match?.params;

  const username = `Welcome, ${user.firstName}`;

  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = (data) => {
    setOpen(true);
    props.viewSingleProvider(data);
  };

  const handleMessage = () => {
    if (isAuthenticated === false) {
      setOpen(true);
    } else {
      history.push("/messaging");
    }
  };


  return (
    <>
      {!isAuthenticated && <Redirect to="/login" />}
      {!isAuthenticated || width < 640 ? <Header showRegisterButton={true} showLoginButton={width > 280 ? true : false} /> : ""}
      <div className="flex max-w-screen-2xl mx-auto bg-indigo-100 bg-opacity-50 sm:bg-opacity-40">
        <Router>
          <WebUserAuthSideNav />

          <div className="relative w-full min-h-screen px-2 sm:ml-72">
            <div className="absolute z-2 right-0 hidden lg:block float-right pt-5 pr-12">{username}</div>

            <Route exact path="/user">
            <LandingComponent open={open} setOpen={setOpen} handleCall={handleCall} handleMessage={handleMessage} handleClose={handleClose}/>
            </Route>
            <Route exact path="/user/messages">
              <MessagesBody name="John Krover" lastMessage="Hello, I have a job that req..." />
            </Route>
            <Route exact path="/user/notifications">
              <div className="pt-6 sm:pt-12">
                <NotificationsBody />
              </div>
            </Route>

            <Route exact path="/user/review-form/:jobRecordId" component={ReviewForm} />

            <Route exact path="/user/profile">
              <ProfileBody />
            </Route>

            <Route exact path="/user/view-provider/:providerId" component={ViewProvider} />

            <Route exact path="/user/provider-review" component={Visitor} />
          

            <Route exact path="/category/:categoryName">
              <div className="xl:ml-40">
                <CategoryPageComponent open={open} setOpen={setOpen} categoryName={categoryName} handleMessage={handleMessage} handleCall={handleCall} />
              </div>
            </Route>

            <Route exact path="/user/contact-support">
            <ContactSupportBody/>
            </Route>
          </div>
          <LoginShowDetailsModal isAuthenticated={isAuthenticated} open={open} handleClose={handleClose} handleCall={handleCall} />

        </Router>
      </div>

      {window.location.pathname.includes("contact-support" || "messag") ? "" : <Footer />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  login,
  saveLoginUserDataToState,
  clearLoginRelatedErrors,
  viewSingleProvider
})(ServiceUserPage);
