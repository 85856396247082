import React from "react";
import { browseServiceProvider } from "../../redux/actions/browseServiceProvider";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import {setCategoryName} from "../../redux/actions/browseServiceProvider";


function CategoryCard(props) {
  const { categoryName } = props;
  const { width } = useWindowDimensions();

  const handleBrowseCategory = () => {
    props.browseServiceProvider(categoryName.replace(/&/g, "_").replace(/ /g, ""));
    props.setCategoryName(categoryName);
  };

  return (
    <Link to={`/category/${categoryName.replace(/ /g, "_")}`} className="flex flex-col items-center text-center bg-white py-6 px-4 rounded" onClick={handleBrowseCategory}>
      <div>{props.icon}</div>
      <div className="pt-2 sm:whitespace-nowrap text-sm sm:text-base truncate" style={width < 480 ? { maxWidth: "10rem" } : { maxWidth: "15rem" }}>
        {props.categoryName}
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  browseServiceProvider,
  setCategoryName,
})(CategoryCard);
