import React from "react";

export default function SideNavWrapper(props) {
  return (
    <div className="max-w-sm hidden sm:block">
      <div className="h-full min-h-screen w-full text-black">
        <div className="bg-white fixed left-0 flex flex-col gap-32 xl:gap-52 h-full w-max">{props.children}</div>
      </div>
    </div>
  );
}
