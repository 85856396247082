import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import ServiceCategoryModal from "../Modals/ServiceCategoryModal";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { getLoggedInUserDetails } from "../../redux/actions/profile";
import { updateProfile } from "../../redux/actions/profile";

function EditWorkProfile(props) {
  const { userDetails } = props.profileReducer;
  const { token } = props?.loginReducer?.user;
  const [snackMessage, setsnackMessage] = useState(props?.profileReducer?.message);
  const [openSnack, setOpenSnack] = useState(false);

  const [otherInformation, setotherInformation] = useState({
    userType: "provider",
    profession: userDetails?.profession,
    categories: userDetails?.categories ? userDetails?.categories : [],
    about: userDetails?.about,
  });

  const handleChange = (prop) => (event) => {
    setotherInformation({ ...otherInformation, [prop]: event.target.value });
  };

  const { isAuthenticated } = props.loginReducer;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let filteredWorkProfile = Object.fromEntries(Object.entries(otherInformation).filter(([_, v]) => v !== null && v !== "" && v !== undefined));

  const handleWorkProfileUpdate = async () => {
    await props.updateProfile(filteredWorkProfile, token);
    await handleClickSnack();
    props.getLoggedInUserDetails(token);
  };


  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      setsnackMessage(props.profileReducer?.message);
    }
    return () => {
      isCancelled = true;
    };
  }, [props.profileReducer?.message]);

  return (
    <div className="sm:mt-12 sm:mt-14 sm:pl-4">
      <div className="max-w-2xl pb-8 sm:p-6" onClick={props.onClick}>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="w-full px-3">
            <div className="w-full mt-6">
              <p className="mb-3 w-full text-sm text-gray-600">Profession</p>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={otherInformation.profession}
                onChange={handleChange("profession")}
                className="w-full"
              />
            </div>

            <div className="w-full mt-6">
              <p className="mb-3 w-full text-sm text-gray-600">Service Categories</p>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={otherInformation?.categories}
                onChange={handleChange("serviceCategories")}
                className="w-full"
                disabled
              />
              <button onClick={handleOpen} className="px-3 py-2 shadow text-sm mt-2">
                Edit Categories
              </button>
            </div>

            <div className="w-full mt-6">
              <p className="mb-3 w-full text-sm text-gray-600">About My Service</p>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={otherInformation.about}
                onChange={handleChange("about")}
                minrows={3}
                multiline={true}
                className="w-full"
              />
            </div>
            <div className="flex w-full pt-6 justify-end">
              <Button variant="contained" color="primary" className="px-3" onClick={handleWorkProfileUpdate}>
                <span className="capitalize ">Save Changes</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ServiceCategoryModal
        isAuthenticated={isAuthenticated}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        otherInformation={otherInformation}
        setotherInformation={setotherInformation}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnack}
        onClose={handleCloseSnack}
        message={snackMessage}
        autoHideDuration={2500}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { updateProfile, getLoggedInUserDetails })(EditWorkProfile);
