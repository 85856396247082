const initialState = {
    addJobRecordsData: [],
    getJobRecordsData:[],
    editJobRecordsData:[],
    deleteJobRecordsData:[],
    addJobRecordSuccessful: false,
    deletedSuccessfully: false,
    editedSuccessfully: false,
    reviewJobRecordSuccessful:false,
    singleJobRecord:{},
    getJobRecordsError:{},
    addJobRecordError:{},
    deleteJobRecordError:{},
    editJobRecordError:{},
    message:"",
  };
  
  export const jobRecordsReducer = (state = initialState, actions) => {
    const { type, payload } = actions;

    if (type === "SAVE_ADD_JOB_RECORD_DATA_TO_STATE") {
      return {
        ...state,
        addJobRecordsData: payload,
        addJobRecordSuccessful: true,
        addJobRecordError: null,
        message: "Job Record Added Successfully"
      };
    }
    else if (type === "ADD_JOB_RECORD_ERROR") {
      return {
        ...state,
        addJobRecordError: payload,
        addJobRecordSuccessful: false,
        message: payload?.data
      };
    }
    else if (type === "SAVE_GET_JOB_RECORD_DATA_TO_STATE") {
      return {
        ...state,
        getJobRecordsData: payload,
      };
    }
    else if(type === "SAVE_REVIEW_JOB_RECORD_DATA_TO_STATE"){
      return {
        ...state,
        reviewedJobRecord: payload,
        reviewJobRecordSuccessful:true,
      };
    }
    else if (type === "GET_JOB_RECORD_ERROR") {
      return {
        ...state,
        getJobRecordsError: payload,
      };
    }
   else if (type === "SET_SINGLE_JOB_RECORD"){
    return {
      ...state,
      singleJobRecord: payload,
    };
   }else if(type === "DELETED_JOB_RECORD_SUCCESSFULLY"){
     return{
       ...state,
       deleteJobRecordsData:payload,
       deletedSuccessfully: true,
       message: payload?.data?.message
     }
   }else if(type === "DELETE_JOB_RECORD_ERROR"){
    return{
      ...state,
      deleteJobRecordError: payload,
      deletedSuccessfully: false,
      message:payload?.data,
    }
  }else if(type === "EDITED_JOB_RECORD_SUCCESSFULLY"){
    return{
      ...state,
      editedSuccessfully: true,
      editJobRecordsData:payload,
      message:"Job record Edited Successfully",
    }
  }else if(type === "EDIT_JOB_RECORD_ERROR"){
   return{
     ...state,
     editJobRecordError: payload,
     editedSuccessfully: false,
     message:payload?.data,
   }
 }
    return state;
  };
  