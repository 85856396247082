import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import MaterialUiphone from "material-ui-phone-number";
import { Button, Select } from "@material-ui/core";
import useWindowDimensions from "../../Hooks/UseWindowDimension";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { updateProfile } from "../../redux/actions/profile";
import Snackbar from "@material-ui/core/Snackbar";
import { getLoggedInUserDetails } from "../../redux/actions/profile";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  medium: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  formControl: {
    width: "100%",
  },
}));

function EditPersonalProfile({getLoggedInUserDetails, ...props}) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const {
    token,
    firstName,
    lastName,
    // avatar,
    phone,
    email,
    state,
    city,
    area,
  } = props?.loginReducer?.user;

  // const { message } = props.profileReducer;

  // const [
  //   // isSubmitting,
  //   setisSubmitting,
  // ] = useState(false);

  const [img, setImg] = useState({ avatar: "" });
  const [updateData, setUpdateData] = useState({
    firstName,
    lastName,
    phone,
    state,
    city,
    area,
  });

  const [avatar, setavatar] = useState("");

  const previewFile = (event) => {
    setavatar(URL.createObjectURL(event.target.files[0]));
  };

  // const handleImgChange = async (event) => {
  //   const data = new FormData();
  //   data.append("avatar", event.target.files[0]);
  //   data.append("avatarName", event.target.files[0]?.name);
  //   // await props.updateProfileAvatar(data, token);
  // };

  const [submissionData, setsubmissionData] = useState({})
  const [snackMessage, setsnackMessage] = useState(props?.profileReducer?.message);
  const [openSnack, setOpenSnack] = useState(false);


  const handleChange = (prop) => (event) => {
    setUpdateData({ ...updateData, [prop]: event.target.value });
    setsubmissionData({...submissionData, [prop]: event.target.value });
  };

  let filteredSubmissionData = Object.fromEntries(Object.entries(submissionData).filter(([_, v]) => v !== null && v !==""));

  const handleProfileUpdate = async () => {
    await props.updateProfile(filteredSubmissionData, token);
    await handleClickSnack();

    // setisSubmitting(true);
  };

  const handleDeleteImg = () => {
    setImg({});
  };
  const handlePhoneChange = (value) => {
    if (value) {
      setUpdateData({ ...updateData, phone: value });
      setsubmissionData({...submissionData, phone: value });

    }
  };
  //======SnackBar======
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  

  useEffect(() => {
    let isCancelled = false;

    if(!isCancelled){
      setsnackMessage(props.profileReducer?.message);
    }
    return () => {
      isCancelled = true;
    }
  }, [props.profileReducer?.message])
  

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      getLoggedInUserDetails(token);
    }
    return () => {
      isCancelled = true;
    };
  }, [token,getLoggedInUserDetails]);


  return (
    <div className="sm:mt-12 px-2 sm:pl-4">
      <div className="max-w-2xl pt-6 pb-10 sm:px-6" onClick={props.onClick}>
        <div className="w-full max-w-ts2.5 sm:max-w-ts3 relative mx-auto">
          <div className={classes.root}>
            <Avatar src={avatar !== "" ? avatar : img.avatar} className={width < 640 ? classes.medium : classes.large} />
          </div>

          <input accept="image/*" className="hidden" id="icon-button-file" type="file" onChange={(event) => previewFile(event)} />
          <label htmlFor="icon-button-file" className="absolute top-0 sm:top-2 sm:left-2 Mui_Picture_Label">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className="rounded-full h-20 w-20 flex items-center justify-center"
            >
              <div className="text-sm font-medium w-full h-full text-transparent hover:text-white">Change Picture</div>
            </IconButton>
          </label>
          <IconButton className="float-right absolute right-3 bottom-8" onClick={handleDeleteImg}>
            <DeleteIcon className="scale-110" />
          </IconButton>
        </div>

        <div className="flex flex-col sm:flex-row w-full">
          <div className="w-full px-3 -mt-8 sm:mt-0">
            <div className="flex flex-col xl:flex-row gap-4 w-full justify-between">
              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm  text-gray-400">First Name</p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="w-full"
                  value={updateData.firstName}
                  onChange={handleChange("firstName")}
                  disabled
                />
              </div>
              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm  text-gray-400">Last Name</p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="w-full"
                  value={updateData.lastName}
                  onChange={handleChange("lastName")}
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-col xl:flex-row gap-4 w-full justify-between">
              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm">Phone Number</p>
                <MaterialUiphone
                  name="phone"
                  data-cy="user-phone"
                  defaultCountry={"ng"}
                  variant="outlined"
                  value={updateData.phone}
                  onChange={handlePhoneChange}
                  autoComplete="phone-number"
                  className="w-full"
                />
              </div>

              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm text-gray-400">Email</p>
                <TextField id="outlined-basic" variant="outlined" value={email} 
                 className="w-full" disabled />
              </div>
            </div>

            <div className="flex flex-col xl:flex-row gap-4 w-full justify-between">
              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm">State</p>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={updateData.state}
                    onChange={handleChange("state")}
                    className={classes.select}
                  >
                    <MenuItem value="Lagos">Lagos</MenuItem>
                    <MenuItem value="Oyo">Oyo</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-full mt-6">
                <p className="mb-3 w-full text-sm">City</p>
                <FormControl variant="outlined" className={classes.formControl}>
                  {updateData.state === "Lagos" ? (
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={updateData.city}
                      onChange={handleChange("city")}
                    >
                      <MenuItem value=" Victoria Island">Victoria Island</MenuItem>
                      <MenuItem value="Ikeja">Ikeja</MenuItem>
                      <MenuItem value="Lekki">Lekki</MenuItem>
                      <MenuItem value="Ajah">Ajah</MenuItem>
                      <MenuItem value="Surulere">Surulere</MenuItem>
                      <MenuItem value="Ikoyi">Ikoyi</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={updateData.city}
                      onChange={handleChange("city")}
                    >
                      <MenuItem value="Ibadan- Oluyole/Onireke">Ibadan- Oluyole/Onireke</MenuItem>
                      <MenuItem value="Ibadan- Agodi/Bodija">Ibadan- Agodi/Bodija</MenuItem>
                      <MenuItem value="Ibadan- Iyaganku/Jericho">Ibadan- Iyaganku/Jericho</MenuItem>
                      <MenuItem value="Ibadan- Ologuneru">Ibadan- Ologuneru</MenuItem>
                      <MenuItem value="Ibadan- Akobo">Ibadan- Akobo</MenuItem>
                    </Select>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row gap-4 w-full justify-between items-center">
              <div className="w-full xl:w-1/2 mt-6">
                <p className="mb-3 w-full text-sm">Area</p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="w-full"
                  value={updateData.area}
                  onChange={handleChange("area")}
                />
              </div>
              <div className="">
                <div className="pt-4 sm:py-8"></div>
                <Button variant="contained" color="primary" className="px-3" onClick={handleProfileUpdate}>
                <span className="capitalize ">Save Changes</span> 
                </Button>
              </div>
            </div>

            {/* {message && isSubmitting && (
                  <p className="text-green-500 pt-4">{message}</p>
                )} */}
           
          </div>
        </div>
      </div>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={snackMessage}
          autoHideDuration={2500}
        />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  updateProfile,
  getLoggedInUserDetails
  // updateProfileAvatar,
  // saveLoginUserDataToState,
  // updateProfileText,
})(EditPersonalProfile);
