import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import BodyLayout from "../Layout/BodyLayout";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";

function SignupSecurity(props) {
  const [values, setValues] = useState({
    confirmPassword: "",
    showPassword: false,
    keepLoggedIn: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handlePasswordChange = (event) => {
    props.setserviceUserPassword({
      _id: props.registerReducer?.user?._id,
      password: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    // your submit logic
  };
  const handleCheckChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    // custom rule will have name 'isPasswordValid'
    ValidatorForm.addValidationRule("isPasswordValid", (value) => {
      if (value.length < 8) {
        return false;
      }
      return true;
    });
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== props.serviceUserPassword.password) {
        return false;
      }
      return true;
    });
    return () => {
      //cleanup: remove rule when it is not needed
      ValidatorForm.removeValidationRule("isPasswordValid");
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [props.serviceUserPassword.password]);

  useEffect(() => {
    if (
      props.serviceUserPassword.password &&
      values.confirmPassword &&
      props.serviceUserPassword.password === values.confirmPassword &&
      values.keepLoggedIn
    ) {
      props.setIsFormFilled(true);
    } else {
      props.setIsFormFilled(false);
    }
  }, [
    props,
    values,
    props.serviceUserPassword.password,
    values.confirmPassword,
  ]);


  return (
    <BodyLayout height="h-full">
      <h3 className="text-xl sm:text-2xl">Security</h3>
      <p className="text-sm sm:text-base md:text-lg text-gray-500 pt-2">
        Secure your dikooko account with password.
      </p>
      <br />
      <div className="flex flex-col gap-5 max-w-md pb-4">
        <ValidatorForm
          className="flex flex-col gap-5 max-w-md pb-4"
          validate="true"
          autoComplete="on"
          ref={React.createRef("form")}
          onSubmit={handleSubmit}
          // onError={(errors) => console.log(errors)}
        >
          <div>
            <p className="font-medium text-gray-500 mb-3">Password</p>
            <TextValidator
              id="outlined-adornment-password"
              variant="outlined"
              className="w-full"
              placeholder="Password"
              onChange={handlePasswordChange}
              name="password"
              value={props.serviceUserPassword.password}
              autoComplete="password"
              type={values.showPassword ? "text" : "password"}
              validators={["required", "isPasswordValid"]}
              errorMessages={[
                "This field is required",
                "Password is less than 8 characters",
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <p className="font-medium text-gray-500 mb-3">Confirm Password</p>
            <TextValidator
              id="outlined-adornment-password"
              variant="outlined"
              className="w-full"
              placeholder="Confirm Password"
              onChange={handleChange("confirmPassword")}
              name="password"
              value={values.confirmPassword}
              autoComplete="password"
              type={values.showPassword ? "text" : "password"}
              validators={["required", "isPasswordValid", "isPasswordMatch"]}
              errorMessages={[
                "This field is required",
                "Password is less than 8 characters",
                "Password mismatch",
              ]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControlLabel
            className="checkboxLabel text-gray-500"
            control={
              <Checkbox
                checked={values.keepLoggedIn}
                onChange={handleCheckChange}
                name="keepLoggedIn"
                color="primary"
              />
            }
            label={
              <span className="text-xs  sm:text-sm">
                By clicking Finish, you agree to our
                <a href="https://dikooko.netlify.app/termsNconditions" rel="noreferrer" target="_blank">
                  <strong>{` Terms of Use & Privacy Policy.`}</strong>
                </a>
                You consent to recieve sms messages for transactional purposes.
              </span>
            }
          />
        </ValidatorForm>
      </div>
    </BodyLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(SignupSecurity);
